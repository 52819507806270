import React, { useEffect } from "react";
import { newsData } from "./NewsMain";
import { useLocation, useParams } from "react-router-dom";
import CustomDetails from "./CustomDetails";

const NewsDetails = () => {
  let { id } = useParams();
  console.log(id);

  const {state} = useLocation();
  console.log(state, "elelle");


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <CustomDetails elem={state} />
    </div>
  );
};

export default NewsDetails;
