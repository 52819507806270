import React, { useState } from "react";
import {
  Box,
  Card,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withStyles,
  FormHelperText,
  Typography,
  styled,
  Container,
  Paper,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CloudUploadRounded } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavTopnav from "../components/NavTopnav/NavTopnav";
import { urls as url } from "./../services/urlConstant";
import SettingContext from "../context/SettingsContext";
import appendStringToUrls from "../utils/appendString";
import { get, post } from "../services/apis";
import dayjs from "dayjs";
import { useContext } from "react";
import { LoadingButton } from "@mui/lab";
import Footer from "./Footer";

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: "15px",
  fontWeight: 700,
}));

const styles = {
  padding: 2,
  width: "100%",
  mt: 1,
};

const style = (theme) => ({
  field: {
    margin: "10px 0",
  },
  countryList: {
    ...theme.typography.body1,
  },
});

const AboutTitle = styled(Box)(({ theme }) => ({
  // color: "#F86F03",
  color: "white",
  padding: "80px",
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url(/HeaderImage.png)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
  },
}));

const FounderTitle = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));

const OuterBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(/admissionbg1.jpg)",
  backgroundRepeat: "no-repeat",
  width: "100%",
  backgroundSize: "100% 100%",
  paddingTop: "100px",
  // paddingBottom: "10px",
}));

const Admission = () => {
  const [date, setDate] = useState(dayjs(Date.now()));
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [academicYears, setAcademicYears] = useState([]);
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [classes, setClasses] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await get(urls.class.getAllHomeClasses);
        setClasses(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [selectedSetting._id]);

  useEffect(() => {
    const getAcademicYears = async () => {
      try {
        const res = await get(urls.administrator.academicYearGetAll);

        setAcademicYears(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getAcademicYears();
  }, [selectedSetting._id]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // console.log(formData, "formData");
  const handleSubmit = async (e) => {
    setLoader(true);
    try {
      e.preventDefault();
      let data = {
        studentDetails: {
          basicDetails: {
            name: formData.name,
            dob: date,
            gender: formData.gender,
            bloodGroup: formData.bloodGroup,
            religion: formData.relegion,
            caste: formData.caste,
            casteIncomeCertificateNumber: formData.casteInform,
            motherTongue: formData.motherTongue,
            birthPlace: formData.birthPlace,
            aadharNo: formData.aadharNo,
          },
          contactDetails: {
            contactNumber: formData.contactNumber,
            guardianName: formData.guardianName,
            guardianContactNumber: formData.guardianPhone,
            alternateNumber: formData.guardianPhone2,
            relationWithGuardian: formData.guardianRelation,
            nationalId: formData.nationalId,
            presentAddress: formData.presentAddress,
            permanentAddress: formData.permanentAddress,
          },
          previousSchoolDetails: {
            schoolName: formData.previousSchool,
            class: formData.previousClass,
            tcNumber: formData.tcNo,
          },
          fatherDetails: {
            name: formData.fatherName,
            contactNumber: formData.fatherPhone,
            education: formData.fatherEdu,
            profession: formData.fatherProfession,
            designation: formData.fatherDesignation,
          },
          motherDetails: {
            name: formData.motherName,
            contactNumber: formData.motherPhone,
            education: formData.motherEdu,
            profession: formData.motherProfession,
            designation: formData.motherDesignation,
          },
          otherDetails: {
            email: formData.email,
            healthCondition: formData.healthCondition,
            hostelRequired: formData.hostel,
            transportRequired: formData.transport,
            otherInfo: formData.otherInfo,
          },
          academicDetails: {
            class: formData.class,
            academicYear: formData.academicYear,
          },
        },
      };
      const res = await post(urls.preAdmission.create, data);
      if (res.status == 200) {
        setFormData({
          name: "",
          gender: "",
          bloodGroup: "",
          academicYear: "",

          hostel: "",
          transport: "",
          caste: "",
          casteInform: "",
          motherTongue: "",
          birthPlace: "",
          aadharNo: "",
          contactNumber: "",
          guardianName: "",
          guardianPhone: "",
          guardianPhone2: "",
          guardianRelation: "",
          nationalId: "",
          presentAddress: "",
          permanentAddress: "",
          fatherName: "",
          fatherPhone: "",
          fatherEdu: "",
          fatherProfession: "",
          fatherDesignation: "",
          previousSchool: "",
          previousClass: "",
          tcNumber: "",
          motherName: "",
          motherPhone: "",
          motherEdu: "",
          motherProfession: "",
          motherDesignation: "",
          motherPhoto: "",
          email: "",
          healthCondition: "",

          otherInfo: "",
          class: "",
        });
        // console.log(res, "jjjjjjjjjjjjj");
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
    setLoader(false);
  };

  return (
    <>
      <NavTopnav />
      <OuterBox>
        <Container>
          <Grid container mt={2}>
            <Paper
              sx={{
                width: "100%",
                bgcolor: "whitesmoke",
                padding: 3,
                textAlign: "center",
              }}
              elevation={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  style={{
                    fontSize: 20,
                    color: "#F86F03",
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontWeight: 700,
                  }}>
                  Pre-Admission Form
                </Typography>
              </Grid>
            </Paper>
          </Grid>
          <form onSubmit={handleSubmit}>
            {/* =====================Basic Information=============== */}
            <Paper sx={styles} elevation={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Heading>Basic Information:</Heading>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    label="Name"
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <DatePicker
                        variant="outlined"
                        size="small"
                        label="Date of Birth"
                        openTo="day"
                        views={["day", "month", "year"]}
                        inputFormat="DD-MM-YYYY"
                        value={date}
                        onChange={(newValue) => {
                          setDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            {...params}
                            margin="dense"
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} sx={{ mt: 1 }}>
                  <FormControl
                    required
                    variant="outlined"
                    size="small"
                    fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Gender
                    </InputLabel>
                    <Select
                      key={formData.gender}
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      required
                      label="Gender"
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}>
                      {/* <MenuItem value="">
                      <em>Select</em>
                    </MenuItem> */}
                      <MenuItem value={"male"}>Male</MenuItem>
                      <MenuItem value={"female"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} sx={{ mt: 1 }}>
                  <FormControl
                    required
                    variant="outlined"
                    size="small"
                    fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Blood Group
                    </InputLabel>
                    <Select
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      key={formData.bloodGroup}
                      required
                      name="bloodGroup"
                      label="Blood Group"
                      value={formData.bloodGroup}
                      onChange={handleChange}>
                      <MenuItem value={"A+"}>A+</MenuItem>
                      <MenuItem value={"A-"}>A-</MenuItem>
                      <MenuItem value={"B+"}>B+</MenuItem>
                      <MenuItem value={"B-"}>B-</MenuItem>
                      <MenuItem value={"O+"}>O+</MenuItem>
                      <MenuItem value={"O-"}>O-</MenuItem>
                      <MenuItem value={"AB+"}>AB+</MenuItem>
                      <MenuItem value={"AB-"}>AB-</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={3} lg={3}>
                <TextField
                  label="Relegion"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="relegion"
                  margin="dense"
                  value={formData.relegion}
                  onChange={handleChange}
                />
              </Grid> */}
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    label="Caste"
                    name="caste"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    value={formData.caste}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    label="Caste Income Cetificate Number"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="casteInform"
                    value={formData.casteInform}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    label="Mother Tongue"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="motherTongue"
                    value={formData.motherTongue}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    label="Birth Place"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="birthPlace"
                    value={formData.birthPlace}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    label="Aadhar No"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    margin="dense"
                    name="aadharNo"
                    value={formData.aadharNo}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Paper>

            {/* =====================Contact Information=============== */}

            <Paper elevation={3} sx={styles}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Heading>Contact Information:</Heading>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    label="Contact Number"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    label="Guardian Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="guardianName"
                    value={formData.guardianName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    label="Guardian Number"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="guardianPhone"
                    value={formData.guardianPhone}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    label="Alternate Number"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="guardianPhone2"
                    value={formData.guardianPhone2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} mt={1}>
                  <FormControl
                    required
                    variant="outlined"
                    size="small"
                    fullWidth>
                    <InputLabel>Relation With Guardian</InputLabel>
                    <Select
                      key={formData.guardianRelation}
                      required
                      label="Relation With Guardian"
                      value={formData.guardianRelation}
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="guardianRelation"
                      onChange={handleChange}>
                      <MenuItem value="none">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Father">Father</MenuItem>
                      <MenuItem value="Mother">Mother</MenuItem>
                      <MenuItem value="Sister">Sister</MenuItem>
                      <MenuItem value="Brother">Brother</MenuItem>
                      <MenuItem value="Uncle">Uncle</MenuItem>
                      <MenuItem value="Maternal Uncle">Maternal Uncle</MenuItem>
                      <MenuItem value="Other Relatve">Other Relative</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    label="National ID"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    value={formData.nationalId}
                    name="nationalId"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    label="Present Address"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="presentAddress"
                    value={formData.presentAddress}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    label="Permanent Address"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="permanentAddress"
                    value={formData.permanentAddress}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Paper>

            {/* =====================Academic Information=============== */}

            <Paper sx={styles} elevation={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Heading>Academic Information:</Heading>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormControl
                    required
                    variant="outlined"
                    size="small"
                    fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Class
                    </InputLabel>
                    <Select
                      key={formData.class}
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      required
                      label="Class"
                      name="class"
                      value={formData.class}
                      onChange={handleChange}>
                      {classes.map((data) => (
                        <MenuItem value={data._id} key={data._id}>
                          {data.className}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <FormControl
                    required
                    variant="outlined"
                    size="small"
                    fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Academic Year
                    </InputLabel>
                    <Select
                      label="Academic Year"
                      name="academicYear"
                      value={formData.academicYear || ""}
                      onChange={handleChange}>
                      {academicYears &&
                        academicYears.map((row, index) => (
                          <MenuItem key={row._id} value={row._id}>
                            {row.academicYearFrom} - {row.academicYearTo}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>

            {/* =====================Previous School Information=============== */}

            <Paper sx={styles} elevation={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Heading>Previous School Information:</Heading>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    label="Previous School"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="previousSchool"
                    value={formData.previousSchool}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    label="Previous Class"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="previousClass"
                    value={formData.previousClass}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    label="TC Number"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="tcNumber"
                    value={formData.tcNumber}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Paper>

            {/* =====================Father Information=============== */}

            <Paper sx={styles} elevation={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Heading>Father Information:</Heading>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    label="Father Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="fatherName"
                    margin="dense"
                    value={formData.fatherName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    required
                    label="Father Phone No"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="fatherPhone"
                    value={formData.fatherPhone}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    label="Father Education"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="fatherEdu"
                    value={formData.fatherEdu}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    label="Father Profession"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="fatherProfession"
                    value={formData.fatherProfession}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    label="Father Designation"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="fatherDesignation"
                    value={formData.fatherDesignation}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Paper>

            {/* =====================Mother Information=============== */}

            <Paper sx={styles} elevation={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Heading>Mother Information:</Heading>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    label="Mother Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="motherName"
                    value={formData.motherName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    label="Mother Phone No"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="motherPhone"
                    value={formData.motherPhone}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    label="Mother Education"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="motherEdu"
                    value={formData.motherEdu}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    label="Mother Profession"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="motherProfession"
                    value={formData.motherProfession}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    label="Mother Designation"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="motherDesignation"
                    value={formData.motherDesignation}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Paper>

            {/* =====================Other Information=============== */}

            <Paper sx={styles} elevation={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Heading>Other Information:</Heading>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    label="Health Condition"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="healthCondition"
                    value={formData.healthCondition}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} mt={1}>
                  <FormControl
                    required
                    variant="outlined"
                    size="small"
                    fullWidth>
                    <InputLabel>Hostel Required</InputLabel>
                    <Select
                      key={formData.hostel}
                      required
                      label="Hostel Required"
                      name="hostel"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      value={formData.hostel}
                      onChange={handleChange}>
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3} mt={1}>
                  <FormControl
                    required
                    variant="outlined"
                    size="small"
                    fullWidth>
                    <InputLabel>Transport Required</InputLabel>
                    <Select
                      key={formData.transport}
                      required
                      label="Transport Required"
                      labelId="demo-simpless-select-filled-label"
                      id="demo-simple-select-filled-l"
                      name="transport"
                      value={formData.transport}
                      onChange={handleChange}>
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                  <TextField
                    label="Other Information"
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                    name="otherInfo"
                    value={formData.otherInfo}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Paper>

            {/* =====================Cancel && Submit=============== */}

            <Paper sx={styles} elevation={3}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  display="flex"
                  justifyContent="flex-end"
                  gap={2}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate("/")}
                    size="small"
                    color="error">
                    Cancel
                  </Button>
                  <LoadingButton
                    id="pre-admission"
                    type="submit"
                    loading={loader}
                    variant="contained"
                    sx={{
                      // color: "white",
                      background: "#F86F03",
                      "&:hover": { backgroundColor: "#F86F03" },
                    }}>
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </Paper>
          </form>
        </Container>
        <Footer />
      </OuterBox>
    </>
  );
};

export default Admission;
