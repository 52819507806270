import Slider from "react-slick";
import styled from "@emotion/styled";
export const AppSlider = styled(Slider)`
  width: 100%;
  .slick-track {
    display: flex;
    padding: 40px 0 40px 0;
    //overflow: hidden;
  }
  .slick-slide {
    display: flex;
    justify-content: center;
    margin-bottom: 1;
    // overflow: hidden;
    outline: none;
  }
  .slick-list {
    overflow: hidden;
  }
`;
