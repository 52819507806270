import React from "react";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { StylesProvider } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-calendar/dist/Calendar.css";
import "react-quill/dist/quill.snow.css";
import "./App.scss";
import Home from "./pages/HomePage";
import "react-modal-video/css/modal-video.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import About from "./components/About/About";
import NavTopnav from "./components/NavTopnav/NavTopnav";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { urls } from "./services/urlConstant";
import { get } from "./services/apis";
import NavBar from "./NavBar";
import PrivateRoute from "./PrivateRoute";
import Academics from "./components/Academics";
import Primary from "./components/Primary";
import HighSchool from "./components/HighSchool";
import Library from "./components/Library";
import Sports from "./components/Sports";
import Canteen from "./components/Canteen";
import Transport from "./components/Transport";
import EduSmart from "./components/EduSmart";
import ContactUs from "./components/ContactUs";
import PublicRoute from "./PublicRoute";
import Results from "./components/Results";
import Gallery from "./components/Layout/Gallery/gallery";
import GalleryHome from "./components/GalleryHome";
import OurSchool from "./components/Our school/OurSchool";
import Principal from "./components/PrincipalDesk/Principal";
import Faculty from "./components/Faculty/Faculty";
import SettingContext from "./context/SettingsContext";
import EventHome from "./components/EventHome";
import UniqueFeatures from "./components/UniqueFeatures";
import KnowledgeOfParents from "./components/KnowledgeOfParents";
import Careers from "./components/Careers";
import HomeNews from "./components/HomeNews";
import HomeNewsView from "./components/HomeNewsView";
import HomeNotice from "./components/HomeNotice";
import HomeNoticeView from "./components/HomeNoticeView";

import AssignmentHome from "./components/AssignmentHome";
import AssignmentView from "./components/AssignmentView";

import Birthday from "./components/Layout/Birthday/Birthday";
import AwardsAndAchivments from "./components/AwardsAndAchivments";
import LatestEventsById from "./components/LatestEventsById";
import Toppers from "./components/toppers";

import { TableDrawer } from "./context/DrawerComponent";
import AboutFounder from "./components/About/AboutFounder";
import DanceAndSinging from "./components/DanceAndSinging";
import OverView from "./components/About/OverView";
import VisionMission from "./components/About/VisionMission";
import EventDetails from "./components/SchoolCard/EventDetails";
import CourseDetails from "./components/HomeComponents/CourseDetails";
import DrawerWidthContext from "./context/DrawerWidthContext";
import ScrollToTop from "./components/ScrollToTop";
import PreAdmission from "./components/PreAdmission";

// theme 2 components
import Addmission2 from "./Components2/Admission";
import OverView2 from "./Components2/about/Overview";
import AboutFounder2 from "./NavbarComponents/AboutFounder";
import VisionMission2 from "./NavbarComponents/VisionandMission";
import ContactUs2 from "./Components2/ConatctUs";
import Canteen2 from "./Components2/Category/Facilities/Food";
import Library2 from "./Components2/Category/Facilities/Library";
import Transport2 from "./Components2/Category/Facilities/Transport";
import DanceAndSinging2 from "./Components2/Category/Facilities/Dance";
import LabFacilities from "./Components2/Category/Facilities/Lab";
import Results2 from "./Components2/Results";
import NewsDetails from "./Components2/NewsAndNotice/NewsDetails";
import EventInfo from "./Components2/events/EventInfo";
import Detail from "./ourblog/Detail";
import NavbarProfile from "./components/Layout/NavbarProfile/NavbarProfile";
import PageNotFound from "./pages/PageNotFound";
import UserTypeContext from "./context/UserTypeContext";
import { theme } from "./theme";
import { ThemeProvider } from "@mui/styles";
import RoleUpdateCountContext from "./context/RoleUpdateCountContext";
import CbscMandatory from "./components/CbscMandatory/CbscMandatory";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const excludePath = [
  "/home-assignment",
  "/home-notice",
  `/home-news`,
  "/home-news",
  "/home-careers",
  "/to-the-knowledge-of-parents",
  "/unique-features",
  "/pre-admission",
  "/home-gallery",
  "/results",
  "/cbse-mandatory",
  "/contact-us",
  "/facilities/edusmart",
  "/facilities/transport",
  "/",
  "/login",
  "/forgot-password",
  "/principal",
  "/desk",
  "/facality",
  "/addmission",
  "/our-school",
  "/academics",
  "/academics/primary",
  "/academics/high-school",
  "/facilities/library",
  "/facilities/sports",
  "/facilities/canteen",
  "/view-assignment",
  "/about/founder",
  "/about/visionandmission",
  "/about/overview",
  "/facilities/dance-and-singing",
  "/eventdetails",
  "/courseDetails",
  "/unique-features",
  "/to-the-knowledge-of-parents",
  "/facilities/labs",
  "/pre-admission/exam-conduct",
  "/details/pre-admission/",
  "/home/events",
];

function App() {
  const [isReady, setIsReady] = useState(false);
  const [roleUpdateCount, setRoleUpdateCount] = useState(0);
  const location = useLocation();
  const { pathname } = location;
  let updateNav = false;
  const token = window.localStorage.getItem(process.env.REACT_APP_TOKEN);
  if (token) updateNav = true;
  const [reRender, setRender] = useState(1);
  const [showDrawerHeader, setShowDrawerHeader] = useState(true);
  const [settings, setSettings] = useState([]);
  const [selectedSetting, setSelectedSetting] = useState({
    schoolName: "ABC School",
    email: "abc@gmail.com",
    phone: 9999999999,
    themeOrder: 1,
    deleted: false,
  });
  const [drawerWidth, setDraweWidth] = useState(0);
  const [canShowDrawer, setCanShowDrawer] = useState(false);
  const [settingsUpdateCount, setSettingsUpdateCount] = useState(0);
  const [notificationsUpdateCount, setNotificationsUpdateCount] = useState(0);

  const [popupData, setPopupData] = useState({
    open: false,
    data: null,
  });
  const [horizontalData, setHorizontalData] = useState([]);

  const [userType, setUserType] = useState("student");

  const getCurrentUser = async () => {
    try {
      let token = window.localStorage.getItem(process.env.REACT_APP_TOKEN);
      if (!token) {
        // window.location.href = "/";
      } else {
        let decodedToken = jwtDecode(token);
        if (decodedToken.userType === "employee") {
          console.log("Employee");
          setUserType("employee");
        } else if (decodedToken.userType === "student") {
          console.log("student");
          setUserType("student");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  const handleClosePopup = () => setPopupData({ open: false, data: null });

  const getSettings = async () => {
    try {
      const { data } = await get("/123/settings");
      setSettings(data.data);
      setSelectedSetting(
        data.data[0] || {
          schoolName: "ABC School",
          email: "abc@gmail.com",
          phone: 9999999999,
          themeOrder: 1,
          deleted: false,
        }
      );
    } catch (error) {
      console.log(error);
    }
    setIsReady(true);
  };

  useEffect(() => {
    getSettings();
  }, [settingsUpdateCount]);

  const [settingsContext, setSettingsContext] = useState({
    schoolName: "",
    schoolLogo: "",
    activeAcademicYear: "",
  });

  const getSplashNews = async () => {
    try {
      const { data } = await get(
        selectedSetting._id + urls.announceFlashNews.getFlashNews
      );
      if (data.data.length) {
        let allSplashNews = data.data;
        setHorizontalData(
          allSplashNews.filter(
            (s) => s.type === "Horizontal" && s.webView === "yes"
          )
        );
        setPopupData({
          open: false,
          data: allSplashNews.filter(
            (s) => s.type === "Popup" && s.webView === "yes"
          )[0],
        });
      } else {
        setHorizontalData([]);
        setPopupData({ open: false, data: null });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedSetting && selectedSetting._id) {
      getSplashNews();
    }
  }, [selectedSetting]);

  useEffect(() => {
    if (popupData.data) {
      setPopupData({ ...popupData, open: true });
    }
  }, [popupData.data]);

  useEffect(() => {
    const exclude =
      excludePath.includes(pathname) ||
      pathname.startsWith("/home-news") ||
      pathname.startsWith("/home-notice") ||
      pathname.startsWith("/view-assignment") ||
      pathname.startsWith("/awards-home") ||
      pathname.startsWith("/events-home") ||
      pathname.startsWith("/home/events") ||
      pathname.startsWith("/eventdetails") ||
      pathname.startsWith("/courseDetails") ||
      pathname.startsWith("/details") ||
      pathname.startsWith("/events") ||
      pathname.startsWith("/ourachievement/") ||
      pathname.startsWith("/pre-admission/exam-conduct") ||
      pathname.startsWith("/details/pre-admission/") ||
      pathname.startsWith("/toppers-list");
    setShowDrawerHeader(!exclude);
  }, [location.pathname]);

  useEffect(() => {
    for (let path of excludePath) {
      if (pathname.startsWith(path)) {
        return setCanShowDrawer(false);
      }
    }
  }, [location.path]);

  const triggerRender = () => {
    setRender((prev) => prev + 1);
  };

  let [drawer, setDrawer] = useState(true);
  const [padding, setPadding] = useState("270px");

  useEffect(() => {
    if (drawer) {
      setPadding("270px");
    } else {
      setPadding("60px");
    }
  }, [drawer]);

  if (!isReady) return null;

  return (
    <ThemeProvider theme={theme}>
      <SettingContext.Provider
        value={{
          settingsContext,
          setSettingsContext,
          settings,
          setSettings,
          selectedSetting,
          setSelectedSetting,
          settingsUpdateCount,
          setSettingsUpdateCount,
          notificationsUpdateCount,
          setNotificationsUpdateCount,
        }}
      >
        <RoleUpdateCountContext.Provider
          value={{ roleUpdateCount, setRoleUpdateCount }}
        >
          <UserTypeContext.Provider value={{ userType, setUserType }}>
            <ScrollToTop />
            <TableDrawer.Provider value={{ drawer, setDrawer }}>
              <DrawerWidthContext.Provider
                value={{ drawerWidth, setDraweWidth }}
              >
                <Box
                  sx={{
                    paddingLeft:
                      updateNav &&
                      !excludePath.includes(pathname) &&
                      userType === "employee" &&
                      showDrawerHeader
                        ? padding
                        : 0,
                  }}
                >
                  <CssBaseline />
                  {updateNav && <NavBar reRender={reRender} />}
                  <div className="App">
                    <Box component="main">
                      {showDrawerHeader && userType === "employee" && (
                        <DrawerHeader />
                      )}

                      {userType === "employee" ? (
                        <StylesProvider injectFirst>
                          <Routes>
                            <Route path="/" element={<NavTopnav />} exact>
                              <Route path="/" element={<Home />} />
                              <Route path="/about" element={<About />} />
                            </Route>

                            <Route path="/desk" element={<Faculty />} />

                            <Route path="/principal" element={<Principal />} />

                            <Route
                              path="/profile"
                              element={
                                <PrivateRoute>
                                  <NavbarProfile />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/birthday"
                              element={
                                <PrivateRoute>
                                  <Birthday />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/about/visionandmission"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <VisionMission />
                                ) : (
                                  <VisionMission2 />
                                )
                              }
                            />
                            <Route path="/academics" element={<Academics />} />
                            <Route
                              path="/academics/primary"
                              element={<Primary />}
                            />
                            <Route
                              path="/academics/high-school"
                              element={<HighSchool />}
                            />
                            <Route
                              path="/courseDetails/:id"
                              element={<CourseDetails />}
                            />
                            <Route
                              path="/about/founder"
                              element={
                                selectedSetting?.themeOrder == 1 ? (
                                  <AboutFounder />
                                ) : (
                                  <AboutFounder2 />
                                )
                              }
                            />

                            <Route
                              path="/about/overview"
                              element={
                                selectedSetting?.themeOrder == 1 ? (
                                  <OverView />
                                ) : (
                                  <OverView2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/library"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Library />
                                ) : (
                                  <Library2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/labs"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <PageNotFound />
                                ) : (
                                  <LabFacilities />
                                )
                              }
                            />
                            <Route
                              path="/facilities/sports"
                              element={<Sports />}
                            />
                            <Route
                              path="/facilities/dance-and-singing"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <DanceAndSinging />
                                ) : (
                                  <DanceAndSinging2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/labs"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <PageNotFound />
                                ) : (
                                  <LabFacilities />
                                )
                              }
                            />
                            <Route
                              path="/facilities/canteen"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Canteen />
                                ) : (
                                  <Canteen2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/transport"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Transport />
                                ) : (
                                  <Transport2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/edusmart"
                              element={<EduSmart />}
                            />
                            <Route
                              path="/contact-us"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <ContactUs />
                                ) : (
                                  <ContactUs2 />
                                )
                              }
                            />
                            <Route
                              path="/pre-admission"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <PreAdmission />
                                ) : (
                                  <Addmission2 />
                                )
                              }
                            />
                            <Route
                              path="/results"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Results />
                                ) : (
                                  <Results2 />
                                )
                              }
                            />

                            <Route
                              path="/cbse-mandatory"
                              element={<CbscMandatory />}
                            />
                            <Route
                              path="/details/:id"
                              element={<NewsDetails />}
                            />
                            <Route
                              path="/home/events/:id"
                              element={<EventInfo />}
                            />
                            <Route
                              path="/ourachievement/:id"
                              element={<Detail />}
                            />
                            <Route path="/gallery" element={<Gallery />} />

                            <Route
                              path="/home-gallery"
                              element={<GalleryHome />}
                            />
                            <Route path="/our-school" element={<OurSchool />} />
                            <Route path="/principal" element={<Principal />} />
                            <Route path="/facality" element={<Faculty />} />

                            <Route path="/event-home" element={<EventHome />} />
                            <Route
                              path="/unique-features"
                              element={<UniqueFeatures />}
                            />
                            <Route
                              path="/to-the-knowledge-of-parents"
                              element={<KnowledgeOfParents />}
                            />
                            <Route path="/home-careers" element={<Careers />} />

                            <Route
                              path="/birthday"
                              element={
                                <PrivateRoute roleFunction="birthday">
                                  <Birthday />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/eventdetails/:id"
                              element={<EventDetails />}
                            />

                            <Route path="/academics" element={<Academics />} />
                            <Route
                              path="/academics/primary"
                              element={<Primary />}
                            />
                            <Route
                              path="/academics/high-school"
                              element={<HighSchool />}
                            />

                            <Route
                              path="/facilities/library"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Library />
                                ) : (
                                  <Library2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/sports"
                              element={<Sports />}
                            />
                            <Route
                              path="/facilities/canteen"
                              element={<Canteen />}
                            />
                            <Route
                              path="/facilities/transport"
                              element={<Transport />}
                            />
                            <Route
                              path="/facilities/edusmart"
                              element={<EduSmart />}
                            />
                            <Route path="/contact-us" element={<ContactUs />} />
                            <Route path="/results" element={<Results />} />
                            <Route
                              path="/cbse-mandatory"
                              element={<CbscMandatory />}
                            />
                            <Route path="/gallery" element={<Gallery />} />
                            <Route
                              path="/home-gallery"
                              element={<GalleryHome />}
                            />
                            <Route path="/our-school" element={<OurSchool />} />
                            <Route path="/principal" element={<Principal />} />
                            <Route path="/facality" element={<Faculty />} />

                            <Route path="/event-home" element={<EventHome />} />

                            <Route
                              path="/unique-features"
                              element={<UniqueFeatures />}
                            />

                            <Route
                              path="/to-the-knowledge-of-parents"
                              element={<KnowledgeOfParents />}
                            />
                            <Route path="/home-careers" element={<Careers />} />
                            <Route path="/home-news" element={<HomeNews />} />
                            <Route
                              path="/home-news/:newsId"
                              element={<HomeNewsView />}
                            />
                            <Route
                              path="/home-notice"
                              element={<HomeNotice />}
                            />

                            <Route
                              path="/home-notice/:noticeId"
                              element={<HomeNoticeView />}
                            />
                            <Route
                              path="/home-assignment"
                              element={<AssignmentHome />}
                            />
                            <Route
                              path="/view-assignment"
                              element={<AssignmentView />}
                            />
                            <Route
                              path="/awards-home/:awardsId"
                              element={<AwardsAndAchivments />}
                            />
                            <Route
                              path="/events-home/:eventsId"
                              element={<LatestEventsById />}
                            />
                            <Route path="/toppers-list" element={<Toppers />} />
                            <Route path="*" element={<PageNotFound />} />
                          </Routes>
                        </StylesProvider>
                      ) : (
                        <StylesProvider injectFirst>
                          <Routes>
                            <Route path="/" element={<NavTopnav />} exact>
                              <Route path="/" element={<Home />} />
                              <Route path="/about" element={<About />} />
                            </Route>

                            <Route
                              path="/profile"
                              element={
                                <PrivateRoute>
                                  <NavbarProfile />
                                </PrivateRoute>
                              }
                            />

                            <Route
                              path="/about/visionandmission"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <VisionMission />
                                ) : (
                                  <VisionMission2 />
                                )
                              }
                            />
                            <Route path="/academics" element={<Academics />} />
                            <Route
                              path="/academics/primary"
                              element={<Primary />}
                            />
                            <Route
                              path="/academics/high-school"
                              element={<HighSchool />}
                            />
                            <Route
                              path="/courseDetails/:id"
                              element={<CourseDetails />}
                            />

                            <Route
                              path="/about/founder"
                              element={
                                selectedSetting?.themeOrder == 1 ? (
                                  <AboutFounder />
                                ) : (
                                  <AboutFounder2 />
                                )
                              }
                            />

                            <Route
                              path="/about/overview"
                              element={
                                selectedSetting?.themeOrder == 1 ? (
                                  <OverView />
                                ) : (
                                  <OverView2 />
                                )
                              }
                            />

                            <Route
                              path="/facilities/library"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Library />
                                ) : (
                                  <Library2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/labs"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <PageNotFound />
                                ) : (
                                  <LabFacilities />
                                )
                              }
                            />
                            <Route
                              path="/facilities/sports"
                              element={<Sports />}
                            />
                            <Route
                              path="/facilities/dance-and-singing"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <DanceAndSinging />
                                ) : (
                                  <DanceAndSinging2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/labs"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <PageNotFound />
                                ) : (
                                  <LabFacilities />
                                )
                              }
                            />
                            <Route
                              path="/facilities/canteen"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Canteen />
                                ) : (
                                  <Canteen2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/transport"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Transport />
                                ) : (
                                  <Transport2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/edusmart"
                              element={<EduSmart />}
                            />
                            <Route
                              path="/contact-us"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <ContactUs />
                                ) : (
                                  <ContactUs2 />
                                )
                              }
                            />
                            <Route
                              path="/pre-admission"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <PreAdmission />
                                ) : (
                                  <Addmission2 />
                                )
                              }
                            />
                            <Route
                              path="/results"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Results />
                                ) : (
                                  <Results2 />
                                )
                              }
                            />
                            <Route
                              path="/cbse-mandatory"
                              element={<CbscMandatory />}
                            />

                            <Route
                              path="/details/:id"
                              element={<NewsDetails />}
                            />
                            <Route
                              path="/home/events/:id"
                              element={<EventInfo />}
                            />
                            <Route
                              path="/ourachievement/:id"
                              element={<Detail />}
                            />

                            <Route path="/gallery" element={<Gallery />} />

                            <Route
                              path="/home-gallery"
                              element={<GalleryHome />}
                            />
                            <Route path="/our-school" element={<OurSchool />} />
                            <Route path="/principal" element={<Principal />} />
                            <Route path="/facality" element={<Faculty />} />

                            <Route path="/event-home" element={<EventHome />} />
                            <Route
                              path="/unique-features"
                              element={<UniqueFeatures />}
                            />
                            <Route
                              path="/to-the-knowledge-of-parents"
                              element={<KnowledgeOfParents />}
                            />
                            <Route path="/home-careers" element={<Careers />} />

                            <Route
                              path="/eventdetails/:id"
                              element={<EventDetails />}
                            />
                            <Route path="/academics" element={<Academics />} />
                            <Route
                              path="/academics/primary"
                              element={<Primary />}
                            />
                            <Route
                              path="/academics/high-school"
                              element={<HighSchool />}
                            />

                            <Route
                              path="/facilities/library"
                              element={
                                selectedSetting?.themeOrder === 1 ? (
                                  <Library />
                                ) : (
                                  <Library2 />
                                )
                              }
                            />
                            <Route
                              path="/facilities/sports"
                              element={<Sports />}
                            />
                            <Route
                              path="/facilities/canteen"
                              element={<Canteen />}
                            />
                            <Route
                              path="/facilities/transport"
                              element={<Transport />}
                            />
                            <Route
                              path="/facilities/edusmart"
                              element={<EduSmart />}
                            />
                            <Route path="/contact-us" element={<ContactUs />} />

                            <Route path="/results" element={<Results />} />
                            <Route
                              path="/cbse-mandatory"
                              element={<CbscMandatory />}
                            />
                            <Route path="/gallery" element={<Gallery />} />
                            <Route
                              path="/home-gallery"
                              element={<GalleryHome />}
                            />
                            <Route path="/our-school" element={<OurSchool />} />
                            <Route path="/principal" element={<Principal />} />
                            <Route path="/facality" element={<Faculty />} />

                            <Route path="/event-home" element={<EventHome />} />
                            <Route
                              path="/unique-features"
                              element={<UniqueFeatures />}
                            />
                            <Route
                              path="/to-the-knowledge-of-parents"
                              element={<KnowledgeOfParents />}
                            />
                            <Route path="/home-careers" element={<Careers />} />

                            <Route path="/home-news" element={<HomeNews />} />
                            <Route
                              path="/home-news/:newsId"
                              element={<HomeNewsView />}
                            />
                            <Route
                              path="/home-notice"
                              element={<HomeNotice />}
                            />
                            <Route
                              path="/home-notice/:noticeId"
                              element={<HomeNoticeView />}
                            />
                            <Route
                              path="/home-assignment"
                              element={<AssignmentHome />}
                            />
                            <Route
                              path="/view-assignment"
                              element={<AssignmentView />}
                            />
                            <Route
                              path="/awards-home/:awardsId"
                              element={<AwardsAndAchivments />}
                            />
                            <Route
                              path="/events-home/:eventsId"
                              element={<LatestEventsById />}
                            />
                            <Route path="/toppers-list" element={<Toppers />} />
                            <Route path="*" element={<PageNotFound />} />
                          </Routes>
                        </StylesProvider>
                      )}
                    </Box>
                    <ToastContainer />
                  </div>
                </Box>
              </DrawerWidthContext.Provider>
            </TableDrawer.Provider>
          </UserTypeContext.Provider>
        </RoleUpdateCountContext.Provider>
      </SettingContext.Provider>
    </ThemeProvider>
  );
}

export default App;
