import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { grey } from "@mui/material/colors";
import moment from "moment";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  createTheme,
  IconButton,
} from "@mui/material";

const theme = createTheme();

const Btn = styled(Button)(({ theme }) => ({
  backgroundColor: "#ff6d34",
  border: "1px solid #ff6d34",
  color: " black",
  fontSize: "12px",
  fontFamily: "serif",
  marginRight: "13px",
  [theme.breakpoints.down("md")]: {
    marginRight: "0px",
  },
}));

const Title = styled(Box)(() => ({
  color: "#1976D2",
  fontSize: "2rem",
  fontWeight: 700,
}));

const Founder = styled(Box)(() => ({
  color: grey[500],
  fontSize: "14px",
  "&:hover": { color: "#ff6d34" },
}));

const Random = styled(Box)(({ theme }) => ({
  color: "gray",
  marginTop: "20px",
  // width: "40%",
  [theme.breakpoints.down(900)]: {
    width: "100%",
  },
}));

const Content = styled(Typography)(() => ({
  marginTop: "10px",
  color: "#2c2c2c",
  lineHeight: "30px",
}));

const Cardcontent = styled(CardContent)(({ theme }) => ({
  // width:"65%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CardImageWrapper = styled(Box)(({ theme }) => ({
  width: "500px",
  position: "relative",
  "&:hover": {
    position: "relative",
    borderRadius: "5px",
    backgroundColor: "rgba(0, 0, 0, 2.5)",
  },
  [theme.breakpoints.down("md")]: {
    width: "95%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CardImage = styled(CardMedia)(({ theme }) => ({
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const ViewMore = styled(CardMedia)(({ theme }) => ({
  fontSize: "13px",
  fontFamily: "Muli",
  color: "#1976D2",
  marginTop: "2%",
  "&:hover": {
    color: "#ef6c00",
  },
}));

// const AnchorBox = styled(Box)({
//   width: "65px",
//   "&::after": {
//     content: '""',
//     height: "3px",
//     marginTop: "2px",
//     backgroundColor: "#dfdfdf",
//     display: "block",
//     fontSize: "1rem",
//     transform: "scale(0,1)",
//     transition: "transform 0.35s ease",
//   },
//   "&:hover::after": {
//     width: "100%",
//     backgroundColor: "#ef6c00",
//     transform: "scale(1,1)",
//   },
// });

// const Our = styled(Typography)(({ theme }) => ({
//   textAlign: "center",
//   textTransform: "uppercase",
//   fontSize: "2.5rem",
//   [theme.breakpoints.down("sm")]: {
//     fontSize: "2rem",
//   },
// }));

const Month = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    paddingLeft: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "15px",
  },
  [theme.breakpoints.down("xs")]: {
    paddingLeft: "10px",
  },
}));

const Arc = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: 0,
  minWidth: "19%",
  borderRadius: "0 5px 0 90%",
  backgroundColor: "#00BDA6",
  // padding: '5px 0 30px',
  padding: "11px 0 23px",
  zIndex: "2",
}));

const CustomComponent = ({ elem }) => {
  const [showFull, setShowFull] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const isFullContentPage = currentPath === `/${elem.id}`;
    setShowFull(isFullContentPage);
  }, [elem.id]);

  let onlyDay = elem.fromDate;
  let EndDay = elem.toDate;

  const date = moment(onlyDay);
  const End_Day = moment(EndDay);
  const specificDate = date.format("Do");

  const specificMonth = date.format("Do, MMMM, YYYY");
  const specificMonthEnd = End_Day.format("Do, MMMM, YYYY");

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <CardImage
            component="img"
            image={elem.image.link}
            alt="Live from space album cover"
            height="100%"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Cardcontent>
            <Typography variant="subtitle1" fontWeight={600}>
              {specificMonth} - {specificMonthEnd}
            </Typography>
            <Title>Event: {elem.eventTitle}</Title>
            <Content>{elem.shortEvent}</Content>
            <Typography
              sx={{
                color: "#1976D2",
                fontSize: "16px",
                my: 2,
              }}
            >
              <b>Note:</b>
              <Typography variant="span"> {elem.note}</Typography>
            </Typography>
            <Random>
              <Typography variant="subtitle1">
                Event For:
                <b style={{ color: "black" }}> {elem.eventFor}</b>
              </Typography>
              <Typography variant="subtitle1">
                Sponsor:
                <b style={{ color: "black" }}> {elem.hostedBy}</b>
              </Typography>
              <Typography variant="subtitle1">
                Event Location:{" "}
                <b style={{ color: "black" }}> {elem.location}</b>
              </Typography>
            </Random>
          </Cardcontent>

          {/* <Random>
              <Content> {elem.eventFor}</Content>
              <Content>{elem.hostedBy}</Content>
            </Random> */}
        </Grid>
      </Grid>
    </>
  );
};

export default CustomComponent;
