import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from './Addmission.module.css'
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";

const EventHome = () => {
    const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  return (
    <>
        <TopNav />
        <Navbar />
      <div className={css.headerContainer}>
                <div>
                    <h1>EVENTS</h1>
                    <h5><Link style={{
                        color:'white'
                    }} to='/'>Home</Link> / EVENTS</h5>
                </div>
      </div>
      <div className={css.contentContainer}>
        <div className={css.content}>
            <div className={css.contentHolder}>
                <div className={css.contentText}>
                    For the convenience of students and their parents, we provide shuttle services for safe and timely arrival and departure of students to and from school. We operate a fleet of 13 hitech buses employing experienced drivers to ensure safety of children on all major roads of the town. Parents are requested to select designated pickup and drop points as part of the admission process. Once a route is selected any request for change in pickup and drop points will not be entertained on ordinary grounds.
                </div>
            </div>
        </div>
        <div className={css.newsNoticeContainer}>
            <div className={css.newsNotice}>
                <Demo />
            </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default EventHome;