import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled, Box, Container, Grid } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { grey, green, blue } from "@mui/material/colors";

import css from "./Custom.module.css";
import moment from "moment";

const Random = styled(Box)(({ theme }) => ({
  color: "gray",
  marginTop: "20px",
  // width: "40%",
  [theme.breakpoints.down(900)]: {
    width: "100%",
  },
}));

const Times = styled(Box)(({ theme }) => ({
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
  gap: "5px",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
}));

const Para = styled(Typography)(() => ({
  fontSize: "15px",
  lineHeight: "25px",
  color: grey[700],
}));
const TimeIcon = styled(AccessTimeIcon)(() => ({
  fontSize: "0.7rem",
  marginTop: "2px",
  color: "#f86f03",
}));
const Time = styled(Typography)(() => ({
  fontSize: "0.7rem",
}));

const Date = styled(Typography)(() => ({
  fontSize: "15px",
  textAlign: "center",
}));

// const CardImage = styled(CardMedia)(({ theme }) => ({
//   marginTop: "30px",
//   width: "90%",
//   [theme.breakpoints.down("md")]: {
//     width: "100%",
//   },
// }));

const CustomCard = styled(Card)(({ theme }) => ({
  minWidth: 450,
  maxWidth: 545,
  boxShadow: "2px 2px 2px 2px gray",
  [theme.breakpoints.down(600)]: {
    minWidth: 400,
  },
  [theme.breakpoints.down(450)]: {
    minWidth: 350,
  },
  [theme.breakpoints.down(400)]: {
    minWidth: 300,
  },
}));

const Title = styled(Box)(() => ({
  color: "#1976D2",
  fontSize: "2rem",
  fontWeight: 700,
}));

const Content = styled(Typography)(() => ({
  marginTop: "10px",
  color: "#2c2c2c",
  lineHeight: "30px",
}));

const CardImage = styled(CardMedia)(({ theme }) => ({
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CustomEventDetails = ({ data }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const time = moment(data.fromDate);
  const specificTime = time.format("h:mm A");

  let onlyDay = data.fromDate;
  let EndDay = data.toDate;

  const date = moment(onlyDay);
  const End_Day = moment(EndDay);
  const specificDate = date.format("Do");

  const specificMonth = date.format("Do, MMMM, YYYY");
  const specificMonthEnd = End_Day.format("Do, MMMM, YYYY");

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <CardImage
            component="img"
            image={data?.image?.link}
            alt="Live from space album cover"
            height="100%"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CardContent sx={{ marginLeft: "20px" }}>
            <Typography variant="subtitle1" fontWeight={600}>
              {specificMonth} - {specificMonthEnd}
            </Typography>
            <Title>Event: {data.eventTitle}</Title>
            {/* </Random> */}
            <Content>{data.shortEvent}</Content>
            <Typography
              sx={{
                color: "#1976D2",
                fontSize: "16px",
                my: 2,
              }}
            >
              <b>Note:</b>
              <Typography variant="span"> {data.note}</Typography>
            </Typography>
            <Random>
              <Typography variant="subtitle1">
                Event For:
                <b style={{ color: "black" }}> {data.eventFor}</b>
              </Typography>
              <Typography variant="subtitle1">
                Sponsor:
                <b style={{ color: "black" }}> {data.hostedBy}</b>
              </Typography>
              <Typography variant="subtitle1">
                Event Location:{" "}
                <b style={{ color: "black" }}> {data.location}</b>
              </Typography>
            </Random>
          </CardContent>
        </Grid>
      </Grid>

      {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CustomCard className={css.container}>
            <Typography
              variant="h4"
              sx={{ textAlign: "center" }}
              className={css.date}
            >
              {specificDate}
            </Typography>
            <Date> {specificMonth}</Date>
            <CardMedia sx={{ height: 400 }} image={data?.image?.link} />
            <CardContent>
              <Typography
                gutterBottom
                sx={{ textAlign: "center" }}
                className={css.title}
              >
                {data.eventTitle}
              </Typography>
              <Random>
                <Times>
                  <TimeIcon></TimeIcon>
                  <Time className={css.time}>{specificTime}</Time>
                </Times>
              </Random>
              <br />
              <Para>{data.shortEvent}</Para>
            </CardContent>
          </CustomCard>
        </Box> */}
    </>
  );
};
export default CustomEventDetails;
