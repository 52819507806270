import { Opacity, TableRows } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Container,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextareaAutosize,
  styled,
  tableCellClasses,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Menu from "@mui/material/Menu";
import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import "../../../styles/ProfileTable/ProfileTable.scss";
import { urls as url } from "../../../services/urlConstant";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import SectionSubHeader from "../../../components/SectionSubHeader";
// import { get } from "react-scroll/modules/mixins/scroller";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { get, post, put, del } from "../../../services/apis";
import { LoadingButton } from "@mui/lab";

const styles = {
  icon: {
    padding: "10px",
    fontSize: "12px",
    fontWeight: 500,
  },
  card: {
    padding: 15,
    marginBottom: 20,
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  textfield: {
    width: "100%",
    label: { fontSize: 12 },
  },
  tab: {
    // width: 650,
    // borderCollapse: "collapse",
    // marginBottom: "5px",
    my: 2,
  },
  Button: {
    background: "#1b3779",
    marginLeft: "20px",
    color: "#fff",
    ":hover": { background: "#1b3779" },
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid gray",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    width: "100%",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
const style = {
  card: {
    color: "red",
    fontFamily: "cursive",
    textAlign: "start",
  },
  card1: {
    padding: 15,
    marginBottom: 20,
    margin: "70px 200px",
  },
};

const NavbarProfile = () => {
  const [tabValue, setTabValue] = useState(0);
  const { selectedSetting } = useContext(SettingContext);

  const [loadingImg, setLoadingImg] = useState(false);
  const [loadingResume, setLoadingResume] = useState(false);
  const [dob, setDob] = useState(dayjs());
  const [join, setJoin] = useState(dayjs());
  const [resume, setResume] = useState(null);
  const [designations, setDesignations] = useState([]);
  const [empPhoto, setEmpPhoto] = useState("");
  const [employee, setEmployee] = useState({
    basicInfo: {
      empName: "",
      designation: "",
      empGender: "",
      empBloodGroup: "",
      Religion: "",
      // dob: "",
      presentAddress: "",
      permanentAddress: "",
      aadharNo: "",
    },
    academicInfo: {
      email: "",
      // joiningDate: "",
    },
    otherInfo: {
      facebookUrl: "",
      twitterUrl: "",
      linkedinUrl: "",
      googlePlusUrl: "",
      youtubeUrl: "",
      instagramUrl: "",
      pinterestUrl: "",
    },
    username: "",
    empPhoto: "",
    contactNumber: "",
  });

  const [profileId, setProfileId] = useState("");
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleResume = async (e) => {
    const formData = new FormData();
    setLoadingResume(true);
    if (e.target.files.length > 0) {
      formData.append("files", e.target.files[0]);
      try {
        const res = await post(urls.fileUpload.postFileUpload, formData, {
          headers: { roleFunction: "auth" },
        });

        if (res.data.success) {
          setEmployee({
            ...employee,
            academicInfo: {
              ...employee.academicInfo,
              resume: res.data.result[0]._id,
            },
          });
          // setResume(res.data.result[0].link);
          setResume(e.target.files[0].name);
        }
        setLoadingResume(false);
      } catch (error) {
        console.error(error);
        setLoadingResume(false);
      }
    }
  };

  const handleEmpPhoto = async (e) => {
    const formData = new FormData();
    setLoadingImg(true);

    if (e.target.files.length > 0) {
      formData.append("files", e.target.files[0]);
      try {
        const res = await post(urls.fileUpload.postFileUpload, formData, {
          headers: { roleFunction: "auth" },
        });

        if (res.data.success) {
          setEmployee({
            ...employee,
            empPhoto: res.data.result[0]._id,
          });
          setEmpPhoto(res.data.result[0].link);
        }
        setLoadingImg(false);
      } catch (error) {
        console.error(error);
        setLoadingImg(false);
      }
    }
  };

  useEffect(() => {
    const profileId = window.localStorage.getItem(
      process.env.REACT_APP_PROFILE_ID
    );
    setProfileId(profileId);
  }, []);

  const getCurrentEmployee = async () => {
    try {
      const profileId = window.localStorage.getItem(
        process.env.REACT_APP_PROFILE_ID
      );

      const getData = await get(`${urls.employee.getEmployeeId}${profileId}`, {
        headers: { roleFunction: "auth" },
      });
      // console.log(getData.data.data, "datata");
      setEmployee(getData.data.data);
      window.localStorage.setItem(
        process.env.REACT_APP_CURRENT_USER,
        JSON.stringify(getData.data.data)
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (profileId) {
      getCurrentEmployee();
    }
  }, [profileId]);

  useEffect(() => {
    const getDesignation = async () => {
      try {
        const res = await get(urls.designation.getAllDesignation, {
          headers: { roleFunction: "auth" },
        });
        setDesignations(res.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    getDesignation();
  }, []);

  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };

  const handleOtherInfoChange = (e) => {
    const { name, value } = e.target;
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      otherInfo: {
        ...prevEmployee.otherInfo,
        [name]: value,
      },
    }));
  };

  const handleDateChange = (newValue) => {
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      basicInfo: {
        ...prevEmployee.basicInfo,
        dob: newValue,
      },
    }));
  };

  const handleJoinDateChange = (newValue) => {
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      academicInfo: {
        ...prevEmployee.academicInfo,
        joiningDate: newValue,
      },
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployee((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOnChange = (field, value) => {
    return setEmployee({ ...employee, [field]: value });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let data = {
        basicInfo: {
          empName: employee.basicInfo.empName,
          designation: employee.basicInfo.designation,
          empGender: employee.basicInfo.empGender,
          empBloodGroup: employee.basicInfo.empBloodGroup,
          Religion: employee.basicInfo.Religion,
          dob: employee.basicInfo.dob,
          presentAddress: employee.basicInfo.presentAddress,
          permanentAddress: employee.basicInfo.permanentAddress,
          aadharNo: employee.basicInfo.aadharNo,
        },
        academicInfo: {
          email: employee.academicInfo.email,
          joiningDate: employee.academicInfo.joiningDate,
          resume: employee.academicInfo.resume,
          // department: employee.academicInfo.department,
        },
        otherInfo: {
          facebookUrl: employee.otherInfo?.facebookUrl,
          twitterUrl: employee.otherInfo?.twitterUrl,
          linkedinUrl: employee.otherInfo?.linkedinUrl,
          googlePlusUrl: employee.otherInfo?.googlePlusUrl,
          youtubeUrl: employee.otherInfo?.youtubeUrl,
          instagramUrl: employee.otherInfo?.instagramUrl,
          pinterestUrl: employee.otherInfo?.pinterestUrl,
        },
        username: employee.username,
        empPhoto: employee.empPhoto,
        contactNumber: employee.contactNumber,
      };

      // console.log(data, "payloaddata");

      const res = await put(
        `${urls.employee.putEmployee}${profileId}`,
        "",
        data,
        { headers: { roleFunction: "auth" } }
      );

      getCurrentEmployee();
      setTabValue(0);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <SectionSubHeader title="Profile" style={{ margin: "15px" }} />
      <Card style={{ margin: 15, padding: 10 }}>
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Profile" {...a11yProps(0)} />
            <Tab label="Update" {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Card>
      <TabPanel value={tabValue} index={0}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {employee.empPhoto && employee.empPhoto.link ? (
            <img
              src={employee.empPhoto.link}
              height={85}
              width={85}
              style={{ borderRadius: "50%" }}
              alt="Employee Photo"
            />
          ) : (
            <Avatar sx={{ height: 100, width: 100 }} src="/broken-image.jpg" />
          )}

          {employee && (
            // <Box my={2}>
            <TableContainer
              component={Paper}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Table
                aria-label="customized table"
                className="profile-table"
                sx={{
                  width: 650,
                  my: 2,
                  display: {
                    xs: "none",
                    sm: "block",
                    md: "block",
                    lg: "block",
                  },
                }}
              >
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.basicInfo && employee.basicInfo.empName
                        ? employee.basicInfo.empName
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      National Id
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.basicInfo && employee.basicInfo.aadharNo
                        ? employee.basicInfo.aadharNo
                        : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Designation
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.basicInfo && employee.basicInfo.designation
                        ? employee.basicInfo.designation.designation
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Phone{" "}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.contactNumber ? employee.contactNumber : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Present Address{" "}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.basicInfo && employee.basicInfo.presentAddress
                        ? employee.basicInfo.presentAddress
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Permanent Address
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.basicInfo && employee.basicInfo.permanentAddress
                        ? employee.basicInfo.permanentAddress
                        : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Gender
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.basicInfo && employee.basicInfo.empGender
                        ? employee.basicInfo.empGender
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Blood Group
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.basicInfo && employee.basicInfo.empBloodGroup
                        ? employee.basicInfo.empBloodGroup.toUpperCase()
                        : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Religion{" "}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.basicInfo && employee.basicInfo.Religion
                        ? employee.basicInfo.Religion
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Birth Date
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {dayjs(
                        employee.basicInfo && employee.basicInfo.dob
                          ? employee.basicInfo.dob
                          : "NA"
                      ).format("DD-MM-YYYY")}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Email
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.academicInfo && employee.academicInfo.email
                        ? employee.academicInfo.email
                        : "NA"}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Role
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.role && employee.role.roleName
                        ? employee.role.roleName
                        : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Joining Date
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {dayjs(
                        employee.academicInfo &&
                          employee.academicInfo.joiningDate
                          ? employee.academicInfo.joiningDate
                          : "NA"
                      ).format("DD-MM-YYYY")}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Resume
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {employee.academicInfo.resume ? (
                        <IconButton color="primary">
                          <FileDownloadIcon
                            onClick={() =>
                              handleLinkClick(
                                employee.academicInfo &&
                                  employee.academicInfo.resume
                                  ? employee.academicInfo.resume.link
                                  : "NA"
                              )
                            }
                          />
                        </IconButton>
                      ) : (
                        "NA"
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>

              {/* ======== Mobile view table ============ */}
              <Table
                aria-label="customized table"
                className="profile-table-mobile"
                sx={{
                  width: "100%",
                  mx: 2,
                  my: 2,
                  display: {
                    xs: "visible",
                    sm: "none",
                    md: "none",
                    lg: "none",
                  },
                }}
              >
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.basicInfo && employee.basicInfo.empName
                        ? employee.basicInfo.empName
                        : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      National Id
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.basicInfo && employee.basicInfo.aadharNo
                        ? employee.basicInfo.aadharNo
                        : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Designation
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.basicInfo && employee.basicInfo.designation
                        ? employee.basicInfo.designation.designation
                        : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Phone{" "}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.contactNumber ? employee.contactNumber : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Present Address{" "}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.basicInfo && employee.basicInfo.presentAddress
                        ? employee.basicInfo.presentAddress
                        : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Permanent Address
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.basicInfo && employee.basicInfo.permanentAddress
                        ? employee.basicInfo.permanentAddress
                        : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Gender
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.basicInfo && employee.basicInfo.empGender
                        ? employee.basicInfo.empGender
                        : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Blood Group
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.basicInfo && employee.basicInfo.empBloodGroup
                        ? employee.basicInfo.empBloodGroup.toUpperCase()
                        : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Birth Date
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {dayjs(
                        employee.basicInfo && employee.basicInfo.dob
                          ? employee.basicInfo.dob
                          : "NA"
                      ).format("DD-MM-YYYY")}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Religion{" "}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.basicInfo && employee.basicInfo.Religion
                        ? employee.basicInfo.Religion
                        : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Email
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.academicInfo && employee.academicInfo.email
                        ? employee.academicInfo.email
                        : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Role
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {employee.role && employee.role.roleName
                        ? employee.role.roleName
                        : "NA"}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Joining Date
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {dayjs(
                        employee.academicInfo &&
                          employee.academicInfo.joiningDate
                          ? employee.academicInfo.joiningDate
                          : "NA"
                      ).format("DD-MM-YYYY")}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell align="left" sx={{ fontWeight: "bold" }}>
                      Resume
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {employee.academicInfo.resume ? (
                        <IconButton color="primary">
                          <FileDownloadIcon
                            onClick={() =>
                              handleLinkClick(
                                employee.academicInfo &&
                                  employee.academicInfo.resume
                                  ? employee.academicInfo.resume.link
                                  : "NA"
                              )
                            }
                          />
                        </IconButton>
                      ) : (
                        "NA"
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            // </Box>
          )}
        </Box>

        {/* // ================================= */}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                <h5 style={{ color: "#1b3779" }}>Basic Information:</h5>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="outlined"
                  size="small"
                  label="Name"
                  name="empName"
                  value={employee.basicInfo.empName || ""}
                  onChange={(e) =>
                    handleOnChange("basicInfo", {
                      ...employee.basicInfo,
                      empName: e.target.value,
                    })
                  }
                  // defaultValue={employee.basicInfo&&employee.basicInfo.empName?employee.basicInfo.empName:"NA"}
                  required
                  style={styles.textfield}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="outlined"
                  size="small"
                  label="National Id"
                  name="aadharNo"
                  style={styles.textfield}
                  value={employee.basicInfo.aadharNo || ""}
                  onChange={(e) =>
                    handleOnChange("basicInfo", {
                      ...employee.basicInfo,
                      aadharNo: e.target.value,
                    })
                  }
                  // defaultValue={employee.academicInfo&&employee.academicInfo.aadharNo?employee.academicInfo.aadharNo:""}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl
                  variant="outlined"
                  size="small"
                  sx={styles.textfield}
                  required
                >
                  <InputLabel
                    id="demo-simple-select-filled-label"
                    sx={{ fontSize: 12 }}
                  >
                    Designation
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    label="Designation"
                    id="demo-simple-select-filled"
                    value={employee.basicInfo.designation._id || ""}
                    name="designation"
                    onChange={(e) =>
                      handleOnChange("basicInfo", {
                        ...employee.basicInfo,
                        designation: designations.filter(
                          (d) => d._id == e.target.value
                        )[0],
                      })
                    }
                  >
                    {designations &&
                      designations.map((row, index) => (
                        <MenuItem
                          key={row._id}
                          value={row._id}
                          sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                          {row.designation}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="outlined"
                  size="small"
                  label="Phone"
                  name="contactNumber"
                  required
                  style={styles.textfield}
                  value={employee.contactNumber || ""}
                  // defaultValue={employee.contactNumber?employee.contactNumber:""}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <FormControl
                  // variant="outlined"
                  size="small"
                  style={styles.textfield}
                  required
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Gender
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    label="Gender"
                    id="demo-simple-select-filled"
                    name="empGender"
                    value={employee.basicInfo.empGender || ""}
                    onChange={(e) =>
                      handleOnChange("basicInfo", {
                        ...employee.basicInfo,
                        empGender: e.target.value,
                      })
                    }
                    // defaultValue={employee.basicInfo&&employee.basicInfo.empGender?employee.basicInfo.empGender:""}
                  >
                    <MenuItem
                      value={"male"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Male
                    </MenuItem>
                    <MenuItem
                      value={"female"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      Female
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={styles.textfield}
                  required
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Blood Group
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    label="Blood Group"
                    id="demo-simple-select-filled"
                    name="empBloodGroup"
                    value={employee.basicInfo.empBloodGroup || ""}
                    onChange={(e) =>
                      handleOnChange("basicInfo", {
                        ...employee.basicInfo,
                        empBloodGroup: e.target.value,
                      })
                    }
                    // defaultValue={employee.basicInfo&&employee.basicInfo.empBloodGroup?employee.basicInfo.empBloodGroup:""}
                  >
                    <MenuItem
                      value={"a+"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      A+
                    </MenuItem>
                    <MenuItem
                      value={"a-"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      A-
                    </MenuItem>
                    <MenuItem
                      value={"b+"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      B+
                    </MenuItem>
                    <MenuItem
                      value={"b-"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      B-
                    </MenuItem>
                    <MenuItem
                      value={"o+"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      O+
                    </MenuItem>
                    <MenuItem
                      value={"o-"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      O-
                    </MenuItem>
                    <MenuItem
                      value={"ab+"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      AB+
                    </MenuItem>
                    <MenuItem
                      value={"ab-"}
                      sx={{ fontSize: 12, fontWeight: 500 }}
                    >
                      AB-
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="outlined"
                  size="small"
                  label="Religion"
                  name="Religion"
                  style={styles.textfield}
                  value={employee.basicInfo.Religion || ""}
                  onChange={(e) =>
                    handleOnChange("basicInfo", {
                      ...employee.basicInfo,
                      Religion: e.target.value,
                    })
                  }
                  // defaultValue={employee.basicInfo&&employee.basicInfo.Religion?employee.basicInfo.Religion:""}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth"
                    inputFormat="DD-MM-YYYY"
                    value={employee.basicInfo.dob || null}
                    onChange={handleDateChange}
                    required
                    renderInput={(params) => (
                      <TextField
                        style={styles.textfield}
                        variant="outlined"
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <TextareaAutosize
                  placeholder="Present Address"
                  name="presentAddress"
                  style={{
                    width: "100%",
                    maxHeight: 80,
                    margin: 5,
                    padding: 10,
                    height: 80,
                    overflow: "auto",
                  }}
                  value={employee.basicInfo.presentAddress || ""}
                  onChange={(e) =>
                    handleOnChange("basicInfo", {
                      ...employee.basicInfo,
                      presentAddress: e.target.value,
                    })
                  }
                  // defaultValue={employee.basicInfo&&employee.basicInfo.presentAddress?employee.basicInfo.presentAddress:""}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextareaAutosize
                  placeholder="Permanent Address"
                  name="permanentAddress"
                  style={{
                    width: "100%",
                    maxHeight: 80,
                    margin: 5,
                    padding: 10,
                    height: 80,
                    overflow: "auto",
                  }}
                  value={employee.basicInfo.permanentAddress || ""}
                  onChange={(e) =>
                    handleOnChange("basicInfo", {
                      ...employee.basicInfo,
                      permanentAddress: e.target.value,
                    })
                  }
                  // defaultValue={employee.basicInfo&&employee.basicInfo.permanentAddress?employee.basicInfo.permanentAddress:""}
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <h5 style={{ color: "#1b3779" }}>Academic Information:</h5>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="outlined"
                  size="small"
                  label="Email"
                  name="email"
                  required
                  style={styles.textfield}
                  value={employee.academicInfo.email || ""}
                  onChange={(e) =>
                    handleOnChange("academicInfo", {
                      ...employee.academicInfo,
                      email: e.target.value,
                    })
                  }
                  // defaultValue={employee.academicInfo&&employee.academicInfo.email?employee.academicInfo.email:""}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="filled-"
                  variant="outlined"
                  size="small"
                  label="User Name"
                  name="username"
                  style={styles.textfield}
                  value={employee.username || ""}
                  onChange={handleChange}
                  // defaultValue={employee&&employee.username?employee.username:""}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Joining"
                    inputFormat="DD-MM-YYYY"
                    value={employee.academicInfo.joiningDate || null}
                    // defaultValue={employee.academicInfo&&employee.academicInfo.joiningDate?employee.academicInfo.joiningDate:""}

                    onChange={handleJoinDateChange}
                    required
                    renderInput={(params) => (
                      <TextField
                        style={styles.textfield}
                        variant="outlined"
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6} lg={3} justifyContent="space-between">
                {/* <p style={{ color: "#1b3779", marginLeft: "20px" }}>
                  Upload Resume:
                </p> */}
                <LoadingButton
                  variant="contained"
                  component="label"
                  loading={loadingResume}
                  sx={styles.Button}
                >
                  Upload Resume
                  <input
                    hidden
                    name="resume"
                    label="resume"
                    onChange={(event) => handleResume(event)}
                    accept=".pdf, .doc, .docx, .txt"
                    type="file"
                  />
                </LoadingButton>
                {resume && (
                  <span style={{ marginLeft: "10px", fontSize: "12px" }}>
                    {resume}
                  </span>
                )}
                {/* {employee.academicInfo.resume && (
                  <IconButton color="primary" sx={{ ml: "20px" }}>
                    <FileDownloadIcon
                      onClick={() =>
                        handleLinkClick(
                          employee.academicInfo && employee.academicInfo.resume
                            ? employee.academicInfo.resume.link
                            : ""
                        )
                      }
                    />
                  </IconButton>
                )} */}
              </Grid>
            </Grid>
          </Box>
        </Card>

        <Card style={styles.card}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <h5 style={{ color: "#1b3779" }}>Other Information:</h5>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="facebookUrl"
                  id="filled-required"
                  variant="outlined"
                  size="small"
                  label="Facebook URL"
                  sx={styles.textfield}
                  onChange={handleOtherInfoChange}
                  value={
                    employee.otherInfo && employee.otherInfo.facebookUrl
                      ? employee.otherInfo.facebookUrl
                      : ""
                  }
                  // defaultValue={employee.otherInfo&&employee.otherInfo.facebookUrl?employee.otherInfo.facebookUrl:""}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="twitterUrl"
                  id="filled-required"
                  variant="outlined"
                  size="small"
                  label="Twitter URL"
                  sx={styles.textfield}
                  onChange={handleOtherInfoChange}
                  // value={employee.otherInfo.twitterUrl || ""}
                  value={
                    employee.otherInfo && employee.otherInfo.twitterUrl
                      ? employee.otherInfo.twitterUrl
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="linkedinUrl"
                  id="filled-required"
                  variant="outlined"
                  size="small"
                  label="Linkedin URL"
                  sx={styles.textfield}
                  onChange={handleOtherInfoChange}
                  // value={employee.otherInfo.linkedinUrl || ""}
                  value={
                    employee.otherInfo && employee.otherInfo.linkedinUrl
                      ? employee.otherInfo.linkedinUrl
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="googlePlusUrl"
                  id="filled-required"
                  variant="outlined"
                  size="small"
                  label="Google Plus URL"
                  sx={styles.textfield}
                  onChange={handleOtherInfoChange}
                  // value={employee.otherInfo.googlePlusUrl || ""}
                  value={
                    employee.otherInfo && employee.otherInfo.googlePlusUrl
                      ? employee.otherInfo.googlePlusUrl
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="youtubeUrl"
                  id="filled-required"
                  variant="outlined"
                  size="small"
                  label="Youtube URL"
                  sx={styles.textfield}
                  onChange={handleOtherInfoChange}
                  // value={employee.otherInfo.youtubeUrl || ""}
                  value={
                    employee.otherInfo && employee.otherInfo.youtubeUrl
                      ? employee.otherInfo.youtubeUrl
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="filled-required"
                  variant="outlined"
                  size="small"
                  label="Instagram URL"
                  name="instagramUrl"
                  sx={styles.textfield}
                  onChange={handleOtherInfoChange}
                  // value={employee.otherInfo.instagramUrl || ""}
                  value={
                    employee.otherInfo && employee.otherInfo.instagramUrl
                      ? employee.otherInfo.instagramUrl
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="pinterestUrl"
                  id="filled-required"
                  variant="outlined"
                  size="small"
                  label="Pinterest URL "
                  sx={styles.textfield}
                  onChange={handleOtherInfoChange}
                  // value={employee.otherInfo.pinterestUrl || ""}
                  value={
                    employee.otherInfo && employee.otherInfo.pinterestUrl
                      ? employee.otherInfo.pinterestUrl
                      : ""
                  }
                />
              </Grid>

              <Grid item xs={12} md={12} lg={3}>
                {/* <p style={{ color: "#1b3779", marginLeft: "20px" }}>
                  Upload photo:
                </p> */}
                <LoadingButton
                  variant="contained"
                  component="label"
                  loading={loadingImg}
                  sx={styles.Button}
                >
                  Upload photo
                  <input
                    hidden
                    name="empPhoto"
                    onChange={(event) => handleEmpPhoto(event)}
                    accept="image/*"
                    type="file"
                  />
                </LoadingButton>
                {/* {empPhoto && (
                  <img
                    src={empPhoto}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                      marginLeft: " 40px",
                    }}
                    alt="Preview"
                  />
                )}
                {employee.empPhoto && (
                  <img
                    src={employee.empPhoto.link}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                      marginLeft: " 40px",
                    }}
                    alt="Preview"
                  />
                )} */}

                {empPhoto ? (
                  <img
                    src={empPhoto}
                    style={{
                      borderRadius: "5px",
                      height: "80px",
                      width: "150px",
                      marginLeft: "20px",
                      marginTop: "10px",
                    }}
                    alt="Preview"
                  />
                ) : employee.empPhoto ? (
                  <img
                    src={employee.empPhoto.link}
                    style={{
                      borderRadius: "5px",
                      width: "150px",
                      height: "80px",
                      marginLeft: "20px",
                      marginTop: "10px",
                    }}
                    alt="Preview"
                  />
                ) : null}
              </Grid>
            </Grid>
          </Box>
        </Card>

        <Box style={{ textAlign: "end", margin: "15px" }}>
          <Tooltip title="Cancel">
            <Button
              variant="contained"
              size="medium"
              type="button"
              color="error"
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
          </Tooltip>
          <Tooltip title="Submit">
            <Button
              variant="contained"
              size="medium"
              type="submit"
              sx={{
                marginRight: "1px",
                background: "rgb(27, 55, 121)",
                ":hover": { background: "rgb(27, 55, 121)" },
              }}
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Tooltip>
        </Box>
      </TabPanel>
    </div>
  );
};
export default NavbarProfile;
