import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Slider from "react-slick";
import {
  Container,
  Box,
  Typography,
  styled,
  Modal,
  Backdrop,
  Fade,
  CardMedia,
  IconButton,
} from "@mui/material";
// custome components
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import { get } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import {
  calculateSlidersData,
  imageCarousalSettings,
  settings,
} from "../../Data/carousal";
import ImageCarousal from "../ImageCarousel";
// icons
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Close } from "@mui/icons-material";

const TextBox1 = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
}));

const Main = styled(Box)(({ theme }) => ({
  marginTop: "4rem",
  marginBottom: "5rem",
}));

const ImagBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  height: "auto",
  background: "#fff",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
}));

const GalleryMain = () => {
  const { selectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [galleryData, setGalleryData] = useState([]);
  const [modalOpen, setModalOpen] = React.useState({
    open: false,
    img: [],
    singleImg: {},
  });

  const selectedIndex = modalOpen.img.findIndex(
    (image) => image._id === modalOpen.singleImg._id
  );

  const reorderedImages = [
    modalOpen.singleImg,
    ...modalOpen.img.slice(0, selectedIndex),
    ...modalOpen.img.slice(selectedIndex + 1),
  ];

  let sliderRef = useRef(null);

  let handlePrevious = () => {
    sliderRef.current.slickPrev();
  };
  let handleNext = () => {
    sliderRef.current.slickNext();
  };

  // gallery list
  const getGalleryData = async () => {
    try {
      const { data } = await get(urls.gallery.getAllGalleries);
      setGalleryData(data.data);
      // console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGalleryData();
  }, []);

  return (
    <>
      <Main>
        <TextBox1>
          <Typography
            variant="h3"
            color="black"
            sx={{ fontWeight: "bold", fontSize: "40px" }}
          >
            OUR
          </Typography>
          &nbsp;&nbsp;
          <Typography
            variant="h3"
            color="#f86f03"
            sx={{ fontWeight: "bold", fontSize: "40px" }}
          >
            GALLERY
          </Typography>
        </TextBox1>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "50px",
          }}
        >
          <Typography component="p">________</Typography>
          <FiberManualRecordIcon sx={{ fontSize: "8px", marginTop: "15px" }} />
          <FiberManualRecordIcon
            sx={{
              color: "#F86F03",
              fontSize: "10px",
              marginTop: "14px",
              marginLeft: "5px",
            }}
          />
          <FiberManualRecordIcon
            sx={{ fontSize: "8px", marginTop: "15px", marginLeft: "6px" }}
          />
          <Typography component="p">________</Typography>
        </Box>

        <Container style={{ padding: "10px" }}>
          <ImageCarousal
            ref={sliderRef}
            galleryData={galleryData}
            setModalOpen={setModalOpen}
          />
        </Container>

        {/*========== view gallery img modal =====*/}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={modalOpen.open}
          onClose={() => setModalOpen({ open: false, img: [], singleImg: {} })}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <ImagBox>
            <IconButton
              aria-label="close"
              onClick={() =>
                setModalOpen({ open: false, img: [], singleImg: {} })
              }
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                zIndex: 9,
              }}
            >
              <Close />
            </IconButton>
            <Slider {...settings}>
              {reorderedImages.map((image, index) => (
                <CardMedia
                  key={index}
                  component="img"
                  image={image?.link}
                  alt="loading..."
                  sx={{
                    borderRadius: "5px",
                    objectFit: "contain",
                    p: "15px",
                    height: "600px",
                  }}
                />
              ))}
            </Slider>
          </ImagBox>
        </Modal>
        {/* <AppSlider
        ref={sliderRef}
        {...calculateSlidersData(galleryData.length)}
      >
        {galleryData.map((d, i) => (
          <Card key={d._id} sx={{ maxWidth: 345 }}>
            <CardActionArea>
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  backgroundColor: "red",
                  width: "100%",
                  maxWidth: "350px",
                  minWidth: "250px",
                  height: "300px",

                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
              <ImageCarousal
                style={{ width: "100%", height: "300px" }}
                images={d.images.map((i) => i.link)}
              />
              </Box>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {d.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {d.note || "Added new images"}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
         
        ))}
      </AppSlider> */}
        {/* </Container> */}
      </Main>
    </>
  );
};

export default GalleryMain;
