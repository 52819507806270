import * as React from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Backdrop, Box, Button, CardMedia, Grid, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Carousel from "react-slick";
// import carddata from "./cardata";

import styles from "../../NavbarComponents/styles/cardevent.module.css";
import { useState, useEffect } from "react";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import { urls as url } from "../../services/urlConstant";
import { get } from "../../services/apis";
import GallerySubHome from "../../components/GallerySubHome";

import { Close } from "@mui/icons-material";

const TypographyMain = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginTop: "25px",
  fontSize: "40px",
  color: "#f86f03",
  fontWeight: "bold",
  textShadow: "10px 8px 8px #969c96",
  [theme.breakpoints.down("md")]: {
    // textAlign:"center",
    // margin:0,
    // padding:"25px"
  },
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    margin: 0,
    padding: "0",
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  height: "auto",
  background: "#fff",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// const GridBox = styled(Box)(({ theme }) => ({
//   display: "grid",
//   gridTemplateColumns: "300px 300px 300px 300px",
//   justifyContent: "space-evenly",
//   gap: "15px",
//   [theme.breakpoints.down(1000)]: {
//     gridTemplateColumns: "300px 300px",
//   },
//   [theme.breakpoints.down(660)]: {
//     gridTemplateColumns: "90%",
//   },
// }));

const TextBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  // marginTop: "20px",
}));

const Heading = styled(Typography)(({ theme }) => ({
  marginTop: "4rem",
  color: "#FF6D34",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
}));

const SecondHeading = styled(Typography)(({ theme }) => ({
  color: "#f86f03",
  textShadow: "3px 3px black",
  fontWeight: "bold",
  fontSize: "2.5rem",
  fontFamily: "sans-serif",
  marginTop: "0.8rem",
}));

export default function OurGallery() {
  const [ModalData, setModalData] = useState(false);
  let sliderRef = React.useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalOpen, setModalOpen] = React.useState({
    open: false,
    imageData: [],
    viewSingleImg: {},
  });

  const SelectedImageIndex = modalOpen.imageData.findIndex(
    (img) => img._id === modalOpen.viewSingleImg._id
  );

  const cutailImages = [
    modalOpen.viewSingleImg,
    ...modalOpen.imageData.slice(0, SelectedImageIndex),
    ...modalOpen.imageData.slice(SelectedImageIndex + 1),
  ];

  let [data, setData] = useState();

  let handleData = (data) => {
    console.log(data);
    setData(data);
  };

  // let navigate = useNavigate();

  const { selectedSetting } = React.useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [galleryData, setGalleryData] = useState([]);

  const getGalleryData = async () => {
    try {
      const { data } = await get(urls.gallery.getAllGalleries);
      setGalleryData(data.data);
      console.log(data.data, "gallery");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getGalleryData();
  }, []);

  return (
    <>
      <TextBox>
        <Heading>DISCOVER NEW</Heading>
        <TypographyMain>OUR GALLERY</TypographyMain>
      </TextBox>

      {/* <GridBox mt={5}>
        {galleryData.slice(0, 4).map((item, i) => {
          return (
            <React.Fragment key={i}>
              <GallerySubHome data={item} setModalOpen={setModalOpen} />
            </React.Fragment>
          );
        })}
      </GridBox> */}
      <Grid
        container
        spacing={2}
        mt={2}
        sx={{
          padding: { xs: 0, sm: "0 20px", md: "0 60px" },
        }}
      >
        {galleryData.slice(0, 4).map((item, i) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={i}>
            <GallerySubHome data={item} setModalOpen={setModalOpen} />
          </Grid>
        ))}
      </Grid>

      {/* <GridBox>
        {galleryData.map((items, i) => {
          return (
            <React.Fragment key={i}>
              <Gallery data={items} onClick={items.onClick} />
            </React.Fragment>
          );
        })}
      </GridBox> */}
      {/* <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          size="large"
          variant="contained"
          onClick={() => navigate("/home-gallery")}
          sx={{
            bgcolor: "#1779f7",
            color: "white",
            border: "solid #1779f7",
            "&:hover": {
              bgcolor: "transparent",
              border: "solid #ff4500",
              color: "black",
            },
          }}>
          View Gallery
        </Button>
      </Box> */}

      {/* ======== View Gallery img ======= */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen.open}
        onClose={() =>
          setModalOpen({ open: false, imageData: [], viewSingleImg: {} })
        }
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <ModalBox>
          <IconButton
            aria-label="close"
            onClick={() =>
              setModalOpen({ open: false, imageData: [], viewSingleImg: {} })
            }
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              zIndex: 9,
            }}
          >
            <Close />
          </IconButton>
          <Carousel>
            {cutailImages?.map((image, index) => (
              <CardMedia
                key={index}
                component="img"
                image={image?.link}
                alt="loading..."
                height="560"
                sx={{
                  borderRadius: "5px",
                  objectFit: "contain",
                  p: "15px",
                }}
              />
            ))}
          </Carousel>
        </ModalBox>
      </Modal>
    </>
  );
}
