import React from "react";

import { Box, Grid, Stack, Typography, styled } from "@mui/material";
// import css from "../styles/MuiCard.module.css"
// import CardItems from './TeacherCardItems'
import TeacherCardItems from "./TeacherCardItems";
import { css } from "@emotion/react";
import MovingText from "react-moving-text";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Data = [
  {
    id: "1",
    name: "JOHN DUE",
    photo: new URL(
      "https://shtheme.com/demosd/eduvisionwp/wp-content/uploads/2017/08/team-img1.jpg"
    ),
    designation: "Finance Teacher",
    description:
      "Lorem ipsum dolor sit amet,consec tetuer adipiscing elit sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Uwisi enim adi min veniam.",
  },
  {
    id: "2",
    name: "ADAM SMITH",
    photo: new URL(
      "https://shtheme.com/demosd/eduvisionwp/wp-content/uploads/2017/08/team-img1.jpg"
    ),
    designation: "Language Teacher",
    description:
      "Lorem ipsum dolor sit amet,consec tetuer adipiscing elit sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Uwisi enim adi min veniam.",
  },
  {
    id: "3",
    name: "ARENA SILVA",
    photo: new URL(
      "https://shtheme.com/demosd/eduvisionwp/wp-content/uploads/2017/08/team-img1.jpg"
    ),
    designation: "Computer Teacher",
    description:
      "Lorem ipsum dolor sit amet,consec tetuer adipiscing elit sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Uwisi enim adi min veniam.",
  },
  {
    id: "4",
    name: "RICKY POINTING",
    photo: new URL(
      "https://shtheme.com/demosd/eduvisionwp/wp-content/uploads/2017/08/team-img1.jpg"
    ),
    designation: "Math Teacher",
    description:
      "Lorem ipsum dolor sit amet,consec tetuer adipiscing elit sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Uwisi enim adi min veniam.",
  },
];

const CourseDetailsStack = styled(Stack)(
  ({}) => css`
    display: flex;
    padding-left: 3%;
    padding-top: 5%;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    height: auto;
  `
);

const TextBox1 = styled(Box)(({ theme }) => ({
  // marginTop: "5%",
  // marginLeft:"5%",
  textShadow: "10px 8px 8px #969c96",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
//   const TextBox2=styled(Box)(({theme})=>({

//     [theme.breakpoints.down("md")]:{
//         display:"flex",
//         alignItems:"center",
//         justifyContent:"center",
//         marginTop:"5%"
//     }
// }))

const TeachersCard = () => {
  return (
    <>
      <CourseDetailsStack spacing={2} >
        {/* <Box  sx={{display:"flex"}}>
      <Typography variant="h4" color='#243b62'>OUR</Typography>&nbsp;
          <Typography  color="#f86f03" variant="h4">TEACHERS</Typography>
      </Box> */}

        <TextBox1>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  variant="h3"
                  color="black"
                  sx={{ fontWeight: "bold", fontSize: "40px" }}
                >
                  OUR
                </Typography>
              </Box>
              &nbsp;&nbsp;
              <Box>
                <Typography
                  variant="h3"
                  color="#f86f03"
                  sx={{ fontWeight: "bold", fontSize: "40px" }}
                >
                  TEACHERS
                </Typography>
              </Box>
            </Box>
        </TextBox1>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {/* <Typography color='#a57a7a'  variant="caption" sx={{marginLeft:"0.5%",fontSize:"16px",fontWeight:"bold"}}>Lorem ipsum dolor sit amet  consectetuer adipiscingi eliyt sed diam.</Typography> */}
          <img
            src="divider.png"
            alt=""
            width="190px"
            style={{ marginLeft: "-0.5%" }}
          />
        </Box>
      </CourseDetailsStack>

          <Box sx={{display : "flex", justifyContent:"center"}}>
          <Typography component="p">________</Typography>
          <FiberManualRecordIcon sx={{ fontSize: "8px", marginTop: "15px" }} />
          <FiberManualRecordIcon
            sx={{
              color: "#F86F03",
              fontSize: "10px",
              marginTop: "14px",
              marginLeft: "5px",
            }}
          />
          <FiberManualRecordIcon
            sx={{ fontSize: "8px", marginTop: "15px", marginLeft: "6px" }}
          />
          <Typography component="p">________</Typography>
        </Box>

      <Box sx={{ margin: "2%" }}>
        <Grid container>
          {Data.map((data) => (
            <React.Fragment key={data.id}>
              <Grid item xs={12} sm={6}>
                <TeacherCardItems items={data} />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default TeachersCard;
