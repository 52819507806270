import React from "react";

export default function ShowComponent({
  roleFunction,
  action,
  CustomComponent,
  children,
}) {
  let role = window.localStorage.getItem(process.env.REACT_APP_ROLE);

  if (!role) return null;

  if (role !== "undefined") {
    let extractedRole = JSON.parse(role);

    if (!extractedRole[roleFunction]) return CustomComponent;

    let canShowComponent =
      extractedRole[roleFunction][action] === true ? true : false;

    if (!canShowComponent && !CustomComponent) return null;

    if (!canShowComponent && CustomComponent) return CustomComponent;
  }

  return <>{children}</>;
}
