import { Box, Container, Typography, styled } from "@mui/material";
import React from "react";
// import image from '../../assets/Images/bg-image.png'
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import NavBar from "../navbar/Navbar";
import TopNav from "../navbar/TopNav";

const ContentBox = styled(Box)(({ theme }) => ({
  // padding: "30px",

  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 15px",
    // overflow:"auto",
  },
}));

const OverViewTitleContainer = styled(Box)(({ theme }) => ({
  color: "white",
  padding: "80px",
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url(/HeaderImage.png)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
  },
}));

const OverViewTitle = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));

const OverView = () => {
  return (
    <>
      <TopNav />
      <NavBar />

      <ContentBox sx={{ height: "max-content" }}>
        <OverViewTitleContainer variant="h4" align="center">
          <OverViewTitle>OVERVIEW</OverViewTitle>
          <Typography sx={{ color: "white" }}>
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              HOME
            </Link>
            &nbsp; / ABOUT
          </Typography>
        </OverViewTitleContainer>
        <Container>
          <Box sx={{ textAlign: "justify" }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                color: "#F86F03",
                textIndent: "4rem",
                marginTop: "2rem",
              }}
            >
              At Raja Somashekara Nayaka English Medium Higher Primary School ,
              we take pride in our rich history and the visionary leader who
              laid the foundation for our institution. Our school founder, Sri.
              Sri. Raja Somashekara Nayaka, was a remarkable individual who
              believed in the transformative power of education and dedicated
              their life to shaping young minds.
            </Typography>

            <Typography sx={{ marginTop: "2rem" }}>
              Driven by the belief that education should be holistic, inclusive,
              and student-centered, Sri. Raja Somashekara Nayaka. Patil worked
              tirelessly to create an environment that fostered intellectual
              curiosity, critical thinking, and personal growth. They believed
              in the power of hands-on learning, interdisciplinary approaches,
              and embracing diverse perspectives to prepare students for the
              challenges of the rapidly evolving world.
            </Typography>

            <Typography sx={{ marginTop: "2rem" }}>
              Under Sri. Sri. Raja Somashekara Nayaka's leadership, Raja
              Somashekara Nayaka English Medium Higher Primary School
              flourished. Their innovative educational philosophy attracted a
              dedicated team of educators who shared their vision and a
              community of parents who believed in the transformative potential
              of Raja Somashekara Nayaka English Medium Higher Primary School .
              Together, they nurtured a vibrant educational community that
              became known for its academic excellence, creative expression, and
              commitment to social responsibility.
            </Typography>

            <Typography sx={{ marginTop: "2rem" }}>
              Join us at Raja Somashekara Nayaka English Medium Higher Primary
              School and become part of a community that cherishes its past,
              embraces the present, and embraces the future. Experience the
              transformative power of education that was envisioned by our
              remarkable founder, Sri. Raja Somashekara Nayaka.
            </Typography>

            <Typography
              sx={{
                marginTop: "3rem",
                fontWeight: "bold",
                color: "#F86F03",
                textAlign: "center",
              }}
            >
              Come and be a part of the legacy at Raja Somashekara Nayaka
              English Medium Higher Primary School!
            </Typography>
          </Box>
        </Container>
      </ContentBox>
      <Footer />
    </>
  );
};

export default OverView;
