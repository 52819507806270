import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import ShowComponent from "./components/ShowComponent";
import PageNotFound from "./pages/PageNotFound";
import PermissionDenied from "./components/PermissionDenied";

const PrivateRoute = ({
  children,
  roleFunction = "dashboard",
  action = "view",
}) => {
  const isAuth = window.localStorage.getItem(process.env.REACT_APP_TOKEN);

  const navUser = () => {
    toast.warning("Please login");
    return <Navigate to="/login" />;
  };
  return isAuth ? (
    <ShowComponent
      roleFunction={roleFunction}
      action={action}
      CustomComponent={<PermissionDenied />}>
      {children}
    </ShowComponent>
  ) : (
    navUser()
  );
};
export default PrivateRoute;
