import React from "react";
import { Box, Container, Grid, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import EastIcon from "@mui/icons-material/East";
import "../../index.css";

const MainGrid = styled(Grid)(({}) => ({
  display: "flex",
  cursor: "pointer",
  "&:hover .image": {
    border: "2px solid #f86f03",
    // backgroundColor: "#f86f03",
  },
  //   "&:hover .navigate": {
  //     fontWeight: 600,
  //     color: "orange",
  //   },
}));

const MuiSubtitle = styled(Typography)(({}) => ({
  display: "inline-flex",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 3,
}));

const TypographyTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "max-content",
  fontSize: "25px",
  "&:hover": { color: "#f86f03" },
  [`&::after`]: {
    content: "''",
    width: "0%",
    height: "2px",
    backgroundColor: "#f86f03",
    display: "block",
    transition: "0.5s",
    fontWeight: 600,
    fontSize: "1rem",
    color: "red",
  },
  [`&:hover::after`]: {
    width: "100%",
  },
  [theme.breakpoints.down(500)]: {
    fontSize: "15px",
  },
}));

export default function NewsNoticeComponents({ elem }) {
  let navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/details/${elem._id}`, { state: elem });
  };
  console.log(elem, "elem");
  return (
    <Container>
      <MainGrid container sx={{ display: "flex" }}>
        <Grid
          item
          md={2}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            className="image"
            src={elem?.image?.link}
            style={{
              height: "160px",
              width: "200px",
              //   borderRadius: "50%",
              border: "1px solid orange",
            }}
            alt="image"
          />
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          sx={{
            padding: "10px 30px",
            border: "1px solid #99999933",
            background: "#fff",
          }}
        >
          <Box>
            <Typography
              variant="body2"
              fontWeight={600}
              sx={{ color: "#068FFF" }}
            >
              {dayjs(elem.date).format("DD, MMM, YYYY")}
            </Typography>
            <TypographyTitle className="title">{elem.title}</TypographyTitle>
            <MuiSubtitle paragraph fontSize={14} variant="subtitle1">
              {elem.news}Promoting national integrity and feeling of oneness
              among students. Empowering our kids to be compassionate and
              responsive to our culture and heritage. promoting scientific
              attitude and temper. We take pride in educating thousands of
              bright kids in last several years and being part of their success
              stories.We are glad to be part of the nation building exercise in
              a noble and humble way. kids in last several years and being part
              of their success stories.We are glad to be part of the nation
              building exercise in a noble and humble way. kids in last several
              years and being part of their success stories.We are glad to be
              part of the nation building exercise in a noble and humble way.
            </MuiSubtitle>

            <Typography
              className="navigate"
              sx={{
                cursor: "pointer",
                color: "#f86f03",
              }}
              fontSize={14}
              onClick={handleNavigate}
            >
              Read More{" "}
              <EastIcon
                sx={{
                  ml: "10px",
                  transform: "translateY(-50%)",
                  animation: "uparrow 0.9s infinite alternate ease-in-out",
                }}
              />
            </Typography>
          </Box>
        </Grid>
      </MainGrid>
    </Container>
  );
}
