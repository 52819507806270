export default function appendStringToUrls(obj, appendString) {
  const updatedObj = {};
  for (const key in obj) {
    if (typeof obj[key] === "string") {
      updatedObj[key] = appendString + obj[key];
    } else if (typeof obj[key] === "object") {
      updatedObj[key] = appendString ? appendString + obj[key] : obj[key];
      updatedObj[key] = appendStringToUrls(obj[key], appendString);
    }
  }
  return updatedObj;
}
