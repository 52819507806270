import React, { useState, useEffect } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from './Addmission.module.css'
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";
import { get } from "../services/apis";
import { urls } from "../services/urlConstant";

const HomeNoticeView = () => {
    const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);
  const [news,setNews] = useState(null);

  const {noticeId} = useParams();


  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };

  useEffect(() => {
    const getNotice = async () => {
        try {
            const news = await get(`${urls.announceNotice.getNotice}/${urls.home.getHome}/${noticeId}`);
            setNews(news.data.data);
        } catch (error) {
            
        }
    }
    getNotice();
  },[]);

  return (
    <>
        <TopNav />
        <Navbar />
      <div className={css.headerContainer}>
                <div>
                    <h1>{news && news.title}</h1>
                    <h5><Link style={{
                        color:'white'
                    }} to='/'>Home</Link> / <Link style={{color:'inherit',textDecoration:'none'}} to={'/home-notice'}>NOTICE</Link></h5>
                </div>
      </div>

      <Grid container spacing={12}marginLeft={"-76px"}>
        <Grid items xs={12} mg={12} lg={8} style={ {"margin":"30px 0px 20px 0px","border-right":"1px solid #BDBDBD"}}>
       
        <p  style={{margin:"20px 10px -5px 40px",fontSize:"1.11rem"}}> {news && news.notice}</p> 
       
        </Grid>
        <Grid items xs={12} mg={6} lg={4} style={{height:"80vh",paddingTop:"40px"}}>
          <Demo/>
        </Grid>
      </Grid>
      {/* <div className={css.contentContainer}>
        <div className={css.content}>
            <div className={css.contentHolder}>
                {news && news.notice}
            </div>
        </div>
        <div className={css.newsNoticeContainer}>
            <div className={css.newsNotice}>
                <Demo />
            </div>
        </div>
      </div> */}

      <Footer />
    </>
  );
}

export default HomeNoticeView;