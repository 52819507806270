import React from "react";
import GiteIcon from "@mui/icons-material/Gite";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ManIcon from "@mui/icons-material/Man";
import InventoryIcon from "@mui/icons-material/Inventory";
import FeedbackIcon from "@mui/icons-material/Feedback";
import PhotoRoundedIcon from "@mui/icons-material/PhotoRounded";
import WcIcon from "@mui/icons-material/Wc";

import {
  SettingsSuggest,
  Boy,
  AccountBalance,
  AccessTime,
  Groups,
  CheckBox,
  LocalAtmSharp,
  TextSnippet,
  SchoolRounded,
  CardMembershipRounded,
  InstallMobileRounded,
  CampaignRounded,
  CalendarMonth,
  Person,
  FiberManualRecord,
  EnhancedEncryption,
  Preview,
  CurrencyRupee,
  Receipt,
  FastForward,
  PointOfSale,
  BarChart,
  FiberManualRecordOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/material";
import { FaChalkboardTeacher } from "react-icons/fa";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import Diversity2Icon from "@mui/icons-material/Diversity2";

const Teacher = styled(FaChalkboardTeacher)(() => ({
  color: "#1de9b6",
  fontSize: "22px",
}));
const menuItem = [
  //setting
  {
    path: "/setting",
    name: "Setting",
    renderName: ["setting"],
    icon: <SettingsSuggest sx={{ color: "#47a093" }} />,
    subMenu: [
      {
        path: "/manageSetting",
        name: "Manage Institute",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "manageSetting",
      },
    ],
  },
  // administrator
  {
    path: "/dashboard/administrator",
    name: "Administrator",
    renderName: ["academicYear", "role", "rolePermission", "resetUserPassword"], //TODO: did not find a paramter on the response
    icon: <Person sx={{ color: "#9c27b0" }} />,
    subMenu: [
      {
        path: "/dashboard/academicyear",
        name: "Academic Year",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "academicYear",
      },
      {
        path: "/role",
        name: "User Role (ACL)",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "role",
      },
      {
        path: "/role-permission",
        name: "Role Permission (ACL)",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "rolePermission",
      },
      {
        path: "/reset-password-user",
        name: "User Password Reset",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "resetUserPassword",
      },
    ],
  },
  // hr
  {
    path: "/dashboard/hr",
    name: "Human Resource",
    renderName: [
      "designation",
      "department",
      "employee",
      "offerletter",
      "relievingletter",
      "offboarding",
      "experienceletter",
      "teacherActivity",
    ], //TODO: did not find a paramter on the response
    icon: <Diversity2Icon sx={{ color: "#c6ff00" }} />,
    subMenu: [
      {
        path: "/dashboard/designation",
        name: "Manage Designation",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "designation",
      },
      {
        path: "/dashboard/department",
        name: "Manage Department",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "department",
      },
      {
        path: "/dashboard/employee",
        name: "Employee",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "employee",
      },
      {
        path: "/dashboard/offer-letter",
        name: "Offer Letter",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "offerletter",
      },
      {
        path: "/dashboard/relieving-letter",
        name: "Relieving Letter",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "relievingletter",
      },
      {
        path: "/dashboard/off-boarding",
        name: "Off-Boarding",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "offboarding",
      },
      {
        path: "/dashboard/experience",
        name: "Experience Letter",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "experienceletter",
      },
      {
        path: "/dashboard/teacher",
        name: "Teacher Activity",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "teacherActivity",
      },
    ],
  },
  //  Pre - Addmission
  {
    name: "Pre Admission",
    renderName: [
      "preaddmissionEnquiry",
      "preaddmissionExam",
      "preaddmissionExamSchedule",
      "result",
    ],
    icon: <ManIcon sx={{ color: "#004d40" }} />,
    subMenu: [
      {
        path: "/dashboard/enquiries",
        name: "Enquiries",
        renderName: "preaddmissionEnquiry",
      },
      {
        path: "/dashboard/preexams",
        name: "Exams",
        renderName: "preaddmissionExam",
      },
      {
        path: "/dashboard/preExamSchedules",
        name: "Exam Schedules",
        renderName: "preaddmissionExamSchedule",
      },
      {
        path: "/dashboard/result",
        name: "Result",
        renderName: "result",
      },
    ],
  },
  //academic
  {
    path: "/dashboard/academic",
    name: "Academic",
    renderName: ["class", "section", "subject"],
    icon: <AccountBalance sx={{ color: "#283618" }} />,
    subMenu: [
      {
        path: "/dashboard/class",
        name: "Class",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "class",
      },
      {
        path: "/dashboard/section",
        name: "Section",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "section",
      },
      {
        path: "/dashboard/subject",
        name: "Subject",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "subject",
      },
    ],
  },

  // attendance
  {
    path: "/dashboard/attendance",
    name: "Attendance",
    renderName: ["studentAttendance", "employeeAttendanceTest"],
    icon: <CheckBox sx={{ color: "#5e548e" }} />,
    subMenu: [
      {
        path: "/student-attendance",
        name: "Student Attendance",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "studentAttendance",
      },

      {
        path: "employee-attendance",
        name: "Employee Attendance",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "employeeAttendanceTest",
      },
    ],
  },
  // routine
  {
    path: "/routine",
    name: "Class Routine",
    renderName: ["periodRoutine"],
    icon: <AccessTime sx={{ color: "#bc6c25" }} />,
    subMenu: [
      {
        path: "/routine",
        name: "Class Routine",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "periodRoutine",
      },
    ],
  },
  // student
  {
    path: "/dashboard/student",
    name: "Student",
    renderName: [
      "studentOverview",
      "admitStudent",
      "studentResuffle",
      "studentPromotion",
      "studentBulkAdmission",
      "studentCredential",
      "quickadmit",
      "studentactivity",
    ],
    icon: <Groups sx={{ color: "#ff006e" }} />,
    subMenu: [
      {
        path: "/student-overview",
        name: "Overview",
        icon: <Preview style={{ fontSize: "10px" }} />,
        renderName: "studentOverview",
      },
      {
        path: "/admitstudent",
        name: "Admit Student",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "admitStudent",
      },

      {
        path: "/reshuffle",
        name: "Reshuffle",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "studentResuffle",
      },
      {
        path: "/promotion",
        name: "Promotion",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "studentPromotion",
      },
      {
        path: "/bulkadmission",
        name: "Bulk Admission",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "studentBulkAdmission",
      },
      {
        path: "/student-credential",
        name: "Credential",
        icon: <EnhancedEncryption style={{ fontSize: "10px" }} />,
        renderName: "studentCredential",
      },
      {
        path: "/quickadmit",
        name: "Quick Admit",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "quickadmit",
      },
      {
        path: "/studentactivity",
        name: "Student Activity",
        icon: <EnhancedEncryption style={{ fontSize: "10px" }} />,
        renderName: "studentactivity",
      },
    ],
  },
  // assignment
  {
    path: "/assignment",
    name: "Assignment",
    renderName: ["assignment"],
    icon: <AssignmentRoundedIcon sx={{ color: "#ff5722" }} />,
    subMenu: [],
  },
  // Communication
  {
    path: "/dashboard/communication",
    name: "Communication",
    renderName: ["sendSms", "smsReport", "sendCredential"], //TODO: did not find a paramter on the response
    icon: <InstallMobileRounded sx={{ color: "#ff69eb" }} />,
    subMenu: [
      {
        path: "/compose",
        name: "Compose",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "sendSms",
      },
      {
        path: "/sms-report",
        name: "Report",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "smsReport",
      },
      {
        path: "/send-credentials",
        name: "Credentials",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "sendCredential",
      },
    ],
  },
  // Announcement
  {
    path: "/dashboard/announcement",
    name: "Announcement",
    icon: <CampaignRounded sx={{ color: "#390099" }} />,
    renderName: ["notice", "news", "holiday", "awards", "splashNews"],
    subMenu: [
      {
        path: "/notice",
        name: "Notice",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "notice",
      },
      {
        path: "/announcenews",
        name: "News",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "news",
      },

      {
        path: "/announceholiday",
        name: "Holiday",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "holiday",
      },
      {
        path: "/awards",
        name: "Awards and Achievements",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "awards",
      },
      {
        path: "/splashNews",
        name: "Splash News",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "splashNews",
      },
    ],
  },

  // Gallery
  {
    path: "/gallery",
    name: "Gallery",
    renderName: ["gallery"],
    icon: <PhotoRoundedIcon sx={{ color: "#1b5e20" }} />,
    subMenu: [],
  },
  // Notification
  {
    path: "/notifications",
    name: "Notifcations",
    renderName: ["smsNotification"],
    icon: <NotificationsActiveRoundedIcon sx={{ color: "#aa00ff" }} />,
    subMenu: [],
  },
  // Event
  {
    path: "/dashboard/events",
    name: "Event",
    renderName: ["event"],
    icon: <Groups sx={{ color: "#ff006e" }} />,
    subMenu: [],
  },
  // Exam
  {
    path: "/dashboard/exam",
    name: "Exam",
    icon: <TextSnippet sx={{ color: "#d62828" }} />,
    renderName: ["examGrade", "examSchedule", "examTerm", "hallTicket"], //TODO: did not find a paramter on the response
    subMenu: [
      {
        path: "/examgrade",
        name: "Exam Grade",
        renderName: "examGrade",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/examterm",
        name: "Exam Term",
        renderName: "examTerm",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/examschedule",
        name: "Exam Schedule",
        renderName: "examSchedule",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
      },
      {
        path: "/exam/hallticket",
        name: "Exam Hall Ticket",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "hallTicket",
      },
    ],
  },
  // Exam Mark
  {
    path: "/dashboard/exam/mark",
    name: "Exam Mark",
    renderName: [
      "examAttendance",
      "examResult",
      "studentMark",
      "marksCard",
      "consolildated",
      "subjectwisereport",
      "divissionwisereport",
    ], //TODO: did not find a paramter on the response
    icon: <SchoolRounded sx={{ color: "#212529" }} />,
    subMenu: [
      {
        path: "/examattendance",
        name: "Exam Attendance",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "examAttendance",
      },
      {
        path: "/managemark",
        name: "Manage Mark",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "studentMark",
      },
      {
        path: "/examresult",
        name: "Exam Result",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "examResult",
      },
      {
        path: "/marks-card",
        name: "Marks card",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "marksCard",
      },

      {
        path: "/consolidated",
        name: "Consolidated Marks Sheet",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "consolildated",
      },
      {
        path: "/subjectwisereport",
        name: "Subject Wise Report",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "subjectwisereport",
      },
      {
        path: "/divisionwisereport",
        name: "Division Wise Report",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "divissionwisereport",
      },
    ],
  },
  // LMS
  {
    name: "LMS",
    renderName: ["course", "courseContent", "fileStorage", "live"],
    icon: <LibraryBooksIcon sx={{ color: "#7b2cbf" }} />,
    subMenu: [
      {
        path: "/dashboard/storage",
        name: "Storage ",
        renderName: "fileStorage",
      },
      {
        path: "/dashboard/course",
        name: "Courses",
        renderName: "course",
      },
      {
        path: "/dashboard/course-content",
        name: "Course Content",
        renderName: "courseContent",
      },
      {
        path: "/dashboard/live",
        name: "Live ",
        renderName: "live",
      },
    ],
  },
  // Library
  {
    name: "Library",
    renderName: [
      "book",
      "periodical",
      "studentLibrary",
      "issuereturn",
      "employeeLibrary",
      "empIssue",
    ],
    icon: <MenuBookRoundedIcon sx={{ color: "#7b2cbf" }} />,
    subMenu: [
      {
        path: "/dashboard/librarybooks",
        name: "Books",
        renderName: "book",
      },
      {
        path: "/dashboard/libraryperiodical",
        name: "Periodical",
        renderName: "periodical",
      },
      {
        path: "/dashboard/studentlibrarymember",
        name: "Student Library Member",
        renderName: "studentLibrary",
      },

      {
        path: "/dashboard/studentissue",
        name: "Student Issue & Return",
        renderName: "issuereturn",
      },
      {
        path: "/dashboard/employeelibrary",
        name: "Employee Library Member",
        renderName: "employeeLibrary",
      },
      {
        path: "/dashboard/employeeissue",
        name: "Employee Issue & Return",
        renderName: "empIssue",
      },
    ],
  },
  // Leave Management
  {
    name: "Leave Management",
    renderName: ["leaveType", "employeeLeave", "studentLeave", "leaveReport"],
    icon: <LibraryBooksIcon sx={{ color: "#bb9457" }} />,
    subMenu: [
      {
        path: "/dashboard/leaveType",
        name: "Leave Type",
        renderName: "leaveType",
      },
      {
        path: "/dashboard/employeeLeave",
        name: "Employee Leave",
        renderName: "employeeLeave",
      },
      {
        path: "/dashboard/studentsLeave",
        name: "Students Leave",
        renderName: "studentLeave",
      },

      {
        path: "/dashboard/leaveReport",
        name: "Leave Report",
        renderName: "leaveReport",
      },
    ],
  },
  // Inventory
  {
    name: "Inventory",
    renderName: [
      "item",
      "vendor",
      "transaction",
      "itemInventory",
      "issueDetails",
      "sold",
    ],
    icon: <InventoryIcon sx={{ color: "#607d8b" }} />,
    subMenu: [
      {
        path: "/dashboard/item",
        name: "Item",
        renderName: "item",
      },

      {
        path: "/dashboard/vendor",
        name: "Vendor",
        renderName: "vendor",
      },
      {
        path: "/dashboard/transaction",
        name: "In/Out Transaction",
        renderName: "transaction",
      },

      {
        path: "/dashboard/itemInventory",
        name: "Stock List",
        renderName: "itemInventory",
      },
      {
        path: "/dashboard/issueDetails",
        name: "Issue",
        renderName: "issueDetails",
      },
      {
        path: "/dashboard/sold",
        name: "Sell",
        renderName: "sold",
      },
    ],
  },

  // Certification
  {
    path: "/dashboard/certificate",
    name: "Certificate",
    renderName: ["studyCertificate", "transferCertificate"],
    icon: <CardMembershipRounded sx={{ color: "#00b4d8" }} />,
    subMenu: [
      {
        path: "/studycertificate",
        name: "Study Certificate",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "studyCertificate",
      },
      {
        path: "/transfercertificate",
        name: "Transfer Certificate",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "transferCertificate",
      },
    ],
  },

  // // Fee
  // {
  //   name: "Fees",
  //   renderName: [
  //     "receiptBookItem",
  //     "feeCategory",
  //     "collectFees",
  //     "balanceFeeReport",
  //     "feeOverview",
  //     "reconciliation",
  //   ],
  //   icon: <CurrencyRupee sx={{ color: "#007f5f" }} />,
  //   subMenu: [
  //     {
  //       path: "/receipt",
  //       name: "Receipt Book",
  //       icon: <Receipt />,
  //       renderName: "receiptBookItem",
  //     },
  //     {
  //       path: "/feecategorylist",
  //       name: "Fee Category",
  //       icon: <Receipt />,
  //       renderName: "feeCategory",
  //     },

  //     {
  //       path: "/detailed-fee",
  //       name: "Collect Fees",
  //       icon: <Receipt />,
  //       renderName: "collectFees",
  //     },
  //     {
  //       path: "/balancefeereport",
  //       name: "Balance Fee Report",
  //       icon: <FastForward />,
  //       renderName: "balanceFeeReport",
  //     },
  //     {
  //       path: "/feeoverview",
  //       name: "Fee Overview",
  //       icon: <FastForward />,
  //       renderName: "feeOverview",
  //     },
  //     {
  //       path: "/dashboard/fee/reconciliation",
  //       name: "Reconciliation",
  //       icon: <FiberManualRecordOutlined style={{ fontSize: "10px" }} />,
  //       renderName: "reconsiliation",
  //     },
  //   ],
  // },
  {
    name: "Fees",
    renderName: [
      "receiptTitle",
      "feeMap",
      "feeMapCategory",
      "collectFees",
      "balanceFeeReport",
      "feeOverview",
      "reconsiliation",
    ],
    icon: <CurrencyRupee sx={{ color: "#007f5f" }} />,
    subMenu: [
      {
        path: "/receipt-book",
        name: "Receipt Book",
        icon: <Receipt />,
        renderName: "receiptTitle",
      },
      {
        path: "/fee-map-category",
        name: "Fee Map Category",
        icon: <Receipt />,
        renderName: "feeMapCategory",
      },

      {
        path: "/collect-fee-detail",
        name: "Collect Fees",
        icon: <Receipt />,
        renderName: "collectFees",
      },
      {
        path: "/balance-fee-report",
        name: "Balance Fee Report",
        icon: <FastForward />,
        renderName: "balanceFeeReport",
      },
      {
        path: "/fee-overview",
        name: "Fee Overview",
        icon: <FastForward />,
        renderName: "feeOverview",
      },
      {
        path: "/dashboard/fee/reconciliation",
        name: "Reconciliation",
        icon: <FiberManualRecordOutlined style={{ fontSize: "10px" }} />,
        renderName: "reconsiliation",
      },
    ],
  },
  // Hostel
  {
    name: "Hostel",
    renderName: ["hostel", "roomType", "hostelroom", "hostelmember"],
    icon: <GiteIcon sx={{ color: "#ffd60a" }} />,
    subMenu: [
      {
        path: "/dashboard/hostel",
        name: "Manage Hostel",
        renderName: "hostel",
      },

      {
        path: "/dashboard/hostelroomtype",
        name: "Manage Room Type",
        renderName: "roomType",
      },
      {
        path: "/dashboard/hostelroom",
        name: "Manage Room & Bed",
        renderName: "hostelroom",
      },
      {
        path: "/dashboard/hostelmember",
        name: "Hostel Member",
        renderName: "hostelmember",
      },
    ],
  },
  // Transport
  {
    name: "Transport",
    renderName: [
      "vehicle",
      "route",
      "trips",
      "transportmember",
      "vehicleLog",
      "maintainance",
      "transportFee",
    ],
    icon: <DirectionsBusIcon sx={{ color: "#6f1d1b" }} />,
    subMenu: [
      {
        path: "/dashboard/vehicle",
        name: "Vehicle",
        renderName: "vehicle",
      },
      {
        path: "/dashboard/route",
        name: "Manage Route & Trips",
        renderName: "route",
      },

      {
        path: "/dashboard/trips",
        name: "Routes",
        renderName: "trips",
      },

      {
        path: "/dashboard/transportmember",
        name: "Transport Member",
        renderName: "transportMember",
      },
      {
        path: "/dashboard/log",
        name: "Vehicle Log",
        renderName: "vehicleLog",
      },
      {
        path: "/dashboard/maintenance",
        name: "Vehicle Maintenance",
        renderName: "maintainance",
      },
      // {
      //   path: "/dashboard/transportfee",
      //   name: "Collect Fee",
      //   renderName: "transportFee",
      // },
    ],
  },
  // Accounts
  // Payroll
  {
    name: "Payroll",
    renderName: ["salaryGrade", "salaryPayment"],
    icon: <CurrencyRupeeIcon sx={{ color: "#007f5f" }} />,
    subMenu: [
      {
        path: "/dashboard/grade",
        name: "Salary Grade",
        renderName: "salaryGrade",
      },
      {
        path: "/dashboard/payment",
        name: "Make Payment",
        renderName: "salaryPayment",
      },
    ],
  },
  // Report
  {
    path: "/dashboard/report",
    name: "Report",
    icon: <BarChart sx={{ color: "#00e676" }} />,
    renderName: ["reports"],
    subMenu: [
      {
        path: "/dashboard/report/libraryReport",
        name: "Library report",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "reports",
      },
      {
        path: "/dashboard/report/studentAttendance",
        name: "Student Attendance",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "reports",
      },
      {
        path: "/dashboard/report/yearlyAttendance",
        name: "Student Yearly Attendance",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "reports",
      },

      {
        path: "/dashboard/report/employeeAttendance",
        name: "Employee Attendance",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "reports",
      },
      {
        path: "/dashboard/report/employeeYearlyAttendance",
        name: "Employee Yearly Attendance",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "reports",
      },
      {
        path: "/dashboard/report/studentReport",
        name: "Student Report",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "reports",
      },
      {
        path: "/dashboard/report/studentActivityReport",
        name: "Student Activity Report",
        icon: <FiberManualRecord style={{ fontSize: "10px" }} />,
        renderName: "reports",
      },
    ],
  },
  // Visitor Info
  {
    name: "Visitor Info",

    icon: <WcIcon sx={{ color: "#1a237e" }} />,
    renderName: ["visitory", "studentCheckout"],

    subMenu: [
      {
        path: "/dashboard/visitoryinfo",
        name: "Visitor Info",
        renderName: "visitory",
      },
      {
        path: "/dashboard/studentcheckout",
        name: "Student Checkout",
        renderName: "studentCheckout",
      },
    ],
  },
  // Help Desk
  {
    path: "/tickets",
    name: "Help Desk",
    renderName: ["ticket"],
    icon: <Groups sx={{ color: "#ff006e" }} />,
    subMenu: [],
  },
  // Guardian Feedback
  {
    path: "/dashboard/guardian",
    name: "Guardian Feedback",
    renderName: ["guardianFeedback"],
    icon: <FeedbackIcon sx={{ color: "#ff5252" }} />,
    subMenu: [],
  },
  //
];

export default menuItem;
