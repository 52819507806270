import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  createTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const Title = styled(Box)(() => ({
  color: "orange",
  fontSize: "2rem",
  fontWeight: 700,
}));

const Random = styled(Box)(({ theme }) => ({
  color: "gray",
  marginTop: "20px",
  [theme.breakpoints.down(900)]: {
    width: "100%",
  },
}));

const Cardcontent = styled(CardContent)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CardImage = styled(CardMedia)(({ theme }) => ({
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const ViewMoreEvents = ({ elem }) => {
  const [showFull, setShowFull] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;
    const isFullContentPage = currentPath === `/${elem.id}`;
    setShowFull(isFullContentPage);
  }, [elem.id]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <CardImage
            component="img"
            image={elem.image.link}
            alt="Live from space album cover"
            height="100%"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Cardcontent>
            <Random>
              <Title variant="body2">{elem.title}</Title>
              <Typography variant="subtitle1">
                Hosted By:
                <span style={{ color: "black" }}> {elem.hostedBy}</span>
              </Typography>
              <Typography variant="subtitle1">
                Location:
                <span style={{ color: "black" }}> {elem.location}</span>
              </Typography>
              <Typography variant="subtitle1">
                Date:
                <span style={{ color: "black" }}>
                  {" "}
                  {dayjs(elem.date).format("DD-MMM-YYYY")}
                </span>
              </Typography>
              <Typography
                variant="span"
                sx={{ color: "black", fontSize: "15px" }}>
                {elem.note}
              </Typography>
            </Random>
          </Cardcontent>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            mt: 1,
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          {" "}
          <Button variant="outlined" onClick={() => navigate("/")}>
            Go Back
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewMoreEvents;
