import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  createTheme,
} from "@mui/material";
import css from "./OurBlog.module.css";
import { ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";
import { arrData } from "./AwardsAchiv";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AppSlider } from "./AppSlider";
import { blue, blueGrey, grey } from "@mui/material/colors";
import {
  Link,
  unstable_HistoryRouter,
  useNavigate,
  useParams,
} from "react-router-dom";
import SettingContext from "../context/SettingsContext";
import { get } from "../services/apis";
import { calculateSlidersSetting } from "../Data/carousal";
const theme = createTheme();

const TypographyMain = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginTop: "25px",
  fontSize: "40px",
  color: "#f86f03",
  fontWeight: "bold",
  textShadow: "10px 8px 8px #969c96",
  [theme.breakpoints.down("md")]: {
    // textAlign:"center",
    // margin:0,
    // padding:"25px"
  },
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    margin: 0,
    padding: "0",
  },
}));

const Btn = styled(Button)(({ theme }) => ({
  backgroundColor: "#ff6d34",
  border: "1px solid #ff6d34",
  color: " black",
  fontSize: "12px",
  fontFamily: "serif",
  marginRight: "13px",
  [theme.breakpoints.down("md")]: {
    marginRight: "0px",
  },
}));

const Title = styled(Box)(() => ({
  color: "#1976D2",
  fontSize: "25px",
  fontWeight: "bold",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

const Founder = styled(Box)(() => ({
  color: grey[500],
  fontSize: "14px",
  "&:hover": { color: "#ff6d34" },
}));

const Random = styled(Box)(() => ({
  display: "flex",
  gap: 5,
}));

const Content = styled(Typography)(() => ({
  fontSize: "18px",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
}));

const Cardcontent = styled(CardContent)(({ theme }) => ({
  // width:"300px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Arc = styled(Box)(() => ({
  position: "absolute",
  top: "-20px",
  right: "10px",
  clipPath: "circle(26.6% at 100% 0)",
  backgroundColor: "#00BDA6",
}));

const CardImageWrapper = styled(Card)(({ theme }) => ({
  // width: '280px',
  // width: "95%",
  position: "relative",
  // "&:hover": {
  //   position: "relative",
  //   borderRadius: "5px",
  //   backgroundColor: "rgba(0, 0, 0, 2.5)",
  // },
  // [theme.breakpoints.down("md")]: {
  //   width: "95%",
  // },
  // [theme.breakpoints.down("sm")]: {
  //   width: "100%",
  // },
}));

const CardImage = styled(CardMedia)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const ViewMore = styled(Button)(() => ({
  color: "#ef6c00",
  padding: "0px 0px",
  borderBottom: "2px solid #dfdfdf",
  paddingTop: "5%",
  "&:hover": {
    color: "#1976D2",
  },
}));

const Our = styled(Typography)(({ theme }) => ({
  // color: '#00BDA6',
  textAlign: "center",
  textTransform: "uppercase",
  fontSize: "2.5rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

// const Random1 = styled.div`
//   display: "flex",
//   justifyContent: "flex-end",
//   marginRight: "15px",
//   gap: "20px",

//   .MuiSvgIcon-root {
//     cursor: pointer;
//     border: 1px solid #f86f03;
//     transition: background-color 0.3s;
//   }

//   .MuiSvgIcon-root:hover {
//     background-color: #f86f03;
//   }
// `;

const Random1 = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginRight: "15px",
  gap: "20px",
  ".MuiSvgIcon-root": {
    cursor: "pointer",
    border: "1px solid #f86f03",
    transition: "background-color 0.3s",
  },

  ".MuiSvgIcon-root:hover": {
    background: "#f86f03",
    color: "#fff",
  },

  [theme.breakpoints.down("md")]: {
    marginTop: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "10px",
  },
  [theme.breakpoints.down("xs")]: {
    marginTop: "10px",
  },
}));

const AnchorBox = styled(Box)({
  "&::after": {
    content: '""',
    height: "3px",
    marginTop: "4px",
    backgroundColor: "#dfdfdf",
    display: "block",
    fontSize: "1rem",
    transform: "scale(0,1)",
    transition: "transform 0.35s ease",
  },
  "&:hover::after": {
    width: "100%",
    backgroundColor: "#1976D2",
    transform: "scale(1,1)",
  },
});

export const CardComponent = ({ elem }) => {
  const [showFull, setShowFull] = useState(false);
  const navigate = useNavigate();

  console.log(elem, "elem Award");

  const handleReadMoreClick = () => {
    navigate(`/ourachievement/${elem._id}`, { state: elem });
    if (showFull) {
      navigate("/");
    } else {
      setShowFull(!showFull);
    }
  };
  useEffect(() => {
    const currentPath = window.location.pathname;
    const isFullContentPage = currentPath === `/${elem.id}`;
    setShowFull(isFullContentPage);
  }, [elem.id]);

  return (
    <>
      <ThemeProvider theme={theme}>
        {/* <Card elevation={1}> */}
        {/* <Grid container spacing={2}> */}
        {/* <Grid item xs={12} md={6} lg={3}> */}
        {/* <CardImage
              className={css.image}
              component="img"
              image={elem.image.link}
              alt="Live from space album cover"
            /> */}
        {/* </Grid> */}
        {/* <Grid item xs={12} md={6} lg={6}> */}
        {/* <Random>
            <Title variant="body2">{elem.title}</Title>
          </Random> */}
        {/* <Content variant="body2">
            {showFull
              ? elem.note
                ? elem.note
                : ""
              : elem.note
              ? elem.note.substring(0, 70) + "..."
              : ""}
          </Content> */}
        {/* </Grid> */}
        {/* </Grid> */}
        {/* </Card> */}
        <Card sx={{ maxWidth: 375, alignSelf: "center" }}>
          <CardMedia
            className={css.image}
            component="img"
            image={elem.image.link}
            alt="Live from space album cover"
            height="297"
            sx={{ overflow: "hidden" }}
          />
          <CardContent>
            <Title variant="body2">{elem.title}</Title>
            <Content variant="body2">
              {showFull
                ? elem.note
                : "Congratulations on your incredible success!"}
            </Content>
          </CardContent>

          <CardActions>
            <AnchorBox
              className={css.viewmore}
              sx={{
                p: 1,
                cursor: "pointer",
                "&:hover": {
                  color: "#1976D2",
                },
              }}
              onClick={handleReadMoreClick}
            >
              {showFull ? "Read Less" : "Read More"}
            </AnchorBox>
          </CardActions>
        </Card>
      </ThemeProvider>
    </>
  );
};

const OurAchievements = () => {
  const { selectedSetting } = useContext(SettingContext);

  const [awards, setAwards] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },

      {
        breakpoint: 600,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 300,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  const handlePrevClick = () => {
    sliderRef.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.slickNext();
  };
  let sliderRef;

  useEffect(() => {
    const getAllAwards = async () => {
      try {
        const getAwards = await get(
          `/${selectedSetting._id}/award/list/home-page`,
          {
            params: {
              search: {
                webView: "yes",
              },
            },
          }
        );
        if (getAwards.data.success) {
          console.log(getAwards.data.result.data, "award Data");
          setAwards(getAwards.data.result.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllAwards();
  }, []);

  return (
    <>
      <Container sx={{ marginTop: "8rem" }} maxWidth="xl">
        <ThemeProvider theme={theme}>
          <TypographyMain color="initial">
            OUR AWARDS & ACHIVEMENTS{" "}
          </TypographyMain>
          <Box
            sx={{
              padding: { xs: 0, sm: 0, md: "40px" },
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {awards.length > 3 && (
              <Random1>
                <ChevronLeftIcon onClick={handlePrevClick} color="warning" />
                <ChevronRightIcon color="warning" onClick={handleNextClick} />
              </Random1>
            )}

            <AppSlider
              {...calculateSlidersSetting(awards.length)}
              ref={(c) => {
                sliderRef = c;
              }}
              sx={{ justifyContent: "space-between" }}
            >
              {awards.map((elem, index) => (
                <CardComponent key={index} elem={elem} />
              ))}
            </AppSlider>
          </Box>
        </ThemeProvider>
      </Container>
    </>
  );
};
export default OurAchievements;
