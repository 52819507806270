import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from './Addmission.module.css';
import careersCss from '../styles/Careers.module.css';
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";
import { Button, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextareaAutosize, TextField } from "@mui/material";

const Careers = () => {
    const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  return (
    <>
        <TopNav />
        <Navbar />
      <div className={css.headerContainer}>
                <div>
                    <h1>CAREER</h1>
                    <h5><Link style={{
                        color:'white'
                    }} to='/'>Home</Link> / CAREER</h5>
                </div>
      </div>
      <div className={css.contentContainer}>
        <div style={{
            width:'70%'
        }}>
            <form style={{
                width:'100%',
                display:'flex',
                flexDirection:'column',
                gap:'1rem'
            }}>
                <FormControl fullWidth>
                    <InputLabel>Application for the post</InputLabel>
                    <Select
                    size="small"
                    label="Application for the post"
                    defaultValue={'teacher'}
                    name="post"
                    required
                    >
                        <MenuItem value={'teacher'}>Teacher</MenuItem>
                        <MenuItem value={'warden'}>Warden</MenuItem>
                        <MenuItem value={'driver'}>Driver</MenuItem>
                        <MenuItem value={'cook'}>cook</MenuItem>
                        <MenuItem value={'peon'}>Peon</MenuItem>
                        <MenuItem value={'administrator'}>Administrator</MenuItem>
                    </Select>
                </FormControl>
                <TextField 
                label="Subject applied for"
                type='text'
                variant="outlined"
                size="small"
                name="subject"
                fullWidth
                required
                />
                <TextField 
                label="Name of candidate"
                type='text'
                variant="outlined"
                size="small"
                name="name"
                fullWidth
                required
                />
                <TextField 
                label="Date of Birth"
                type='date'
                variant="outlined"
                size="small"
                name="dob"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                />
                <TextField 
                label="Father Name"
                type='text'
                variant="outlined"
                size="small"
                name="father"
                fullWidth
                />
                <TextField 
                label="Mother Name"
                type='text'
                variant="outlined"
                size="small"
                name="father"
                fullWidth
                />
                <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
                <RadioGroup
                    name="married"
                >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                </FormControl>
                <TextField 
                label="Name of husband/wife"
                type='text'
                variant="outlined"
                size="small"
                name="spouse"
                fullWidth
                />
                <TextField 
                label="Caste"
                type='text'
                variant="outlined"
                size="small"
                name="caste"
                fullWidth
                required
                />
                <TextField 
                label="Qualification"
                type='text'
                variant="outlined"
                size="small"
                name="qualification"
                fullWidth
                />
                <TextareaAutosize
                placeholder="Name of School & Experience*"
                style={{width:'100%',height:'100px'}}
                name="experience"
                />
                <TextareaAutosize
                placeholder="Address for correspondence"
                style={{width:'100%',height:'100px'}}
                name="correspondence"
                />
                <TextField 
                label="Mobile No"
                type='text'
                variant="outlined"
                size="small"
                name="mobile"
                fullWidth
                />
                <TextField 
                label="Email"
                type='text'
                variant="outlined"
                size="small"
                name="email"
                fullWidth
                />
                <TextField 
                label="Date of Birth"
                type='date'
                variant="outlined"
                size="small"
                name="dob"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                />
                <div>Photo* (please upload images in jpg, png format only & limit is 2MB)</div>
                <input name="photo" type='file' />
                <div>Document SSLC* (please upload images in jpg, png format only & limit is 2MB)</div>
                <input name="sslc" type='file' />
                <div>Document PUC* (please upload images in jpg, png format only & limit is 2MB)</div>
                <input type="file" name="puc" />
                <div>Document DEGREE (please upload images in jpg, png format only & limit is 2MB)</div>
                <input type="file" name="degree" />
                <div className={careersCss.btnContainer}>
                  <Button variant="outlined">Cancel</Button>
                  <Button variant="contained">Submit</Button>
                </div>
            </form>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Careers;