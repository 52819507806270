import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
  css,
  keyframes,
  Paper,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import MovingText from "react-moving-text";
import CelebAnime from "./CelebAnime";
import "./birthday.css";
import { get, post } from "../../services/apis";
import SettingContext from "../../context/SettingsContext";
import appendStringToUrls from "../../utils/appendString";
import { urls as url } from "../../services/urlConstant";
import { toast } from "react-toastify";
import Balloon from "../../assets/Images/baloon.png.png";
import RedImg from "../../assets/Images/redImg.png.png";
import dayjs from "dayjs";

const MovingTextContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "470px",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  // background: "yellow",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

// const moveTextAnimation = keyframes`
//   0% {
//    transform: translateY(100%);
//       }
//   100% {
//     transform: translateY(-100%);
//     bottom: 100% }
// `;

const moveTextAnimation = keyframes`
from {
  transform: translateY(100%);
}
to {
  transform: translateY(-50%);
}
`;

const MovingTexts = styled(Box)(({}) => ({
  position: "absolute",
  bottom: 0,
  color: "white",
  // textAlign: "center",
  animation: css`
    ${moveTextAnimation} 10s linear infinite
  `,
  animationPlayState: "running",

  "&:hover": {
    animationPlayState: "paused",
  },
}));

const BirthdayBox = styled(Box)(({}) => ({
  display: "flex",
  alignItems: "center",
  position: "relative",
  zIndex: 10,
}));

const GridContainerBox = styled(Grid)(
  ({}) => css`
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(https://img.freepik.com/free-vector/gradient-abstract-geometric-background-with-triangles_52683-61899.jpg?size=626&ext=jpg&ga=GA1.1.1434379891.1671263694&semt=ais);
    background-attachment: fixed;
    background-repeat: no-repeat;
    padding: 0px 0px 80px 0px;
    max-width: 100%;
    background-size: cover;
    background-color: #198eeb;
    background-position: center;
    position: relative;
  `
);

const GridBox1 = styled(Box)(({ theme }) => ({
  marginTop: "5%",
  // marginLeft: "5%",
  // [theme.breakpoints.down("md")]: {
  //   marginTop: "30%",
  //   marginLeft: "5%",
  // },
}));

let names = [
  {
    name: "Raju",
    class: "10",
    section: "A",
  },
  {
    name: "Monika",
    class: "10",
    section: "C",
  },
  {
    name: "Alia",
    class: "8",
    section: "A",
  },
  {
    name: "Raina",
    class: "9",
    section: "D",
  },
];

const Birthday = () => {
  const { selectedSetting, setSelectedSetting } = useContext(SettingContext);
  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  const [visible, setVisible] = useState(true);
  const [open, setOpen] = useState(false);
  const [birthdayEvents, setBirthdayEvents] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  let handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const register = async (e) => {
    e.preventDefault();
    try {
      const { data } = await post(urls.register.create, formData);
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      console.log(error);
    }
  };

  // const getAllBirthdayEvents = async () => {
  //   try {
  //     const { data } = await get(`/${selectedSetting._id}/student-birthday`);
  //     console.log(
  //       data,
  //       "birthday==================================================="
  //     );
  //     setBirthdayEvents(data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getAllBirthdayEvents = async () => {
    try {
      const { data } = await get(urls.birthday.getStudentBirthday);
      setBirthdayEvents(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllBirthdayEvents();
  }, []);

  return (
    <GridContainerBox>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          paddingTop: { sm: "40px", xs: "20px" },
        }}
      >
        <Grid item>
          <GridBox1>
            <BirthdayBox>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  color: "white",
                  textShadow: "3px 3px black",
                  fontSize: { sm: "50px", xs: "30px" },
                }}
              >
                HAPPY{" "}
              </Typography>
              &nbsp;&nbsp;
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  color: "#0E4BF1",
                  fontSize: { sm: "50px", xs: "30px" },
                }}
              >
                BIRTHDAY{" "}
              </Typography>
              &nbsp;
              <img
                // src="cake-bg3.png"
                src={RedImg}
                style={{ height: "50px", width: "50px" }}
                alt="loading..."
              />
            </BirthdayBox>

            {birthdayEvents.length ? (
              <MovingTextContainer>
                <MovingTexts>
                  {birthdayEvents.map((data, i) => (
                    <React.Fragment key={i}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          color: "#FFD24D",
                          mt: 4,
                          width: "150px",
                        }}
                      >
                        {/* <img
                          src="cake-bg3.png"
                          style={{
                            height: "30px",
                            objectFit: "contain",
                          }}
                          alt="loading..."
                        />{" "}
                        {data.basicInfo.name}{" "}
                        <img
                          src="cake-bg3.png"
                          style={{
                            height: "30px",
                            objectFit: "contain",
                          }}
                          alt="loading..."
                        /> */}
                      </Typography>
                      <img
                        src={Balloon}
                        alt="loading..."
                        style={{
                          height: "170px",
                          objectFit: "contain",
                        }}
                      />
                      <Typography variant="h6" sx={{ fontSize: "16px", mt: 1 }}>
                        <b style={{ color: "#FFD24D" }}>Name: </b>
                        {data.basicInfo.name}
                      </Typography>
                      <Typography variant="h6" sx={{ fontSize: "16px", mt: 1 }}>
                        <b style={{ color: "#FFD24D" }}>Class: </b>
                        {data.academicInfo.class.className}
                      </Typography>
                      <Typography variant="h6" sx={{ fontSize: "16px" }}>
                        <b style={{ color: "#FFD24D" }}>Roll No: </b>
                        {data.academicInfo.rollNo},{" "}
                        {data.academicInfo.section.sectionName}
                      </Typography>
                      <Typography variant="h6" sx={{ fontSize: "16px" }}>
                        <b style={{ color: "#FFD24D" }}>DOB: </b>
                        {dayjs(data.basicInfo.dob).format("DD, MMMM, YYYY")}
                      </Typography>
                    </React.Fragment>
                  ))}
                </MovingTexts>
              </MovingTextContainer>
            ) : null}
          </GridBox1>
        </Grid>

        <Grid item sx={{ paddingTop: "2rem" }}>
          <div className="wrapper">
            <h2>Registration Form</h2>

            <form action="#" onSubmit={register}>
              <div className="input-box">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  required
                />
              </div>

              <div className="input-box">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  required
                />
              </div>

              <div className="input-box">
                <input
                  type="text"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Message"
                  required
                />
              </div>

              <div className="policy">
                <input type="checkbox" />

                <h3>I accept all terms & condition</h3>
              </div>

              <div className="input-box button">
                {/* <input type="submit" value="Register Now" /> */}
                <button type="submit">Register Now</button>
              </div>

              <div className="text">
                <h3>
                  Already have an account? <a href="/login">Login now</a>
                </h3>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>

      {/* <CelebAnime /> */}
    </GridContainerBox>
  );
};

export default Birthday;
