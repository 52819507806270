import React from "react";
import { Grid } from "@mui/material";
import Footer from "../Footer/Footer";
import Navbar from "../navbar/Navbar";
import NewsNotic from "../NewsNotic/NewsNotic";
import Demo from "../NewsNotic/Demo";

// // import CardContents from "../components/Cards/CardContents";



const OurSchool = () => {
  return (
    <div >
    
      <Navbar/>

     
      <div>
      <Grid container spacing={12}marginLeft={"-76px"} style={{marginTop:"40px"}}>
        <Grid items xs={12} mg={12} lg={8} style={ {"margin":"20px 0px 20px 0px","border-right":"1px solid #BDBDBD",width:"500px"}}>
          <h5  style={{margin:"0px 10px -5px 100px",fontWeight:"700"}}>OUR MISSION:</h5>
          <p style={{margin:"10px 10px 20px 100px",fontSize:"1.11rem"}}>Academic excellence being the top priority emphasis is given for all round development of child.</p>
          <h5  style={{margin:"10px 10px -5px 100px",fontWeight:"700"}}>OBJECTIVES:</h5>
            <ul style={{margin:"10px 10px -5px 100px",fontSize:"1.11rem"}}>
              <li>Promoting national integrity and feeling of oneness among students.</li>
              <li>Empowering our kids to be compassionate and responsive to our culture and heritage.</li>
              <li>promoting scientific attitude and temper.</li>
            </ul>
            <p style={{margin:"10px 10px 20px 100px",fontSize:"1.11rem"}}>We take pride in educating thousands of bright kids in last several years and being part of their success stories.We are glad to be part of the nation building exercise in a noble and humble way.</p>
            <h5  style={{margin:"10px 10px 10px 100px",fontWeight:"700"}}>SOME OF THE SALIENT FEATURES OF THE INSTITUION ARE:</h5>
            <ul  style={{margin:"10px 10px 10px 100px",fontSize:"1.11rem"}}>
              <li>Affiliated to karnataka State board and is approved and recognized by the department of public instruction ,karnataka.</li>
              <li>Co-education school exclusively offering English as the medium of instruction.</li>
              <li>A sprawling 3 acres campus with a large open ground for outdoor activity and thousands of square feet of indoor classroom and activity space.</li>
              <li>Over 34 spacious and well ventilated classrooms.They are designed in a way to cause minimum disturbance to adjoining classes.</li>
              <li>Well qualified and experienced teaching staff with very good student-teacher ratio of over 10:1 with over 60 full time teachers for high quality care of pupils.</li>
              <li>Over 80 non-teaching staff to assist in administration and maintenance of the school.</li>
            </ul>
        
        </Grid>
        <Grid items xs={12} mg={6} lg={4} style={{height:"80vh",paddingTop:"40px"}}>
          <Demo/>
        </Grid>
      </Grid>
       

      </div>
      <Footer/>
    </div>
    
  );
 
};

export default OurSchool;





