import { arrData } from "./AwardsAchiv";
import { Link, useLocation, useParams } from "react-router-dom";
import { CardComponent } from "./OurAchievements";
import { Box, Container, Grid, Typography, createTheme } from "@mui/material";
import styled from "@emotion/styled";
import style from "./OurBlog.module.css";
import { ThemeProvider } from "@emotion/react";
import Footer from "../Components2/Footer";
import Mainnav from "../NavbarComponents/MainNav";
import image from "../assets/Images/award.jpg";
import TopNav from "../NavbarComponents/TopNav";
import ViewMoreEvents from "./ViewMoreEvents";

const theme = createTheme();

const Text = styled(Box)(({ theme }) => ({
  textAlign: "center",
}));
const Heading = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "2rem",
  textTransform: "uppercase",
  fontFamily: "sans-serif",
  fontWeight: "bold",
}));

const Heading1 = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
}));

const Headin = styled(Link)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
  textDecoration: "none",
}));
const BoxSize = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "#fff",
  marginTop: "30px",
  borderRadius: "5px",
  paddingLeft: 0,
}));

const ContentContainer = styled(Container)(({ theme }) => ({
  padding: "30px",
  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px 15px",
  },
}));
const OuterBox = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)),url("https://plus.unsplash.com/premium_photo-1683749809341-23a70a91b195?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`,
  backgroundRepeat: "no-repeat",
  width: "100%",
  backgroundSize: "100% 100%",
  paddingTop: "150px",
}));

const Detail = () => {
  const { id } = useParams();

  let { state } = useLocation();

  return (
    <>
      <TopNav />
      <Mainnav />
      <OuterBox>
        <Typography
          variant="h4"
          color="#F86F03"
          textAlign="center"
          fontWeight="700">
          OUR AWARDS & ACHIVEMENTS
        </Typography>
        <Container>
          <ThemeProvider theme={theme}>
            <BoxSize padding={{ xs: "15px", md: "20px" }}>
              <ViewMoreEvents elem={state} />
            </BoxSize>
          </ThemeProvider>
        </Container>

        <Footer />
      </OuterBox>
    </>
  );
};
export default Detail;
