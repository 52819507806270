import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "rgb(27, 55, 121)",
    },
    secondary: {
      main: "#b0b3ff",
    },
  },

  typography: {
    allVariants: {
      // wordWrap: "break-word",
      // wordBreak: "break-word",
    },
  },
  shape: {
    borderRadius: 5,
  },

  components: {
    MuiListItemText: {
      styleOverrides: {
        primary: {
          // fontWeight: "bold",
          // fontSize: "15px",
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          fontSize: 12,
          paddingTop: "6px",
          paddingBottom: "6px",
        },
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              backgroundColor: "rgb(27, 55, 121)",
              color: "#fff",
            }),
        }),
      },
    },

    MuiIcon: {
      defaultProps: {
        fontSize: "small",
      },
    },

    // MuiTable: {
    //   styleOverrides: {
    //     root: {
    //       "&.MuiTable-root": {
    //         size: "small",
    //       },
    //     },
    //   },
    // },

    MuiTableHead: {
      styleOverrides: {
        root: {
          height: "40px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "8px",
          height: "45px",
        },
      },
    },
  },
});
