import { Box, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled/macro";
import React from "react";
import styles from "./MuiCard.module.css";
import { TiSocialFacebook } from "react-icons/ti";
import { FaLinkedinIn } from "react-icons/fa";
import TwitterIcon from "@mui/icons-material/Twitter";
import { css } from "@emotion/react";

const MainGrid = styled(Grid)(
  ({}) => css`
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    width: fit-content;
    overflow: hidden;
    position: relative;
    display: flex;
  `
);

const MainLogoBox = styled(Box)(
  ({}) => css`
    display: flex;
    justify-content: space-evenly;
    z-index: 1;
    display: none;
    width: 140px;

    ${MainGrid}:hover & {
      display: flex;
      position: absolute;
      overflow: hidden;
      bottom: 50%;
      left: 26%;
      display: flex;
      gap: 5%;
      justify-content: center;
      align-items: center;
    }
  `
);

const FaceBookLogoBox = styled(Box)(
  ({}) =>
    ({}) =>
      css`
        height: 35px;
        width: 35px;
        border-radius: 50%;
        font-size: 25px;
        // background-color: #80cd36;
        background-color: #f86f03;
        display: flex;
        z-index: 20;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: rgb(68, 103, 177);
        }
      `
);

const TwitterLogoBox = styled(Box)(
  ({}) =>
    ({}) =>
      css`
        height: 35px;
        width: 35px;
        border-radius: 50%;
        font-size: 25px;
        background-color: #f86f03;
        display: flex;
        z-index: 20;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #5ac3e8;
        }
      `
);

const LinkedinLogoBox = styled(Box)(
  ({}) =>
    ({}) =>
      css`
        height: 35px;
        width: 35px;
        border-radius: 50%;
        font-size: 25px;
        background-color: #f86f03;
        display: flex;
        z-index: 20;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #5ac3e8;
        }
      `
);

const FootName = styled(Typography)(
  ({}) => css`
    font-size: 14px;
    color: #323232;
    text-transform: uppercase;
    font-weight: 500;

    ${MainGrid}:hover & {
      color: #f86f03;
    }
  `
);

const Image = styled.img`
  height: 309px;
  width: 284px;
  transition: 0.4s ease-in-out;
`;
const TeacherNames = styled(Typography)(
  ({}) => css`
    font-size: 14px;
    color: #323232;
    text-transform: uppercase;
    font-weight: Bold;

    ${MainGrid}:hover & {
      color: #f86f03;
    }
  `
);

const PrimaryGrid = styled(Grid)(
  ({}) =>
    css`
      position: relative;
      overflow: hidden;
      height: 309px;
      width: 252px;
    `
);

const SecondaryGrid = styled(Grid)(
  ({}) =>
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      box-shadow: 1px 1px 2px 2px rgb(223 219 219 / 60%);
      padding-left: 20px;
      padding-right: 20px;
    `
);

const TeacherCardItems = ({ items }) => {
  return (
    <Box sx={{ margin: "5%" }}>
      {/* <Box component='div' className={styles.overlay} sx={{position:"absolute",zIndex:105,height:"310px",width:"272px",display:"none",backgroundColor:"rgba(0,0,0,0.5)",transition:"opacity 0.3 sease"}}></Box> */}

      <MainGrid container className={styles.mainGrid}>
        <PrimaryGrid item xs={12} md={6}>
          <Image className={styles.pic} src={items.photo} alt="no img" />
          {/* <MainLogoBox>
            <FaceBookLogoBox>
              <TiSocialFacebook color="white" />
            </FaceBookLogoBox>

            <TwitterLogoBox>
              <TwitterIcon sx={{ color: "white" }} />
            </TwitterLogoBox>

            <LinkedinLogoBox>
              <FaLinkedinIn color="white" />
            </LinkedinLogoBox>
          </MainLogoBox> */}
        </PrimaryGrid>

        <SecondaryGrid item xs={12} md={6}>
          <TeacherNames>{items.name}</TeacherNames>
          <Typography sx={{ fontSize: "16px", color: "#604232" }}>
            {items.designation}
          </Typography>
          <Typography sx={{ fontSize: "15px", color: "#947c7a" }}>
            {items.description}
          </Typography>
          <FootName>READ MORE</FootName>
        </SecondaryGrid>
      </MainGrid>
    </Box>
  );
};

export default TeacherCardItems;
