import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from './Addmission.module.css'
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";
import { get } from "../services/apis";
import { urls } from "../services/urlConstant";
import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import dayjs from "dayjs";

const HomeNotice = () => {
    const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);
  const [notices,setNotices] = useState([]);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    const getNotice = async () => {
        try {
            const notice = await get(urls.announceNotice.getNotice);
            setNotices(notice.data.data);
        } catch (error) {
            
        }
    }
    getNotice();
  }, []);

  return (
    <>
        <TopNav />
        <Navbar />
      <div className={css.headerContainer}>
                <div>
                    <h1>NOTICE</h1>
                    <h5><Link style={{
                        color:'white'
                    }} to='/'>Home</Link> / NOTICE</h5>
                </div>
      </div>
      <div className={css.contentContainer}>
        <div className={css.content}>
            <div style={{
                margin:'1rem 2rem',
                display:'flex',
                flexWrap:'wrap',
                gap:'1.5rem'
            }}>
                {notices.map((ele) => (
                    <Card key={ele._id} sx={{minWidth:275}}>
                    <CardContent>
                        <Typography variant="h5">
                            {ele.title}
                        </Typography>
                        <p>
                            {ele.notice}
                        </p>
                        <div style={{fontWeight:'bold',fontSize:'12px'}}>{dayjs(ele.date).format('MMMM DD, YYYY')}</div>
                    </CardContent>
                    <CardActions>
                        <Link style={{color:'inherit',textDecoration:'none'}} to={`/home-notice/${ele._id}`}>
                          <Button size="small">Read More</Button>
                        </Link>
                    </CardActions>
                </Card>
                ))}
            </div>
        </div>
        <div className={css.newsNoticeContainer}>
            <div className={css.newsNotice}>
                <Demo />
            </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default HomeNotice;