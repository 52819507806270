import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from './Addmission.module.css'
import assignmentCss from '../styles/AssignmentHome.module.css';
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";
import { get } from "../services/apis";
import { urls } from "../services/urlConstant";

const AssignmentHome = () => {
    const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);
  const [schoolClasses,setSchoolClasses] = useState([]);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  useEffect(() => {
    const getClasses = async () => {
        try {
            const schoolClasses = await get(`${urls.home.getHome}/${urls.class.getClass}`);
            if(schoolClasses.data.success) {
                setSchoolClasses(schoolClasses.data.data);
            }
        } catch (error) {
            
        }
    }
    getClasses();
  },[])

  return (
    <>
        <TopNav />
        <Navbar />
      <div className={css.headerContainer}>
                <div>
                    <h1>ASSIGNMENTS</h1>
                    <h5><Link style={{
                        color:'white'
                    }} to='/'>Home</Link> / ASSIGNMENT</h5>
                </div>
      </div>
      <div className={css.contentContainer}>
        <div className={css.content}>
            <div className={css.contentHolder}>
                <div className={css.contentText}>
                    <ul>
                        {schoolClasses.map((schoolClass) => (
                            <Link to={`/view-assignment/${schoolClass._id}`} className={assignmentCss.link}><li className={assignmentCss.list} key={schoolClass._id}>{schoolClass.className}</li></Link>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
        <div className={css.newsNoticeContainer}>
            <div className={css.newsNotice}>
                <Demo />
            </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AssignmentHome;