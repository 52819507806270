import React, { useState, useEffect } from "react";
import { Link,NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from './Addmission.module.css'
import TopNav from "./navbar/TopNav";
import Footer from "./Footer/Footer";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";

const Academics = () => {
    const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  const menuItems = [
    {
      title: "Home",
      children: [],
    },
    {
      title: "About",
      children: [
        {
          title: "Our School",
          link: "/our-school",
        },
        {
          title: "Principal Desk",
          link: "/principal-desk",
        },
        {
          title: "Faculty",
          link: "/faculty",
        },
      ],
    },
    {
      title: "Gallery",
      children: [],
    },
    {
      title: "Academics",
      children: [],
    },
    {
      title: "Facilities",
      children: [
        {
          title: "Library",
          link: "/library",
        },
        {
          title: "Sports",
          link: "/sports",
        },
        {
          title: "Canteen",
          link: "/canteen",
        },
        {
          title: "Transport",
          link: "/transport",
        },
        {
          title: "Edusmart",
          link: "/Edusmart",
        },
      ],
    },
    {
      title: "Jobs",
      children: [],
    },
    {
      title: "Exam",
      children: [
        {
          title: "Exam Time Table",
          link: "/birth-day",
        },
        {
          title: "Hall Ticket",
          link: "/annual-day",
        },
        {
          title: "Results",
          link: "/freshers-day",
        },
      ],
    },
    {
      title: "Contact Us",
      children: [],
    },
  ];

  return (
    <>
        <TopNav />
        <Navbar />
      <div className={css.headerContainer}>
                <div>
                    <h1>ACADEMICS</h1>
                    <h5><Link style={{
                        color:'white'
                    }} to='/'>Home</Link> / ACADEMICS</h5>
                </div>
      </div>

      <Grid container spacing={12}marginLeft={"-76px"}>
        <Grid items xs={12} mg={12} lg={8} style={ {"margin":"40px 0px 20px 0px","border-right":"1px solid #BDBDBD"}}>
        <h5 style={{margin:"10px 10px -5px 24px",fontWeight:"700"}}>SCHOOL TIMINGS</h5>
        <p style={{margin:"10px 10px 20px 24px",fontSize:"1.11rem"}}>The higher primary timings are from 07:45 am to 02:15 PM for Classes VI/VII/VIII. Extra/remedial classes will be conducted from 3 PM to 4:45 PM for students requiring additional care.
        <br></br>
        High school timings are from 10:00 AM to 4:45 PM from Monday to Friday.
        <br></br>
        On Saturday school timings are 7:45 AM to 12:30 for primary and 10:00 am to 2:15 pm for high school.
         </p>
         <h5 style={{margin:"5px 10px -5px 24px",fontWeight:"700"}}>PROGRESS REPORT</h5>   
           <p style={{margin:"10px 10px 20px 24px",fontSize:"1.11rem"}}>5th standard syllabusStudents are given periodical tests (FA1/FA2/SA1/FA3/FA4/SA2) to assess their progress and report cards are sent to their parents.
           <br></br>
           Please note: a child must write all examinations and tests as all marks will be considered for final result.
           </p> 
           <h5 style={{margin:"5px 10px -5px 24px",fontWeight:"700"}}> ORIENTATION PERIOD/PASS/FAIL</h5>   
           <p style={{margin:"10px 10px 20px 24px",fontSize:"1.11rem"}}>
           It is applicable only to the classes VI/VII/VIII i.e if student fails in examination he is given second chance to write examination to get promoted to next class. However, it is not applicable for class IX and X.
           </p>
           <h5 style={{margin:"5px 10px -5px 24px",fontWeight:"700"}}> OTHER TRAINING PROGRAMMES</h5> 
           <p style={{margin:"10px 10px 20px 24px",fontSize:"1.11rem"}}>
           We strive to impart quality education to all our students on par with corporate and convent schools in large cities and metros. We encourage our students to imbibe competitive spirit and compete in various science and mathematics contests and competitions. We have designed special coaching for meritorious students from VI to X.We engage special faculty for specialized training on the following national and international programmes.
           <br></br>
           Geo Genius/KO/SCYGANLSTSE/Olympiad Examinations/NIMO/NTSE (X only) /ASSET/IAIS/CYPYLS (X) Sainik Entrance (only class VIII studens) .Students wanting to undergo training on the above programmes need to extend for upto 2 hours after the regular closure time.
           </p>
           <h5 style={{margin:"5px 10px -5px 24px",fontWeight:"700"}}>EXTRA (REMEDIAL) CLASSES</h5> 
           <p style={{margin:"10px 10px 20px 24px",fontSize:"1.11rem"}}>
           We take special care of the students who require additional support in academics. Students would be assessed from time to time for their understanding of subjects through periodical tests. Underperforming students from classes VI/VII/VIII have the opportunity of availing extra classes on payment of additional fees.
           </p>
         </Grid>
         <Grid items xs={12} mg={6} lg={4} style={{height:"80vh",paddingTop:"40px"}}>
                <Demo />
                </Grid>
         </Grid>
      {/* <div className={css.contentContainer}>
          <div className={css.content}>
          <div className={css.contentHolder}>
                    <div className={css.contentHead}>
                    SCHOOL TIMINGS
                    </div>
                    <div className={css.contentText}>
                    The higher primary timings are from 07:45 am to 02:15 PM for Classes VI/VII/VIII. Extra/remedial classes will be conducted from 3 PM to 4:45 PM for students requiring additional care.
                    </div>
                    <div className={css.contentText}>
                    High school timings are from 10:00 AM to 4:45 PM from Monday to Friday.
                    </div>
                    <div className={css.contentText}>
                    On Saturday school timings are 7:45 AM to 12:30 for primary and 10:00 am to 2:15 pm for high school.
                    </div>
                </div>
                <div className={css.contentHolder}>
                    <div className={css.contentHead}>
                    PROGRESS REPORT
                    </div>
                    <div className={css.contentText}>
                    5th standard syllabusStudents are given periodical tests (FA1/FA2/SA1/FA3/FA4/SA2) to assess their progress and report cards are sent to their parents.</div>
                    </div>
                    <div className={css.contentText}>
                    Please note: a child must write all examinations and tests as all marks will be considered for final result.
                    </div>
                <div className={css.contentHolder}>
                    <div className={css.contentHead}>
                    ORIENTATION PERIOD/PASS/FAIL
                    </div>
                    <div className={css.contentText}>
                    It is applicable only to the classes VI/VII/VIII i.e if student fails in examination he is given second chance to write examination to get promoted to next class. However, it is not applicable for class IX and X.
                    </div>
                </div>
                <div className={css.contentHolder}>
                    <div className={css.contentHead}>
                    OTHER TRAINING PROGRAMMES
                    </div>
                    <div className={css.contentText}>
                    We strive to impart quality education to all our students on par with corporate and convent schools in large cities and metros. We encourage our students to imbibe competitive spirit and compete in various science and mathematics contests and competitions. We have designed special coaching for meritorious students from VI to X.We engage special faculty for specialized training on the following national and international programmes.
                    </div>
                    <div className={css.contentText}>
                    Geo Genius/KO/SCYGANLSTSE/Olympiad Examinations/NIMO/NTSE (X only) /ASSET/IAIS/CYPYLS (X) Sainik Entrance (only class VIII studens) .Students wanting to undergo training on the above programmes need to extend for upto 2 hours after the regular closure time.
                    </div>
                </div>
                <div className={css.contentHolder}>
                    <div className={css.contentHead}>
                    EXTRA (REMEDIAL) CLASSES
                    </div>
                    <div className={css.contentText}>
                    We take special care of the students who require additional support in academics. Students would be assessed from time to time for their understanding of subjects through periodical tests. Underperforming students from classes VI/VII/VIII have the opportunity of availing extra classes on payment of additional fees.
                    </div>
                </div>
          </div>
          <div className={css.newsNoticeContainer}>
            <div className={css.newsNotice}>
                <Demo />
            </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
}

export default Academics;