import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from './Addmission.module.css'
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";

const EduSmart = () => {
    const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  const menuItems = [
    {
      title: "Home",
      children: [],
    },
    {
      title: "About",
      children: [
        {
          title: "Our School",
          link: "/our-school",
        },
        {
          title: "Principal Desk",
          link: "/principal-desk",
        },
        {
          title: "Faculty",
          link: "/faculty",
        },
      ],
    },
    {
      title: "Gallery",
      children: [],
    },
    {
      title: "Academics",
      children: [],
    },
    {
      title: "Facilities",
      children: [
        {
          title: "Library",
          link: "/library",
        },
        {
          title: "Sports",
          link: "/sports",
        },
        {
          title: "Canteen",
          link: "/canteen",
        },
        {
          title: "Transport",
          link: "/transport",
        },
        {
          title: "Edusmart",
          link: "/Edusmart",
        },
      ],
    },
    {
      title: "Jobs",
      children: [],
    },
    {
      title: "Exam",
      children: [
        {
          title: "Exam Time Table",
          link: "/birth-day",
        },
        {
          title: "Hall Ticket",
          link: "/annual-day",
        },
        {
          title: "Results",
          link: "/freshers-day",
        },
      ],
    },
    {
      title: "Contact Us",
      children: [],
    },
  ];

  return (
    <>
        <TopNav />
        <Navbar />
      <div className={css.headerContainer}>
                <div>
                    <h1>EduSmart</h1>
                    <h5><Link style={{
                        color:'white'
                    }} to='/'>Home</Link> / EduSmart</h5>
                </div>
      </div>
      <Grid container spacing={12}marginLeft={"-76px"}>
      <Grid items xs={12} mg={12} lg={8} style={ {"margin":"40px 0px 20px 0px","border-right":"1px solid #BDBDBD"}}>
      <p style={{margin:"0px 10px 20px 24px",fontSize:"1.11rem"}}>For the benefit of students, we have tied up with Educom Smart Services Pvt Ltd for enhanced learning through audio visual teaching and learning.</p>
      <p style={{margin:"10px 10px 20px 24px",fontSize:"1.11rem"}}>Use Edusmart Science prior to, during, or following “hands-on” lab activities and instruction to:</p>
      <p style={{margin:"10px 10px 20px 24px",fontSize:"1.11rem"}}>Set the learning objective Assess and activate prior knowledge Provide support for English Language Learners (ELL) 
      Encourage cooperative learning Promote science discourse Summarize and take notes and Formally assess understanding.</p>

          </Grid>
          <Grid items xs={12} mg={12} lg={4} style={{height:"80vh",paddingTop:"40px"}}>
          <Demo />
          </Grid>
        </Grid>  
      {/* <div className={css.contentContainer}>
        <div className={css.content}>
            <div className={css.contentHolder}>
                <div className={css.contentText}>
                    For the benefit of students, we have tied up with Educom Smart Services Pvt Ltd for enhanced learning through audio visual teaching and learning.
                </div>
            </div>
            <div className={css.contentHolder}>
                <div className={css.contentText}>
                    Use Edusmart Science prior to, during, or following “hands-on” lab activities and instruction to:
                </div>
            </div>
            <div className={css.contentHolder}>
                <div className={css.contentText}>
                    Set the learning objective Assess and activate prior knowledge Provide support for English Language Learners (ELL) Encourage cooperative learning Promote science discourse Summarize and take notes and Formally assess understanding.
                </div>
            </div>
        </div>
        <div className={css.newsNoticeContainer}>
            <div className={css.newsNotice}>
                <Demo />
            </div>
        </div>
      </div> */}

      <Footer />
    </>
  );
}

export default EduSmart;