import React from "react";
import { Box, Card, Container, Grid, Typography, styled } from "@mui/material";
import dayjs from "dayjs";

const Item = styled(Box)(({ theme }) => ({
  color: "gray",
}));

const CustomCourse = ({ data }) => {
  return (
    <Container sx={{ marginTop: "25px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ order: { xs: 2, sm: 2, md: 1 } }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 700, color: "#F76D35", fontSize: "2rem" }}
          >
            {data.title}
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              marginBottom: "50px",
            }}
          >
            {data.note}
          </Typography>
          <Item>
            <Typography variant="subtitle1">
              Hosted By:
              <span style={{ color: "black" }}>{data.hostedBy}</span>
            </Typography>
            <Typography variant="subtitle1">
              Date:{" "}
              <span style={{ color: "black" }}>
                {dayjs(data.date).format("MMM DD, YYYY")}
              </span>
            </Typography>
            <Typography variant="subtitle1">
              Location: <span style={{ color: "black" }}> {data.location}</span>
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12} md={6} sx={{ order: { xs: 1, sm: 1, md: 2 } }}>
          <img
            src={data.image ? data.image.link : "awards.png"}
            alt="loading..."
            style={{ width: "100%", borderRadius: "5px" }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CustomCourse;
