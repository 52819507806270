import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Card,
  Grid,
  Typography,
  css,
  keyframes,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router";
import { get } from "../../services/apis";
import SettingContext from "../../context/SettingsContext";
import { urls as url } from "../../services/urlConstant";
import appendStringToUrls from "../../utils/appendString";
import NewsNoticeComponents from "./NewsNoticeComponents";
import style from "../../Components2/NewsAndNotice/newsmain.module.css";

export const newsData = [
  {
    id: 1,
    title: "SSLC Farewell",
    image:
      "https://i.pinimg.com/236x/0e/6e/27/0e6e271f040a6babe6ca66f1dff8bcb0.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores dolorem culpa, placeat officia labore saepe, voluptate harum accusamus atque perferendis nam eveniet omnis doloribus delectus! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime obcaecati animi enim voluptatibus, pariatur in eligendi repellat omnis necessitatibus? Delectus aut quos natus odio molestiae aspernatur, quasi quisquam odit itaque, quibusdam illum ea! Ad quasi quae tenetur, temporibus ab alias? Minima, excepturi recusandae? Ducimus laborum ipsum blanditiis aliquid ex ipsam ea, possimus suscipit, animi, ad dolor quas perferendis nobis odit? Sed, non! Temporibus, quae animi, at impedit laborum doloribus excepturi natus eveniet eaque sit dolores dolor mollitia asperiores ab, minima aut explicabo optio? Labore fugit consequatur aspernatur quam sapiente asperiores, impedit consequuntur neque quas beatae quibusdam doloribus, aperiam eligendi odit cum similique laboriosam. Alias, illum? Velit adipisci, molestiae cumque voluptatibus dicta maiores corporis error cum, repudiandae est iure recusandae sint, odio vel iste? Veritatis earum placeat animi magnam voluptate sapiente voluptatibus impedit, nostrum unde. Aspernatur, rerum voluptates eveniet, animi nostrum doloremque pariatur aliquam dolor ab fuga, nisi hic autem totam.",
  },
  {
    id: 2,
    title: "PU Farewell",
    image:
      "https://i.pinimg.com/236x/73/5f/07/735f071149815ccd57ee41798721d391.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores dolorem culpa, placeat officia labore saepe, voluptate harum accusamus atque perferendis nam eveniet omnis doloribus delectus! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime obcaecati animi enim voluptatibus, pariatur in eligendi repellat omnis necessitatibus? Delectus aut quos natus odio molestiae aspernatur, quasi quisquam odit itaque, quibusdam illum ea! Ad quasi quae tenetur, temporibus ab alias? Minima, excepturi recusandae? Ducimus laborum ipsum blanditiis aliquid ex ipsam ea, possimus suscipit, animi, ad dolor quas perferendis nobis odit? Sed, non! Temporibus, quae animi, at impedit laborum doloribus excepturi natus eveniet eaque sit dolores dolor mollitia asperiores ab, minima aut explicabo optio? Labore fugit consequatur aspernatur quam sapiente asperiores, impedit consequuntur neque quas beatae quibusdam doloribus, aperiam eligendi odit cum similique laboriosam. Alias, illum? Velit adipisci, molestiae cumque voluptatibus dicta maiores corporis error cum, repudiandae est iure recusandae sint, odio vel iste? Veritatis earum placeat animi magnam voluptate sapiente voluptatibus impedit, nostrum unde. Aspernatur, rerum voluptates eveniet, animi nostrum doloremque pariatur aliquam dolor ab fuga, nisi hic autem totam.",
  },
  {
    id: 3,
    title: "New Magazine Launch",
    image:
      "https://i.pinimg.com/236x/ba/b1/04/bab1045e2482f492f8c55078680d0551.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores dolorem culpa, placeat officia labore saepe, voluptate harum accusamus atque perferendis nam eveniet omnis doloribus delectus! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime obcaecati animi enim voluptatibus, pariatur in eligendi repellat omnis necessitatibus? Delectus aut quos natus odio molestiae aspernatur, quasi quisquam odit itaque, quibusdam illum ea! Ad quasi quae tenetur, temporibus ab alias? Minima, excepturi recusandae? Ducimus laborum ipsum blanditiis aliquid ex ipsam ea, possimus suscipit, animi, ad dolor quas perferendis nobis odit? Sed, non! Temporibus, quae animi, at impedit laborum doloribus excepturi natus eveniet eaque sit dolores dolor mollitia asperiores ab, minima aut explicabo optio? Labore fugit consequatur aspernatur quam sapiente asperiores, impedit consequuntur neque quas beatae quibusdam doloribus, aperiam eligendi odit cum similique laboriosam. Alias, illum? Velit adipisci, molestiae cumque voluptatibus dicta maiores corporis error cum, repudiandae est iure recusandae sint, odio vel iste? Veritatis earum placeat animi magnam voluptate sapiente voluptatibus impedit, nostrum unde. Aspernatur, rerum voluptates eveniet, animi nostrum doloremque pariatur aliquam dolor ab fuga, nisi hic autem totam.",
  },
  {
    id: 4,
    title: "Annual Day Events",
    image:
      "https://i.pinimg.com/236x/4e/47/14/4e47147dc016f601fea20e38ce9f3439.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores dolorem culpa, placeat officia labore saepe, voluptate harum accusamus atque perferendis nam eveniet omnis doloribus delectus! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime obcaecati animi enim voluptatibus, pariatur in eligendi repellat omnis necessitatibus? Delectus aut quos natus odio molestiae aspernatur, quasi quisquam odit itaque, quibusdam illum ea! Ad quasi quae tenetur, temporibus ab alias? Minima, excepturi recusandae? Ducimus laborum ipsum blanditiis aliquid ex ipsam ea, possimus suscipit, animi, ad dolor quas perferendis nobis odit? Sed, non! Temporibus, quae animi, at impedit laborum doloribus excepturi natus eveniet eaque sit dolores dolor mollitia asperiores ab, minima aut explicabo optio? Labore fugit consequatur aspernatur quam sapiente asperiores, impedit consequuntur neque quas beatae quibusdam doloribus, aperiam eligendi odit cum similique laboriosam. Alias, illum? Velit adipisci, molestiae cumque voluptatibus dicta maiores corporis error cum, repudiandae est iure recusandae sint, odio vel iste? Veritatis earum placeat animi magnam voluptate sapiente voluptatibus impedit, nostrum unde. Aspernatur, rerum voluptates eveniet, animi nostrum doloremque pariatur aliquam dolor ab fuga, nisi hic autem totam.",
  },
  {
    id: 5,
    title: "Republic Day Events",
    image:
      "https://i.pinimg.com/236x/4e/47/14/4e47147dc016f601fea20e38ce9f3439.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores dolorem culpa, placeat officia labore saepe, voluptate harum accusamus atque perferendis nam eveniet omnis doloribus delectus! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime obcaecati animi enim voluptatibus, pariatur in eligendi repellat omnis necessitatibus? Delectus aut quos natus odio molestiae aspernatur, quasi quisquam odit itaque, quibusdam illum ea! Ad quasi quae tenetur, temporibus ab alias? Minima, excepturi recusandae? Ducimus laborum ipsum blanditiis aliquid ex ipsam ea, possimus suscipit, animi, ad dolor quas perferendis nobis odit? Sed, non! Temporibus, quae animi, at impedit laborum doloribus excepturi natus eveniet eaque sit dolores dolor mollitia asperiores ab, minima aut explicabo optio? Labore fugit consequatur aspernatur quam sapiente asperiores, impedit consequuntur neque quas beatae quibusdam doloribus, aperiam eligendi odit cum similique laboriosam. Alias, illum? Velit adipisci, molestiae cumque voluptatibus dicta maiores corporis error cum, repudiandae est iure recusandae sint, odio vel iste? Veritatis earum placeat animi magnam voluptate sapiente voluptatibus impedit, nostrum unde. Aspernatur, rerum voluptates eveniet, animi nostrum doloremque pariatur aliquam dolor ab fuga, nisi hic autem totam.",
  },
  {
    id: 6,
    title: "Teachers Day Events",
    image:
      "https://i.pinimg.com/236x/4e/47/14/4e47147dc016f601fea20e38ce9f3439.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores dolorem culpa, placeat officia labore saepe, voluptate harum accusamus atque perferendis nam eveniet omnis doloribus delectus! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime obcaecati animi enim voluptatibus, pariatur in eligendi repellat omnis necessitatibus? Delectus aut quos natus odio molestiae aspernatur, quasi quisquam odit itaque, quibusdam illum ea! Ad quasi quae tenetur, temporibus ab alias? Minima, excepturi recusandae? Ducimus laborum ipsum blanditiis aliquid ex ipsam ea, possimus suscipit, animi, ad dolor quas perferendis nobis odit? Sed, non! Temporibus, quae animi, at impedit laborum doloribus excepturi natus eveniet eaque sit dolores dolor mollitia asperiores ab, minima aut explicabo optio? Labore fugit consequatur aspernatur quam sapiente asperiores, impedit consequuntur neque quas beatae quibusdam doloribus, aperiam eligendi odit cum similique laboriosam. Alias, illum? Velit adipisci, molestiae cumque voluptatibus dicta maiores corporis error cum, repudiandae est iure recusandae sint, odio vel iste? Veritatis earum placeat animi magnam voluptate sapiente voluptatibus impedit, nostrum unde. Aspernatur, rerum voluptates eveniet, animi nostrum doloremque pariatur aliquam dolor ab fuga, nisi hic autem totam.",
  },
  {
    id: 7,
    title: "Children Day Events",
    image:
      "https://i.pinimg.com/236x/4e/47/14/4e47147dc016f601fea20e38ce9f3439.jpg",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores dolorem culpa, placeat officia labore saepe, voluptate harum accusamus atque perferendis nam eveniet omnis doloribus delectus! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime obcaecati animi enim voluptatibus, pariatur in eligendi repellat omnis necessitatibus? Delectus aut quos natus odio molestiae aspernatur, quasi quisquam odit itaque, quibusdam illum ea! Ad quasi quae tenetur, temporibus ab alias? Minima, excepturi recusandae? Ducimus laborum ipsum blanditiis aliquid ex ipsam ea, possimus suscipit, animi, ad dolor quas perferendis nobis odit? Sed, non! Temporibus, quae animi, at impedit laborum doloribus excepturi natus eveniet eaque sit dolores dolor mollitia asperiores ab, minima aut explicabo optio? Labore fugit consequatur aspernatur quam sapiente asperiores, impedit consequuntur neque quas beatae quibusdam doloribus, aperiam eligendi odit cum similique laboriosam. Alias, illum? Velit adipisci, molestiae cumque voluptatibus dicta maiores corporis error cum, repudiandae est iure recusandae sint, odio vel iste? Veritatis earum placeat animi magnam voluptate sapiente voluptatibus impedit, nostrum unde. Aspernatur, rerum voluptates eveniet, animi nostrum doloremque pariatur aliquam dolor ab fuga, nisi hic autem totam.",
  },
];

const ContainerWithScroll = styled(Box)`
  overflow: auto;
`;

const ScrollContent = styled(Box)`
  animation: scrollAnimation 30s linear infinite;
  animation-play-state: running;
  &:hover {
    animation-play-state: paused;
  }

  @keyframes scrollAnimation {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
`;

const TypographyMain = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  color: "#f86f03",
  fontWeight: "bold",
  textShadow: "10px 8px 8px #969c96",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },

  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    margin: 0,
    padding: "0",
  },

  [theme.breakpoints.down(600)]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down(500)]: {
    fontSize: "15px",
  },
}));

const NewsCardMain = () => {
  const [contentHeight, setContentHeight] = useState(0);
  const scrollContentRef = useRef(null);

  const { selectedSetting } = useContext(SettingContext);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);
  let [announceNews, setAnounceNews] = useState([]);

  useEffect(() => {
    const getAllNews = async () => {
      try {
        const response = await get(urls.announceNews.getNewsHome);
        setAnounceNews(response.data.data);
        console.log("news", response.data.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    getAllNews();
  }, []);
  return (
    <Box mx={7}>
      <Card
        sx={{
          display: "flex",
          marginTop: "40px",
          border: "2px solid #ffffff",
          boxShadow: "6px 5px 25px rgba(0,0,0,0.08)",
          marginTop: "8rem",
          borderRadius: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "25%",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TypographyMain
              sx={{ width: "max-content", fontWeight: 700 }}
              variant="h3"
            >
              News & <b style={{ color: "black" }}>Notice</b>
            </TypographyMain>
          </Box>
        </Box>

        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "250px",
            overflowY: "auto",
            justifyContent: "center",
          }}
          component="div"
          className={style.newscontainer}
        >
          {announceNews.length ? (
            <div className={style.newsscroll}>
              {announceNews.map((elem, index) => {
                return (
                  <React.Fragment key={index}>
                    <NewsNoticeComponents elem={elem} />
                  </React.Fragment>
                );
              })}
            </div>
          ) : (
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
                maxWidth: "900px",
              }}
            >
              No News/Events to show at the moment!
            </Typography>
          )}
          {/* </ScrollContent> */}
          {/* </ContainerWithScroll> */}
        </Box>
      </Card>
    </Box>
  );
};

export default NewsCardMain;
