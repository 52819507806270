import React from "react";
import Footer from "../Footer/Footer";
import Navbar from "../navbar/Navbar";
import { Grid } from "@mui/material";
import "../../styles/components/PrincipleDesk/PrincipleDesk.scss";
const OurSchool = () => {
  return (
    <>
      <Navbar />
      <Grid container spacing={12} marginTop={"50px"}>
        {/* <div style={{display:"flex",justifyContent:"space-between", flexWrap:"wrap","margin-top":"-150px"}}> */}
        {/* <div style={{padding:"15px"}}> */}
        <Grid items xs={12} mg={12} lg={5} marginLeft={"130px"}>
          <h5
            style={{
              margin: "10px 10px 10px -10px",
              textDecoration: "underline",
              fontWeight: "700",
            }}
          >
            OUR MISSION:
          </h5>
          <p
            style={{
              margin: "10px 10px 10px -10px",
              fontSize: "1.11rem",
              textAlign: "justify",
            }}
          >
            The word Excellent has been the talk of the town,Vijayapura and
            North Karnataka. It has been rendering great educational service for
            the last 19 years, creating many milestones in the field of
            education including selection of highest number of students every
            year to pace setting schools like Navodaya/Sainik/Kittur etc.
            Presently more than 4000 students are studying in the institution
            under the able guidance of dedicated and experienced management,
            highly determined, workaholic teaching and non-teaching faculty. It
            is the only institution where management is bent to work 24×7 to
            train young minds to excel in modern, dynamic world. Excellent is
            known for framing diverse co-curricular and extra-curricular
            activities for development of wholesome personality of a child. We
            feel proud that many of our students are playing vital role in
            society serving as doctors, engineers, defense personal,
            entrepreneurs, career advisers etc. For the benefit of students, we
            have tied up with Educom Smart Services Pvt Ltd for enhanced
            learning through audio visual teaching and learning.
          </p>
          {/* </div> */}
        </Grid>
        <Grid items xs={10} mg={5} lg={5}>
          <img className="content-image" src="principal.jpg" alt="" />
        </Grid>
        {/* </div> */}
      </Grid>
      <Footer />
    </>
  );
};

export default OurSchool;
