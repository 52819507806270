import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Footer from "../Footer/Footer";
import Navbar from "../navbar/Navbar";
import css from "../Addmission.module.css";
import { Link, NavLink } from "react-router-dom";
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
  Card,
  Tooltip,
  TextField,
} from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Faculty() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Navbar />

      <div className={css.headerContainer}>
        <div>
          <h1>FACULTY</h1>
          <h5>
            <Link
              style={{
                color: "white",
              }}
              to="/"
            >
              Home
            </Link>{" "}
            / FACULTY
          </h5>
        </div>
      </div>
      <div style={{ padding: "15x" }}>
        <Box margin={"0px 10px 0px 10px "}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="PRIMARY SECTION" {...a11yProps(0)} />
              <Tab label="HIGH SCHOOL SECTION" {...a11yProps(1)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "800px",
                marginLeft: "400px",
              }}
            >
              <h6> Sri.S.B.Hegaladi (H.M)</h6> <h6>M.A. B.Ed</h6>
              <h6>8050472651</h6>
            </div>

            <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
              DEPARTMENT OF ENGLISH
            </h3>
            <Table size="small">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.S.B.Hegaladi (H.M){" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8050472651
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri B M.Hadapad{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.A B.Ed.
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    7259844936
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri S.I. Mathapati{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.A B.Ed.
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8861884287
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    4
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt. K R Donur{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9606346373
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    5
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.Rehana Managoli{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9986237959
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    6
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.S.I.Biradar{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8310006009
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    7
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.R.R.Kadam
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8660664251
                  </TableCell>
                </TableRow>
              </TableBody>
              <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                DEPARTMENT OF KANNADA
              </h3>
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri P.B. Biradar{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9844771899
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.R.P.Hadapad{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    7349031995
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri Y.S Pujari{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9620110146
                  </TableCell>
                </TableRow>
              </TableBody>
              <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                DEPARTMENT OF HINDI
              </h3>
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt. C. S Muruge{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9739606960
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.Vijayalaksmi Managuli{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8217832505
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.R.S.Hoogar
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8105575567
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.Asha Gayakwad{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9945922962
                  </TableCell>
                </TableRow>
              </TableBody>
              <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                DEPARTMENT OF MATHS
              </h3>
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.A.B.Chavan
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9886221719
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri M.M. Mathapati
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.Sc B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9731459446
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.B.K.Joshi
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.Sc B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    7483067986
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    4
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.A.N.Yaranal{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.E
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9591308228
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    5
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Miss.S.J.Girigoudar{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.Sc B.ED{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8197399630
                  </TableCell>
                </TableRow>
              </TableBody>
              <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                DEPARTMENT OF SCIENCE
              </h3>
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Miss.G.V.Mathapati
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.Sc B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    7353401658
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.S.C.Rathod
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.Sc B.ED
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9741673546
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.S.S.Suryavanshi
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.Sc. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8296858582
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    4
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.I.V.Muragad
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.Sc B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    7975714669
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    5
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.Afsha anjum Inamadar
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.Sc B.ED
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9844000682
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    6
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Miss Priya V Patil
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.Sc B.ED
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    7406344019
                  </TableCell>
                </TableRow>
              </TableBody>
              <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                DEPARTMENT OF SOCIALSCIENCE
              </h3>
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.S.S.Tolanur
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    7353897997
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.C.D.Vibhutimath
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8217429268
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.P.R.Savadatti
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8105140803
                  </TableCell>
                </TableRow>
              </TableBody>
              <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                DEPARTMENT OF PHE
              </h3>
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.S.S.Upadhye
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.P.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    7619298656
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri S.N. Alamel
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.P.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9686736101
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.Sushmita.V.A
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.P.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    7411676729
                  </TableCell>
                </TableRow>
              </TableBody>

              <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                Department of Computer
              </h3>
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Miss.Bhagyashree Savalagi
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    BCA
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8971574281
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "800px",
                marginLeft: "400px",
              }}
            >
              <h6> Sri.R.V.Moratagi (H.M)</h6> <h6>M.A. B.Ed</h6>
              <h6>6364567556</h6>
            </div>
            <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
              DEPARTMENT OF ENGLISH
            </h3>
            <Table size="small">
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.R.V.Moratagi (H.M){" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    6364567556
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.R.V.Deshpande{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9986441504
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.S.D.Hunkunte{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9632316912
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    4
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.V.B.Utnal{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9945401143
                  </TableCell>
                </TableRow>
              </TableBody>
              {/* <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    6
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    V.D.Bagali
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    6361303566
                  </TableCell>
                </TableRow> */}

              <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                DEPARTMENT OF HINDI
              </h3>
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.S.B. Pawar{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9964156792
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.T.A. Mulla{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9741837966
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.V.P. Vaishampayan{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.A. B.Ed{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9035361153{" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    4
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt. P. M. Hiremath{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    7337679062{" "}
                  </TableCell>
                </TableRow>
              </TableBody>
              <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                DEPARTMENT OF KANNADA
              </h3>
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri. S.B. Allawagol{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8762680143
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri. V.Y. Dalawai{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    6362942760
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.B.S. Hatti{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8748009721
                  </TableCell>
                </TableRow>
              </TableBody>
              <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                DEPARTMENT OF MATHS
              </h3>
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.I.M. Hittinahalli{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.Sc B.ED{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9035068058
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.S.M.Sunkad{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.Sc B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    7676269058
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.Sandeep.M.Paragond{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.Sc B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9632221829
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    4
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.I.B.Ganigar{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.Sc B.ED
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    7975291416
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    5
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.D.B. Patil
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.E
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    7406344019
                  </TableCell>
                </TableRow>
              </TableBody>
              <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                DEPARTMENT OF SCIENCE
              </h3>
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri Gopal kulkarni{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.Sc B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    7619179072{" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.B.R.Math{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.Sc B.Ed.{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8884182044
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.M B Chappalagavi{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.Sc B.Ed.{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9986509882
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    4
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.Afreen Jamadar{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.Sc B.ED{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8971108876
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    5
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.J. S. Hadimur{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.Sc B.ED{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9632455736
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    6
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.Roopa.M.G{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.Sc B.ED
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    --
                  </TableCell>
                </TableRow>
              </TableBody>
              <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                DEPARTMENT OF SOCIALSCIENCE
              </h3>
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.A.P. Korti{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.A B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9480780760
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.S S Hoogar{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9916445446
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.A J Jamadar{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9743332600
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    4
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.Panduranga.K{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9611792827
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    5
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.C S Devanal{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B A B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8317397769
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    6
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Miss.J.A. Kandagal{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A. B.Ed
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8867920170
                  </TableCell>
                </TableRow>
              </TableBody>
              <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                DEPARTMENT OF PHE
              </h3>
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.D.M.Shinde{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A.BPED{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9945852599
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    2
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Smt.S.S.Tambake{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.PED & M.PED{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8050757287
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    3
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.K.B. Harijan{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    B.A.BPED{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9591714245
                  </TableCell>
                </TableRow>
              </TableBody>

              <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                Department of Computer{" "}
              </h3>
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.R.N.Kattimani{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.Com PGDCA{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    8792961328
                  </TableCell>
                </TableRow>
              </TableBody>

              <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>
                Department of Drawing.Art{" "}
              </h3>
              <TableHead style={{ background: "rgb(27 55 121)" }}>
                <TableRow>
                  <TableCell align="center">
                    <span className="class-table-header">#SL</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Teacher Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Qualification</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="class-table-header">Mobile No</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    1
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    Sri.P.S.Managuli{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    M.V.A{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    9686323448
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>
        </Box>
      </div>
      <Footer />
    </div>
  );
}
