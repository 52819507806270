import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Person } from "@mui/icons-material";
import css from "./styles/Topnav.module.css";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import { Container, Divider, Grid, styled } from "@mui/material";
import SettingContext from "../context/SettingsContext";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const TopNav = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const { settings, selectedSetting, setSelectedSetting } =
    React.useContext(SettingContext);

  const [selectedOption, setSelectedOption] = React.useState(
    selectedSetting ? selectedSetting.schoolName : "ABC School"
  );
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option) => {
    setSelectedSetting(option);
    handleClose();
  };

  return (
    <Box sx={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }}>
      <Container maxWidth="xl" sx={{ backgroundColor: "rgba(0, 0, 0, 0.87)" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" },
            gap: "20px",
            padding: "10px 30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                borderRight: " 0.5px solid white",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <MailOutlineIcon
                fontSize="12px"
                sx={{ ml: 1, mr: 1 }}
                className={css.secHalf}
              />

              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  fontFamily: "monospace",
                  fontWeight: 10,
                  fontSize: 15,
                  color: "white",
                  textDecoration: "none",
                  mr: 2,
                }}
                className={css.firstHalf}
              >
                {selectedSetting ? selectedSetting.email : "abc@gmail.com"}
              </Typography>
            </Box>

            <Divider flexItem orientation="vertical" />

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PhoneIcon
                color="white"
                fontSize="12px"
                sx={{ ml: 1, mr: 1 }}
                className={css.secHalf}
              />

              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  fontFamily: "monospace",
                  fontWeight: 10,
                  fontSize: 15,
                  color: "white",
                  textDecoration: "none",
                }}
                className={css.firstHalf}
              >
                +91 {selectedSetting ? selectedSetting.phone : 9999999999}
              </Typography>
            </Box>
          </Box>

          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <Box>
              <Button
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                variant="contained"
                sx={{ color: "" }}
                size="medium"
                endIcon={<KeyboardArrowDownIcon />}
              >
                {selectedSetting.schoolName}
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                sx={{ marginTop: "3%" }}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {settings
                  .filter((s) => s.active)
                  .map((s) => {
                    return (
                      <MenuItem
                        key={s._id}
                        onClick={() => handleOptionSelect(s)}
                      >
                        {s.schoolName}
                      </MenuItem>
                    );
                  })}
              </Menu>
            </Box>
            <Box>
              <Button
                startIcon={<Person sx={{ color: "#ffff" }} />}
                onClick={() => navigate("/login")}
                variant="contained"
                sx={{
                  color: "white",
                  background: "#F86F03",
                  "&:hover": { backgroundColor: "#F86F03" },
                }}
              >
                Login
              </Button>
            </Box>
          </Box> */}
        </Box>
      </Container>
    </Box>
  );
};

export default TopNav;
