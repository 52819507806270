import React from "react";
import { Box, Container, Grid, Typography, styled } from "@mui/material";
import image from "../../assets/Images/founderrsn.png";

import president from "../../assets/Images/president.png";
import secretary from "../../assets/Images/secretary.png";
import director from "../../assets/Images/FounderRns.png";

import treasurer from "../../assets/Images/treasurer.png";

import { Link } from "react-router-dom";
import NavBar from "../navbar/Navbar";
import Footer from "../Footer/Footer";
import TopNav from "../navbar/TopNav";

const ImageContainer = styled(Grid)(({ theme }) => ({
  boxShadow: "10px 5px 10px 5px #969c96",

  padding: "15px",
  width: "65%",
  textAlign: "center",
  marginLeft: "20%",
  [theme.breakpoints.down("md")]: {
    marginBottom: "15px",
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: "15px",
    width: "60%",
  },
}));

const ImageContainerSubImage = styled(Box)(({ theme }) => ({
  borderShadow: "10px 20px #0000",
  textAlign: "center",

  img: {
    height: "150px",

    objectFit: "cover",
    borderRadius: "8px",
  },
}));

const AboutTitle = styled(Box)(({ theme }) => ({
  // color: "#F86F03",
  color: "white",
  padding: "80px",
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url(/HeaderImage.png)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
  },
}));

const FounderTitle = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));

const Role = styled(Typography)(({ theme }) => ({
  align: "center",
  fontWeight: "bold",
  fontSize: "18px",
}));

const Name = styled(Typography)(({ theme }) => ({
  align: "center",
  fontSize: "16px",
  marginTop: "10px",
}));

const FounderName = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const AboutContainer = styled(Box)(({ theme }) => ({
  padding: "20px",
  [theme.breakpoints.down("md")]: {
    padding: "15px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
  },
}));

const AboutFounder = () => {
  return (
    <React.Fragment>
      <TopNav />
      <NavBar />
      <AboutTitle>
        <FounderTitle>About Founder</FounderTitle>
        <Typography sx={{ color: "white" }}>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            <Typography
              variant="span"
              sx={{
                color: "white",
                textTransform: "uppercase",
                "&:hover": {
                  color: "#F86F03",
                },
              }}
            >
              HOME
            </Typography>
          </Link>
          &nbsp; / About
        </Typography>
      </AboutTitle>

      <AboutContainer>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Box textAlign="center" padding="20px">
              <Typography
                sx={{
                  textShadow: "1px 1px 1px #F86F03",
                  color: "#1b61ff",
                  fontSize: "1.2rem",
                }}
              >
                <b>
                  {" "}
                  {"\u201C"}
                  The end-product of education should be a free creative man,
                  who can battle against historical circumstances and
                  adversities of nature.{"\u201D"}
                </b>
              </Typography>
              <Typography variant="body2" color="#F86F03">
                - Dr. Sarvepalli Radhakrishnan
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ImageContainer>
              <Role>Founder President</Role>
              <img
                src={image}
                alt=""
                style={{
                  // maxHeight: "100%",
                  height: "100%",
                  maxWidth: "100%",
                }}
              />
              <FounderName align="center" sx={{ pt: "2px", fontWeight: 700 }}>
                Mr. H M Bharatesh
              </FounderName>
            </ImageContainer>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Typography
              style={{
                fontFamily: "arial",
                textAlign: "start",
              }}
              className="font-sans"
            >
              <b>
                <b style={{ color: "#30A2FF" }}>"</b> Mr. H M Bharatesh,{" "}
              </b>
              established{" "}
              <b>
                Raja Somashekara Nayaka English Medium Higher Primary School{" "}
              </b>{" "}
              with a singular vision: to create a nurturing environment where
              students can excel academically and grow into responsible,
              compassionate, and innovative individuals. With a rich background
              in education and a deep commitment to fostering holistic
              development, <b>Mr. H M Bharatesh</b> has dedicated their life to
              shaping the future of young minds.
              <br />
              <br />
              Under <b>Mr. H M Bharatesh</b> leadership,{" "}
              <b>
                Raja Somashekara Nayaka English Medium Higher Primary School{" "}
              </b>{" "}
              has achieved numerous milestones. The school is recognized for its
              innovative teaching methods, outstanding faculty, and exceptional
              student performance in both academics and extracurricular
              activities.
              <b>Mr. H M Bharatesh</b> has also been instrumental in developing
              programs that support students' mental and emotional well-being,
              ensuring a balanced approach to education.
              <br />
              <br />
              <b>Mr. H M Bharatesh</b> believes that education is the most
              powerful tool to change the world. They are a firm advocate for
              lifelong learning and encourage both students and staff to
              continually seek knowledge and personal growth. Their dedication
              to education is not just professional but deeply personal, driven
              by the belief that every child deserves the opportunity to reach
              their full potential.
              <b style={{ color: "#30A2FF" }}>"</b>
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} mt={3}>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <ImageContainerSubImage>
              <Role>President</Role>
              <img src={president} alt="" />
              <Name>Mr.RajaShekhar M</Name>
            </ImageContainerSubImage>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <ImageContainerSubImage>
              <Role>Secretary</Role>
              <img src={secretary} alt="" />
              <Name>Mr.Sadyojathaiah S M</Name>
            </ImageContainerSubImage>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <ImageContainerSubImage>
              <Role>Treasurer</Role>
              <img src={treasurer} alt="" />
              <Name>Dr.Anantha Shetty</Name>
            </ImageContainerSubImage>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <ImageContainerSubImage>
              <Role>Director</Role>
              <img src={director} alt="" />
              <Name>Mr.H.M.Jagadeesh</Name>
            </ImageContainerSubImage>
          </Grid>
        </Grid>
      </AboutContainer>

      <Footer />
    </React.Fragment>
  );
};

export default AboutFounder;
