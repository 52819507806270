import Axios from "axios";
import { toast } from "react-toastify";
const excludePath = [
  "/view-assignment",
  "/home-notice",
  "/home-notice/",
  `/home-news`,
  "/pre-admission",
  "/home-news",
  "/home-careers",
  "/to-the-knowledge-of-parents",
  "/unique-features",
  "/home-gallery",
  "/results",
  "/cbse-mandatory",

  "/contact-us",
  "/facilities/edusmart",
  "/facilities/transport",
  "/",
  "/forgot-password",
  "/principal",
  "/desk",
  "/facality",
  "/addmission",
  "/our-school",
  "/academics",
  "/academics/primary",
  "/academics/high-school",
  "/facilities/library",
  "/facilities/sports",
  "/facilities/canteen",
  "/facilities/labs",
  "/toppers-list",
  "/ourachievement/",
  "/pre-admission/exam-conduct",
  "/details/pre-admission/",
];

const BASE_URL = process.env.REACT_APP_BASE_URL;
export const instance = Axios.create({
  baseURL: BASE_URL,
  // baseURL: "http://localhost:3003/api",
});
//

instance.interceptors.request.use(
  function (config) {
    const token = window.localStorage.getItem(process.env.REACT_APP_TOKEN);
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    toast.error(error.response.data.message);
    return Promise.reject(error);
  }
);

//

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.headers["refresh-token"]) {
      window.localStorage.setItem(
        process.env.REACT_APP_TOKEN,
        response.headers["refresh-token"]
      );
    }
    const url = response.config.url;
    if (
      ["delete", "put", "post"].includes(response.config.method) &&
      !url.includes("absent") &&
      !url.includes("present")
    ) {
      toast.success(response.data.msg || response.data.message);
    }
    return response;
  },

  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      if (
        error.response.data.message == "jwt expired" ||
        error.response.data.message == "invalid token"
      ) {
        window.localStorage.removeItem(process.env.REACT_APP_TOKEN);
        window.location.href = "/login";
      }
      if (
        !excludePath.filter(
          (path) =>
            path.match(window.location.pathname) ||
            path.startsWith(window.location.pathname)
        ).length &&
        window.localStorage.getItem(process.env.REACT_APP_TOKEN) &&
        error.response.data.message !== "settings not created"
      ) {
        toast.error(error.response.data.message);
      } else {
        window.localStorage.removeItem(process.env.REACT_APP_TOKEN);

        window.location.href = "/login";
      }
      return Promise.reject(error);
    } else {
      toast.error(error.response.data.message);
    }
  }
);
