import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import MainNav from "../../NavbarComponents/MainNav";
import css from "./newsmain.module.css";
import TopNav from "../../NavbarComponents/TopNav";

import image from "../../assets/Images/news.jpg";
import dayjs from "dayjs";

const OuterBox = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url(${image})`,
  backgroundRepeat: "no-repeat",
  width: "100%",
  backgroundSize: "100% 100%",
  paddingTop: "150px",
}));
const CardImage = styled(CardMedia)(({ theme }) => ({
  width: "100%",
  height: "100%",

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CustomDetails = ({ elem }) => {
  console.log(elem, "wasdgfhjk");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <TopNav />
      <MainNav />
      <OuterBox>
        <Typography
          variant="h4"
          color="#F86F03"
          textAlign="center"
          fontWeight="700">
          News
        </Typography>
        <Container>
          <Card
            elevation={1}
            sx={{
              padding: "15px",
              borderRadius: "5px",
            }}>
            {" "}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CardImage
                  component="img"
                  height="100%"
                  image={elem.image.link}
                  alt=""
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <CardContent>
                  {" "}
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: { xs: "center", sm: "center" },
                      fontWeight: 700,

                      color: "orange",
                    }}>
                    {elem.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "justify", marginTop: "5%" }}>
                    {elem.shortNews}
                  </Typography>{" "}
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#616161",
                      textAlign: "justify",
                      marginTop: "2%",
                    }}>
                    {elem.news}
                  </Typography>{" "}
                </CardContent>
              </Grid>
              <Grid item={12} sm={12} md={12} lg={12}></Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 15px",
              }}>
              <Typography fontWeight={600}>
                Date:{dayjs(elem.data).format("DD-MM-YYYY")}
              </Typography>

              <Button
                variant="outlined"
                size="small"
                onClick={() => navigate("/")}>
                Go Back
              </Button>
            </Box>
          </Card>
        </Container>
        <Footer />
      </OuterBox>
    </>
  );
};
export default CustomDetails;
