import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import css from "./style.module.css";
// import CourseData from './CourseData'
// import img from '../../assets/images/imgCource1.jpg'
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { FaComment } from "react-icons/fa";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import awardsImg from "../../assets/Images/awards.png";

const Cource = ({ coursedata }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/courseDetails/${coursedata._id}`);
  };

  return (
    <Box className={css.mainBox}>
      <Card
        sx={{
          maxWidth: 345,
          marginBottom: "50px",
          boxShadow: (theme) => theme.shadows[2],
        }}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="260"
            image={coursedata.image ? coursedata.image.link : "awards.png"}
            alt="green iguana"
          />
          <CardContent sx={{ height: 100 }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#F86F03",
                textAlign: "center",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
            >
              {coursedata.title}
            </Typography>
            <Typography
              varient="body2"
              fontSize={16}
              color="text.secondary"
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {coursedata.note}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button onClick={handleNavigate} color="warning" size="small">
            View Details
          </Button>
        </CardActions>
      </Card>
      {/* <Box
        sx={{
          backgroundColor: "black",
          overflow: "hidden",
          borderRadius: "5px",
        }}
      >
        <img
          className={css.mainImage}
          src={coursedata.image ? coursedata.image.link : "awards.png"}
          style={{
            height: "35vh",
            width: "100%",
            borderRadius: "5px",
            overflow: "hidden",
            backgroundSize: "contain",
          }}
          alt=""
        />
      </Box> */}
      {/* <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
            color: "#F86F03",
            textAlign: "center",
            padding: "10px 2px 0",
          }}
        >
          {coursedata.title}
        </Typography>
        <Typography
          varient="body2"
          fontSize={16}
          sx={{ padding: "10px 2px 10px" }}
        >
          {coursedata.note ? coursedata.note.substring(0, 150) + "..." : ""}
        </Typography>
      </Box> */}
    </Box>
  );
};

export default Cource;
