import { eventdetails } from "./EventsDetails";
import { Link, useLocation, useParams } from "react-router-dom";
import { CardComponent } from "./Events";
import { Box, Container, Typography, createTheme } from "@mui/material";
import styled from "@emotion/styled";
import style from "./Events.module.css";
import { ThemeProvider } from "@emotion/react";
import CustomComponent from "./CustomComponent";

import moment from "moment";
import Footer from "../Footer";
import NavBar from "../../NavBar";
import MainNav from "../../NavbarComponents/MainNav";
import TopNav from "../../NavbarComponents/TopNav";

const theme = createTheme();

const Heading = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "2rem",
  textTransform: "uppercase",
  fontFamily: "sans-serif",
  fontWeight: "bold",
}));

const Heading1 = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
}));

const Headin = styled(Link)(({ theme }) => ({
  color: "#FFFFFF",
  fontSize: "1rem",
  fontFamily: "sans-serif",
  fontWeight: "bold",
  textDecoration: "none",
}));

const BoxSize = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "#fff",
  marginTop: "30px",
  borderRadius: "5px",
  paddingLeft: 0,
}));

const OuterBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)),url(https://images.unsplash.com/photo-1523580494863-6f3031224c94?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
  backgroundRepeat: "no-repeat",
  width: "100%",
  backgroundSize: "100% 100%",
  paddingTop: "176px",
}));

const EventInfo = () => {
  const { id } = useParams();
  const { state } = useLocation();

  console.log("state events ff", state);

  return (
    <>
      <TopNav />
      <MainNav />
      <OuterBox>
        {/* <Link to="/" style={{ textDecoration: "none", color: "white" }}>
          Go Back
        </Link> */}
        <Typography
          variant="h4"
          color="#F86F03"
          textAlign="center"
          fontWeight="700"
        >
          OUR EVENTS
        </Typography>
        <Container>
          <ThemeProvider theme={theme}>
            <BoxSize padding={{ xs: "15px", md: "20px" }}>
              <CustomComponent elem={state} />
            </BoxSize>
          </ThemeProvider>
        </Container>
        <Footer />
      </OuterBox>
    </>
  );
};
export default EventInfo;
