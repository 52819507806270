
import React from 'react';
import css from "../NavbarComponents/styles/waves.module.css";

import { useState } from 'react';
import { Box } from '@mui/material';

const CircularWaves = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Box className={css.circular1} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Box className={css.circular2} ></Box>
      <Box className={css.circular3}></Box>
      <Box className={css.circular4}></Box>
      

    </Box>
  );
};

export default CircularWaves;
