// import React from "react";
// import "../../styles/components/NewsNotice/NewsNotice.scss";

// import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import { useState, useEffect } from "react";
// import { urls } from "../../services/urlConstant";
// import { get } from "../../services/apis";
// import dayjs from "dayjs";
// import { Grid } from "@mui/material";
// import { Link } from "react-router-dom";

// const Demo = () => {
//   const [announceNotice, setAnounceNotice] = useState([]);

//   useEffect(() => {
//     const getNotice = async () => {
//       try {
//         const response = await get(`${urls.announceNotice.getNotice}/home`,{
//           params:{
//             search:{
//               webView:true
//             }
//           }
//         });
//         const getNews = await get(`${urls.announceNews.getNews}/home`,{
//           params:{
//             search:{
//               webView:'yes'
//             }
//           }
//         })
//         setAnounceNotice([...response.data.data,...getNews.data.data]);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getNotice();
//   }, []);

//   const eventContent = [
//     {
//       img: "BdayImg2.jpg",
//       title: "shrikant",
//       content: "hi good afternoon",
//     },
//     {
//       img: "BdayImg4.jpg",
//       title: "shrikant",
//       content: "hi good afternoon",
//     },
//     {
//       img: "BdayImg3.jpg",
//       title: "Naveen",
//       content: "hi good afternoon",
//     },
//     {
//       img: "award.jpg",
//       title: "Sai",
//       content: "hi good afternoon",
//     },
//     {
//       img: "BdayImg2.jpg",
//       title: "shrikant",
//       content: "hi good afternoon",
//     },
//     {
//       img: "BdayImg4.jpg",
//       title: "shrikant",
//       content: "hi good afternoon",
//     },
//   ];
//   const responsive = {
//     superLargeDesktop: {
//       // the naming can be any, depends on you.
//       breakpoint: { max: 4000, min: 3000 },
//       items: 1,
//     },
//     desktop: {
//       breakpoint: { max: 3000, min: 1024 },
//       items: 1,
//     },
//     tablet: {
//       breakpoint: { max: 1024, min: 464 },
//       items: 2,
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 1,
//     },
//   };
//   return (
//     <div className="news-and-notice-conatiner">
//       <div className="news-awards">
//         <div>
//           <div>
//             <h1>
//               <span style={{ color: "#f07b47" }}> NEWS </span>AND
//               <span style={{ color: "#f07b47" }}> NOTICE </span>
//               <div className="all-line-3">
//                 <div className="first-line"></div>
//                 <div className="middle-line"></div>
//                 <div className="first-line last-line"></div>
//               </div>
//             </h1>
//           </div>
//         </div>
//         <div style={{ padding: "100px 100px 100px 200px" }}>
//           <Grid container spacing={12}>
//             <Grid items xs={12} mg={6} lg={12} style={{marginLeft:"-100px"}}>
//               <div className="news-container" style={{height:"400px",width:"400px"}}>
//                 <div className="scroll-container" >
//                   {announceNotice.map((news) => (
//                     <Link style={{color:'inherit',textDecoration:'none'}} to={news.news ? `/home-news/${news._id}` : `/home-notice/${news._id}`}>
//                       <div className="scroll-text">
//                         <div className="news-content">
//                           <div className="news-date-month">
//                             <div className="date">{dayjs(news.date).format('DD-MMM')}</div>
//                           </div>
//                           <div className="title-discription">
//                             <div>
//                               <h4>{news.title}</h4>
//                             </div>
//                             <div className="discription" style={{display:"flex"}}>{news.notice || news.news}</div>
//                           </div>
//                         </div>
//                       </div>
//                     </Link>
//                   ))}
//                 </div>
//               </div>
//             </Grid>
//           </Grid>
//         </div>
//       </div>
//       {/* <div className="achievents" item lg={12}>
//         <div>
//           <h1>
//             <span style={{ color: "#f07b47" }}>AWARDS </span> AND
//             <span style={{ color: "#f07b47" }}> ACHIEVEMENTS</span>
//             <div className="all-line-4">
//               <div className="first-line"></div>
//               <div className="middle-line"></div>
//               <div className="first-line last-line"></div>
//             </div>
//           </h1>
//         </div> */}
//         {/* <div style={{ margin: "15px 10px", padding: "50px 20px" }}>
//           <Grid container spacing={2}>
//             <Grid items xs={12} mg={6} lg={12}>
//               <div className="achievements-conatiner">
//                 <Carousel responsive={responsive}>
//                   {eventContent.map((e) => (
//                     <div>
//                       <Card sx={{ maxWidth: 345 }}>
//                         <CardMedia
//                           component="img" */}
//         {/* //                   height="200"
//         //                   image={e.img}
//         //                   alt="Latest Event"
//         //                 />
//         //                 <CardContent>
//         //                   <Typography gutterBottom variant="h5" component="div">
//         //                     {e.title}
//         //                   </Typography>
//         //                   <Typography variant="body2" color="text.secondary">
//         //                     {e.content}
//         //                   </Typography>
//         //                 </CardContent>
//         //                 <CardActions>
//         //                   <Button size="small">Read more</Button>
//         //                 </CardActions>
//         //               </Card>
//         //             </div> */}
//         {/* //           ))}
//         //         </Carousel>
//         //       </div> */}
//         {/* //     </Grid>
//         //   </Grid>
//         // </div> */}
//       </div>

//   );
// };

// export default Demo;

import React from "react";
import "../../styles/components/NewsNotice/NewsNotice.scss";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState, useEffect } from "react";
import { urls } from "../../services/urlConstant";
import { get } from "../../services/apis";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

const Demo = () => {
  const [announceNotice, setAnounceNotice] = useState([]);
  const [awards, setAwards] = useState([]);

  useEffect(() => {
    const getNotice = async () => {
      try {
        const response = await get(`${urls.announceNotice.getNotice}/home`, {
          params: {
            search: {
              webView: true,
            },
          },
        });
        const getNews = await get(`${urls.announceNews.getNews}/home`, {
          params: {
            search: {
              webView: "yes",
            },
          },
        });
        setAnounceNotice([...response.data.data, ...getNews.data.data]);
        const getAwards = await get(`${urls.award.getAwardList}`, {
          params: {
            search: {
              webView: "yes",
            },
          },
        });
        if (getAwards.data.success) {
          console.log();
          setAwards(getAwards.data.result.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getNotice();
  }, []);

  const eventContent = [
    {
      img: "BdayImg2.jpg",
      title: "shrikant",
      content: "hi good afternoon",
    },
    {
      img: "BdayImg4.jpg",
      title: "shrikant",
      content: "hi good afternoon",
    },
    {
      img: "BdayImg3.jpg",
      title: "Naveen",
      content: "hi good afternoon",
    },
    {
      img: "award.jpg",
      title: "Sai",
      content: "hi good afternoon",
    },
    {
      img: "BdayImg2.jpg",
      title: "shrikant",
      content: "hi good afternoon",
    },
    {
      img: "BdayImg4.jpg",
      title: "shrikant",
      content: "hi good afternoon",
    },
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      paritialVisibilityGutter: 120,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="news-and-notice-conatiner">
      <div className="news-awards">
        <div>
          <div>
            <h1>
              <span style={{ color: "#f07b47" }}> NEWS </span>AND
              <span style={{ color: "#f07b47" }}> NOTICE </span>
              <div className="all-line-3">
                <div className="first-line"></div>
                <div className="middle-line"></div>
                <div className="first-line last-line"></div>
              </div>
            </h1>
          </div>
        </div>
        <div style={{ padding: "100px 100px" }}>
          <Grid container spacing={12}>
            <Grid items xs={12} mg={6} lg={12}>
              <div
                className="news-container1"
                style={{ height: "400px", width: "380px" }}
              >
                <div className="scroll-container1">
                  {announceNotice.map((news) => (
                    <Link
                      style={{ color: "inherit", textDecoration: "none" }}
                      to={
                        news.news
                          ? `/home-news/${news._id}`
                          : `/home-notice/${news._id}`
                      }
                    >
                      <div className="scroll-text1">
                        <div className="news-content1">
                          <div className="news-date-month1">
                            <div className="date">
                              {dayjs(news.date).format("DD")}
                            </div>
                            <div className="month">
                              {dayjs(news.date).format("MMM")}
                            </div>
                          </div>
                          <div className="title-discription1">
                            <div style={{ height: "30px", overflow: "hidden" }}>
                              <h4>{news.title}</h4>
                            </div>
                            <div className="discription1">
                              {news.notice || news.news}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Demo;
