import React, { useState, useEffect } from "react";
import { Link,NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from './Addmission.module.css'
import TopNav from "./navbar/TopNav";
import Footer from "./Footer/Footer";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";

const Primary = () => {
    const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  const menuItems = [
    {
      title: "Home",
      children: [],
    },
    {
      title: "About",
      children: [
        {
          title: "Our School",
          link: "/our-school",
        },
        {
          title: "Principal Desk",
          link: "/principal-desk",
        },
        {
          title: "Faculty",
          link: "/faculty",
        },
      ],
    },
    {
      title: "Gallery",
      children: [],
    },
    {
      title: "Academics",
      children: [],
    },
    {
      title: "Facilities",
      children: [
        {
          title: "Library",
          link: "/library",
        },
        {
          title: "Sports",
          link: "/sports",
        },
        {
          title: "Canteen",
          link: "/canteen",
        },
        {
          title: "Transport",
          link: "/transport",
        },
        {
          title: "Edusmart",
          link: "/Edusmart",
        },
      ],
    },
    {
      title: "Jobs",
      children: [],
    },
    {
      title: "Exam",
      children: [
        {
          title: "Exam Time Table",
          link: "/birth-day",
        },
        {
          title: "Hall Ticket",
          link: "/annual-day",
        },
        {
          title: "Results",
          link: "/freshers-day",
        },
      ],
    },
    {
      title: "Contact Us",
      children: [],
    },
  ];

  return (
    <>
        <TopNav />
        <Navbar />
      <div className={css.headerContainer}>
      <div>
                    <h1>PRIMARY</h1>
                    <h5><Link style={{
                        color:'white'
                    }} to='/'>Home</Link> / PRIMARY</h5>
                </div>
      </div>
      <Grid container spacing={12}marginLeft={"-76px"}>
        <Grid items xs={12} mg={12} lg={8} style={ {"margin":"40px 0px 20px 0px","border-right":"1px solid #BDBDBD"}}>
    
          <p style={{margin:"0px 10px 20px 24px",fontSize:"1.11rem"}}>The purpose of primary education is to prepare children physically, emotionally, socially and mentally to enter high school. It is here that the foundation for the love of learning is first formed and strengthened.</p>
          <p style={{margin:"10px 10px 20px 24px",fontSize:"1.11rem"}}> Objectives for primary include to:</p>     
          <ul  style={{margin:"-5px 10px 10px 24px",fontSize:"1.11rem"}}>
          <li>Enhance the physical development of children.</li>
                        <li>Train children to follow instructions of the teacher.</li>
                        <li>Encourage children’s imagination and creativity.</li>
                        <li>Train children to be disciplined.</li>
                        <li>Facilitate the learning of different movements.</li>
                        <li>Create an environment for children to be happy and enjoy themselves.</li>
                        <li>Train children in memorizing.</li>
                    </ul> 
         <p style={{margin:"10px 10px 20px 24px",fontSize:"1.11rem"}}>  The importance of early childhood education is now a well recognized fact, that children need enriched environment, intellectual stimulation and plenty of opportunity for socialization of the same age group. Various techniques for teaching are adopted in a playable manner which involves clay modeling, painting, alphabets on toys, number games etc. Bridging the gap between the nurturing environment of home and the disciplined demands of formal education is the primary goal of the school.</p>     
        
             </Grid>
             <Grid items xs={12} mg={6} lg={4} style={{height:"80vh",paddingTop:"40px"}}>
                <Demo />
                </Grid>
          </Grid>
      {/* <div className={css.contentContainer}>
            <div className={css.content}>
            <div className={css.contentHolder}>
                    <div className={css.contentText}>
                    The purpose of primary education is to prepare children physically, emotionally, socially and mentally to enter high school. It is here that the foundation for the love of learning is first formed and strengthened.
                    </div>
                </div>
                <div className={css.contentHolder}>
                    <div className={css.contentText}>
                    Objectives for primary include to:
                    </div>
                </div>
                <div className={css.contentHolder}>
                    <ul className={css.contentText}>
                        <li>Enhance the physical development of children.</li>
                        <li>Train children to follow instructions of the teacher.</li>
                        <li>Encourage children’s imagination and creativity.</li>
                        <li>Train children to be disciplined.</li>
                        <li>Facilitate the learning of different movements.</li>
                        <li>Create an environment for children to be happy and enjoy themselves.</li>
                        <li>Train children in memorizing.</li>
                    </ul>
                </div>
                <div className={css.contentHolder}>
                    <div className={css.contentText}>
                    The importance of early childhood education is now a well recognized fact, that children need enriched environment, intellectual stimulation and plenty of opportunity for socialization of the same age group. Various techniques for teaching are adopted in a playable manner which involves clay modeling, painting, alphabets on toys, number games etc. Bridging the gap between the nurturing environment of home and the disciplined demands of formal education is the primary goal of the school.
                    </div>
                </div>
            </div>
            <div className={css.newsNoticeContainer}>
            <div className={css.newsNotice}>
                <Demo />
            </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
}

export default Primary;