import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Tooltip,
  Container,
  Grid,
  TablePagination,
} from "@mui/material";
import { display } from "@mui/system";
import { get, post, put, del } from "../../../services/apis";
import { urls as url } from "../../../services/urlConstant";
import { Clear, Delete, Edit } from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import SettingContext from "../../../context/SettingsContext";
import appendStringToUrls from "../../../utils/appendString";
import SectionSubHeader from "../../SectionSubHeader";
import Wrapper from "../../Wrapper";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import ShowComponent from "../../ShowComponent";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddGalleryForm = styled(Box)(({ theme }) => ({
  width: "800px",
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80%",
  },
}));

const GalleryTitle = styled(TextField)(({ theme }) => ({
  width: "400px",
  margin: "10px",
  [theme.breakpoints.down(700)]: {
    width: "250px",
  },
  [theme.breakpoints.down(600)]: {
    width: "200px",
  },
  [theme.breakpoints.down(350)]: {
    width: "150px",
  },
}));

const AddDate = styled(TextField)(({ theme }) => ({
  width: "400px",
  margin: "10px",
  [theme.breakpoints.down(700)]: {
    width: "250px",
  },
  [theme.breakpoints.down(600)]: {
    width: "200px",
  },
  [theme.breakpoints.down(350)]: {
    width: "150px",
  },
}));

const AddNote = styled(TextareaAutosize)(({ theme }) => ({
  width: "400px",
  margin: "10px",
  height: "100px",
  padding: "5px",
  [theme.breakpoints.down(700)]: {
    width: "250px",
  },
  [theme.breakpoints.down(600)]: {
    width: "200px",
  },
  [theme.breakpoints.down(350)]: {
    width: "150px",
  },
}));

const WebViewSelect = styled(Select)(({ theme }) => ({
  width: "400px",
  [theme.breakpoints.down(700)]: {
    width: "250px",
  },
  [theme.breakpoints.down(600)]: {
    width: "200px",
  },
  [theme.breakpoints.down(350)]: {
    width: "150px",
  },
}));

const ChooseFile = styled(Box)(({ theme }) => ({
  width: "400px",
  margin: "10px",
  [theme.breakpoints.down(700)]: {
    width: "250px",
  },
  [theme.breakpoints.down(600)]: {
    width: "200px",
  },
  [theme.breakpoints.down(350)]: {
    width: "150px",
    fontSize: "12px",
  },
}));

const Gallery = (props) => {
  const { selectedSetting } = useContext(SettingContext);
  const [value, setValue] = React.useState(0);
  const [formData, setFormDate] = useState({});
  const [images, setImages] = useState([]);
  const [editImages, setEditImages] = useState([]);
  const [galleries, setGalleries] = useState([]);
  const [editGallery, setEditGallery] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [date, setDate] = useState(dayjs());
  const [editDate, setEditDate] = useState(dayjs());
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [editLoading, setEditLoading] = React.useState(false);
  const [sort, setSort] = useState({
    title: "asc",
    webView: "asc",
  });

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortByTitle = () => {
    console.log("Title");
    if (sort.title === "asc") {
      let sortedList = [
        ...galleries.sort((a, b) => b.title.localeCompare(a.title)),
      ];

      setGalleries(sortedList);
      setSort({ ...sort, title: "des" });
    } else {
      let sortedList = [
        ...galleries.sort((a, b) => a.title.localeCompare(b.title)),
      ];
      setGalleries(sortedList);
      setSort({ ...sort, title: "asc" });
    }
  };

  const sortByWebView = () => {
    console.log("WebView");
    if (sort.webView === "asc") {
      let sortedList = [
        ...galleries.sort((a, b) => b.webView.localeCompare(a.webView)),
      ];

      setGalleries(sortedList);
      setSort({ ...sort, webView: "des" });
    } else {
      let sortedList = [
        ...galleries.sort((a, b) => a.webView.localeCompare(b.webView)),
      ];
      setGalleries(sortedList);
      setSort({ ...sort, webView: "asc" });
    }
  };

  const handleClearEditImg = async (id) => {
    const editedImage = { ...editGallery };
    editedImage.images = editedImage.images.filter((img) => img._id !== id);
    setEditGallery(editedImage);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue !== 2) {
      setShowEdit(false);
    }
  };
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormDate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditGallery((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const formStyle = {
    width: "400px",
    margin: "10px",
  };

  useEffect(() => {
    if (value !== 1) {
      setValue(0);
      setShowEdit(false);
    }

    const getGalleries = async () => {
      const res = await get(`${urls.gallery.getAllGalleries}`, {
        headers: { roleFunction: "gallery" },
      });
      setGalleries(res.data.data);
    };
    getGalleries();
  }, [selectedSetting._id]);

  const handleImages = (e) => {
    setImages((prev) => [...prev, ...e.target.files]);
  };
  const handleImagesEdit = (e) => {
    setEditImages((prev) => [...prev, ...e.target.files]);
  };

  // create new gallery or update the previous gallery
  const handleGallerySubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    setEditLoading(true);
    try {
      if (!showEdit) {
        let imageIds = [];
        if (!images.length) {
          toast.error("Please add at least one image to create gallery!");
          setLoader(false);
        }

        const formImage = new FormData();
        if (images.length > 0) {
          for (const img of images) {
            formImage.append("files", img);
          }
          const res = await post(urls.fileUpload.postFileUpload, formImage, {
            headers: { roleFunction: "gallery" },
          });
          imageIds = res.data.result.map((id) => id._id);
          const galleryData = {
            ...formData,
            images: imageIds,
            date: dayjs(date).format("YYYY-MM-DD"),
          };
          await post(`${urls.gallery.createGallery}`, galleryData, {
            headers: { roleFunction: "gallery" },
          });
          const getUpdated = await get(`${urls.gallery.getAllGalleries}`, {
            headers: { roleFunction: "gallery" },
          });
          setGalleries(getUpdated.data.data);
          setValue(0);
          setFormDate({});
          setImages([]);
        }
      } else {
        let imageIds = [];
        const formImage = new FormData();
        if (editImages.length > 0) {
          for (const img of editImages) {
            formImage.append("files", img);
          }
          const res = await post(urls.fileUpload.postFileUpload, formImage, {
            headers: { roleFunction: "gallery" },
          });
          imageIds = res.data.result.map((id) => id._id);
        }
        const galleryData = {
          ...editGallery,
          date: dayjs(editDate).format("YYYY-MM-DD"),
          images: [...editGallery.images, ...imageIds],
        };
        await put(
          `${urls.gallery.updateGallery}/${editGallery._id}`,
          "",
          galleryData,
          { headers: { roleFunction: "gallery" } }
        );
        const getUpdated = await get(`${urls.gallery.getAllGalleries}`, {
          headers: { roleFunction: "gallery" },
        });
        setGalleries(getUpdated.data.data);
        setShowEdit(false);
        setValue(0);
        setEditImages([]);
        setEditGallery({});
      }
      setLoader(false);
      setEditLoading(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
      setEditLoading(false);
    }
  };

  // edit images
  const removeImage = (index) => {
    setImages(images.filter((img, pos) => pos !== index));
  };
  const removeImageEdit = (index) => {
    setEditImages(editImages.filter((img, pos) => pos !== index));
  };

  // edit gallery , change tabs
  const handleEdit = (id) => {
    const editGallery = galleries.find((gallery) => gallery._id === id);
    setEditGallery(editGallery);
    setEditDate(dayjs(editGallery.date));
    setShowEdit(true);
    setValue(2);
  };

  // delete gallery
  const handleGalleryDel = async (id) => {
    try {
      const delGallery = await del(
        `${urls.gallery.deleteGallery}/${id}`,
        "",
        {},
        { roleFunction: "gallery" }
      );
      if (delGallery.data.success) {
        setGalleries(galleries.filter((gallery) => gallery._id !== id));
      }
    } catch (error) {}
  };
  return (
    <Wrapper>
      <SectionSubHeader title="Gallery" />
      <Box sx={{ borderBottom: 1, borderColor: "divider", margin: "15px 0" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Gallery List" {...a11yProps(0)} />
          <Tab label="Add Gallery" {...a11yProps(1)} />
          {showEdit && <Tab label="Update gallery" {...a11yProps(2)} />}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ backgroundColor: "#1b3779", color: "white" }}>
              <TableRow sx={{ color: "white" }}>
                <TableCell align="center" sx={{ color: "inherit" }}>
                  #Sl
                </TableCell>
                <TableCell align="center" sx={{ color: "inherit" }}>
                  <span> Gallery Title</span>
                  <span
                    onClick={sortByTitle}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.title === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center" sx={{ color: "inherit" }}>
                  <span> Is view on web</span>
                  <span
                    onClick={sortByWebView}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    {sort.webView === "asc" ? "▲" : "▼"}
                  </span>
                </TableCell>
                <TableCell align="center" sx={{ color: "inherit" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {galleries
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((gallery, index) => (
                  <TableRow key={gallery._id}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{gallery.title}</TableCell>
                    <TableCell align="center">{gallery.webView}</TableCell>
                    <TableCell align="center">
                      <div className="edit-delete">
                        <ShowComponent roleFunction="gallery" action="update">
                          <Tooltip title="Update" disableInteractive>
                            <IconButton
                              size="small"
                              sx={{ color: "#1b3779" }}
                              onClick={() => handleEdit(gallery._id)}
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>
                        <ShowComponent roleFunction="gallery" action="delete">
                          <Tooltip title="Delete" disableInteractive>
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => setDeleteModal(gallery._id)}
                            >
                              <Delete color="error" fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ShowComponent>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {galleries.length === 0 && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "5px", padding: "5px" }}
            >
              No data found
            </Typography>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={galleries.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            my: 1,
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Paper sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                padding: 2,
              }}
            >
              <form onSubmit={handleGallerySubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      size="small"
                      id="outlined-basic"
                      label="Gallery Title"
                      variant="outlined"
                      name="title"
                      required
                      value={formData.title || ""}
                      onChange={handleFormChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Date"
                        inputFormat="DD-MM-YYYY"
                        value={date}
                        onChange={(val) => setDate(val)}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            fullWidth
                            required
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        Web View
                      </InputLabel>
                      <Select
                        size="small"
                        name="webView"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.webView || ""}
                        label="Web View"
                        onChange={handleFormChange}
                        fullWidth
                      >
                        <MenuItem value={"yes"}>Yes</MenuItem>
                        <MenuItem value={"no"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextareaAutosize
                      style={{
                        height: "100px",
                        borderRadius: "5px",
                        width: "100%",
                        padding: 10,
                      }}
                      fullWidth
                      size="small"
                      placeholder="Type note here..."
                      name="note"
                      required
                      value={formData.note || ""}
                      onChange={handleFormChange}
                      // fullWidth
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "5px",
                      marginTop: "5px",
                    }}
                  >
                    <input
                      type="file"
                      name="image"
                      accept="image/*"
                      multiple
                      onChange={handleImages}
                    />

                    {images.map((image, index) => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid lightgray",
                          borderRadius: "5px",
                          padding: "2px",
                          mt: 1,
                        }}
                        key={index}
                      >
                        {image.name}
                        <IconButton
                          size="small"
                          onClick={() => removeImage(index)}
                        >
                          <Clear />
                        </IconButton>
                      </Box>
                    ))}
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} mt={1}>
                    <Button
                      onClick={() => {
                        setShowEdit(false);
                        setValue(0);
                      }}
                      size="small"
                      variant="contained"
                      color="error"
                    >
                      Cancel
                    </Button>
                    <ShowComponent roleFunction="gallery" action="add">
                      <LoadingButton
                        type="submit"
                        size="small"
                        variant="contained"
                        loading={loader}
                        sx={{
                          ml: 2,
                          background: "#1b3779",
                          "&:hover": {
                            background: "#1b3779",
                          },
                        }}
                      >
                        Submit
                      </LoadingButton>
                    </ShowComponent>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Paper>
      </TabPanel>
      {showEdit && (
        <TabPanel value={value} index={2}>
          <Paper sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
                  padding: 2,
                }}
              >
                <form onSubmit={handleGallerySubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        size="small"
                        id="outlined-basic"
                        label="Gallery Title"
                        variant="outlined"
                        name="title"
                        required
                        value={editGallery.title || ""}
                        onChange={handleEditFormChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          label="Date"
                          inputFormat="MM/DD/YYYY"
                          value={editDate}
                          onChange={(val) => setEditDate(val)}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              fullWidth
                              required
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Web View
                        </InputLabel>
                        <Select
                          name="webView"
                          // sx={{ width: 400 }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={editGallery.webView || ""}
                          label="Web View"
                          onChange={handleEditFormChange}
                        >
                          <MenuItem value={"yes"}>Yes</MenuItem>
                          <MenuItem value={"no"}>No</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextareaAutosize
                        style={{
                          height: "100px",
                          borderRadius: "5px",
                          width: "100%",
                          padding: 10,
                        }}
                        size="small"
                        placeholder="Note"
                        name="note"
                        required
                        value={editGallery.note || ""}
                        onChange={handleEditFormChange}
                        fullWidth
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <input
                        type="file"
                        name="image"
                        accept="image/*"
                        multiple
                        onChange={handleImagesEdit}
                      />

                      {editImages.map((image, index) => (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid lightgray",
                            borderRadius: "5px",
                            padding: "2px",
                          }}
                          key={index}
                        >
                          {image.name}
                          <IconButton onClick={() => removeImageEdit(index)}>
                            <Clear />
                          </IconButton>
                        </Box>
                      ))}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{ display: "flex" }}
                    >
                      {editGallery.images &&
                        editGallery.images.map((img) => (
                          <Grid item xs={6} sm={6} md={1} lg={1}>
                            <img
                              width="50"
                              height="50"
                              src={`${img.link}`}
                              alt=""
                              style={{
                                borderRadius: "5px",
                                objectFit: "contain",
                              }}
                            />

                            <IconButton
                              size="small"
                              onClick={() => handleClearEditImg(img._id)}
                            >
                              <Clear />
                            </IconButton>
                          </Grid>
                        ))}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      display="flex"
                      // justifyContent="center"
                      mt={1}
                    >
                      <Button
                        onClick={() => {
                          setShowEdit(false);
                          setValue(0);
                        }}
                        size="small"
                        variant="contained"
                        color="error"
                      >
                        Cancel
                      </Button>
                      <ShowComponent roleFunction="gallery" action="update">
                        <LoadingButton
                          type="submit"
                          size="small"
                          variant="contained"
                          loading={editLoading}
                          sx={{
                            ml: 2,
                            background: "#1b3779",
                            "&:hover": {
                              background: "#1b3779",
                            },
                          }}
                        >
                          Update
                        </LoadingButton>
                      </ShowComponent>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Box>
          </Paper>
        </TabPanel>
      )}
    </Wrapper>
  );
};

export default Gallery;
