import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./../styles/components/navbar/Navbar.scss";
import { Language } from "@mui/icons-material";
import "./../styles/components/navbar/NavTop.scss";
import css from './Addmission.module.css'
import Footer from "./Footer/Footer";
import TopNav from "./navbar/TopNav";
import Navbar from "./navbar/Navbar";
import Demo from "./NewsNotic/Demo";
import { get } from "../services/apis";
import { urls } from "../services/urlConstant";
import { toast } from "react-toastify";
import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import dayjs from "dayjs";

const HomeNews = () => {
    const [addClass, updateClass] = useState(false);
  const [stkyClass, setStkyClass] = useState(false);
  const [mega, setMega] = useState(false);
  const [news,setNews] = useState([]);

  const handleClickCross = () => {
    updateClass(false);
    window.scrollTo(0, 0);
  };
  const scrollHandleScroll = () => {
    if (window.scrollY > 20) {
      setStkyClass(true);
    } else {
      setStkyClass(false);
    }
  };
  const handleMega = () => {
    setMega(!mega);
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandleScroll);
  }, []);

  useEffect(() => {
    const getNews = async () => {
        try {
            const news = await get(urls.announceNews.getNews);
            setNews(news.data.data);
        } catch (error) {
            toast.error(error.message)
        }
    }
    getNews();
  },[])

  return (
    <>
        <TopNav />
        <Navbar />
      <div className={css.headerContainer}>
                <div>
                    <h1>News Archives</h1>
                    <h5><Link style={{
                        color:'white'
                    }} to='/'>Home</Link> / News</h5>
                </div>
      </div>
      <div className={css.contentContainer}>
        <div style={{
            margin:'1rem 2rem',
            display:'flex',
            flexWrap:'wrap',
            gap:'1.5rem'
        }}>
            {news.map((ele) => (
            <Card key={ele._id} sx={{minWidth:275}}>
                <CardContent>
                    <Typography variant="h5">
                        {ele.title}
                    </Typography>
                    <p>
                        {ele.shortNews}
                    </p>
                    <p>
                        {ele.news}
                    </p>
                    <div style={{fontWeight:'bold',fontSize:'12px'}}>{dayjs(ele.date).format('MMMM DD, YYYY')}</div>
                </CardContent>
                <CardActions>
                    <Link style={{color:'inherit',textDecoration:'none'}} to={`/home-news/${ele._id}`}>
                      <Button size="small">Read More</Button>
                    </Link>
                </CardActions>
            </Card>
            ))}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default HomeNews;