import React, { useContext } from "react";
import {
  Box,
  Container,
  Typography,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import TopNav from "../navbar/TopNav";
import NavBar from "../navbar/Navbar";
import Footer from "../Footer/Footer";
import SettingContext from "../../context/SettingsContext";

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "15px",
  lineHeight: "1.7px",
  textAlign: "center",
  marginBottom: "40px",
}));

const TableTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "15px",
  lineHeight: "1.7px",
  textAlign: "left",
  textDecoration: "underline",
  marginBottom: "10px",
}));

const TableTitleResult = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "15px",
  lineHeight: "1.7px",
  textAlign: "left",

  marginBottom: "10px",
}));

const generalData = [
  {
    slNo: 1,
    information: "NAME OF THE SCHOOL",
    details: "RAJA SOMASHEKARA NAKAYA ENGLISH MEDIUM PRIMARY SCHOOL",
  },
  {
    slNo: 2,
    information: "AFFILIATION NO.(IF APPLICABLE)",
    details: "",
  },
  {
    slNo: 3,
    information: "SCHOOL CODE (IF APPLICABLE)",
    details: "",
  },

  {
    slNo: 4,
    information: "COMPLETE ADDRESS WITH PIN CODE",
    details:
      "Hadagali road, Near Canara bank, Harapanahalli.Vijayanagar, Karnataka - 583131",
  },
  {
    slNo: 5,
    information: "PRINCIPAL NAME",
    details: "Mr. KASHINATHA N",
  },

  {
    slNo: 6,
    information: "PRINCIPAL QUALIFICATION",
    details: "MA BED",
  },

  {
    slNo: 7,
    information: "SCHOOL EMAIL ID",
    // details: "rajasomashekarnayakaschool1973@gmail.com",
    details: (
      <a href="mailto:rajasomashekarnayakaschool1973@gmail.com">
        rajasomashekarnayakaschool1973@gmail.com
      </a>
    ),
  },
  {
    slNo: 8,
    information: "CONTACT DETAILS (LANDLINE/MOBILE)",
    details: "9535970960, 9538584894",
  },
];

const documentData = [
  {
    slNo: 1,
    information:
      "COPIES OF AFFILIATION/UPGRADATIO LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY",
    details: "RAJA SOMASHEKARA NAKAYA ENGLISH MEDIUM PRIMARY SCHOOL",
    path: "",
  },
  {
    slNo: 2,
    information:
      "COPIES OF SOCIETIES/TRUST/COMPANY REGISTRATION/RENEWAL CERTIFICATE, AS APPLICABLE",
    details: "",
    path: "/db2.pdf",
  },
  {
    slNo: 3,
    information:
      "COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF APPLICABLE, BY THE STATE GOVT./UT",
    details: "",
    path: "/db3.pdf",
  },

  {
    slNo: 4,
    information:
      "COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009, AND IT’S RENEWAL IF APPLICABLE",
    details: "Vijayanagara (Dist), Harapanahalli, Karnataka –583 131",
    path: "/db4.pdf",
  },
  {
    slNo: 5,
    information:
      "COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE",
    details: "Shri. Kashinatha.N",
    path: "/db5.pdf",
  },

  {
    slNo: 6,
    information:
      "COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY",
    details: "MA BEd",
    path: "/db6.pdf",
  },

  {
    slNo: 7,
    information:
      "COPY OF THE SELF CERTIFICATION SUBMITTED BY THE SCHOOL FOR AFFILIATION/UPGRADATION/EXTENSION OF AFFILIATION",
    details: "SCHOOL EMAIL ID",
    path: "/db7.pdf",
  },
  {
    slNo: 8,
    information: "COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES",
    details: "9535970960, 9538584894",
    path: "/db8.pdf",
  },
];

const resultData = [
  {
    slNo: 1,
    information: "FEE STRUCTURE OF THE SCHOOL",
    details: "RAJA SOMASHEKARA NAKAYA ENGLISH MEDIUM PRIMARY SCHOOL",
    path: "/dc1.pdf",
  },
  {
    slNo: 2,
    information: "ANNUAL ACADEMIC CALENDER",
    details: "",
    path: "/dc2.pdf",
  },
  {
    slNo: 3,
    information: "LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)",
    details: "",
    path: "/dc3.pdf",
  },

  {
    slNo: 4,
    information: "LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS",
    details: "Vijayanagara (Dist), Harapanahalli, Karnataka –583 131",
    path: "/dc4.pdf",
  },
  {
    slNo: 5,
    information:
      "LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER APPLICABILITY",
    details: "Shri. Kashinatha.N",
  },
];

const schoolnfa = [
  {
    slNo: 1,
    information: "TOTAL CAMPUS AREA OF THE SCHOOL (IN SQMTR)",
    details: "6128.89",
  },
  {
    slNo: 2,
    information: "NO. AND SIZE OF THE CLASS ROOMS (IN SQ MTR) ",
    details: "28 & 41 Sq mtr",
  },
  {
    slNo: 3,
    information:
      "NO. AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS (IN SQ MTR)",
    details: "3 & 50 Sq mtr",
  },

  {
    slNo: 4,
    information: "INTERNET FACILITY INTERNET FACILITY ",
    details: "YES",
  },
  {
    slNo: 5,
    information: "NO. OF GIRLS TOILETS",
    details: "12",
  },
  {
    slNo: 6,
    information: "NO. OF BOYS TOILETS",
    details: "12",
  },
  {
    slNo: 7,
    information:
      "LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING THE INFRASTRUCTURE OF THE SCHOOL",
    details: "",
  },
];

const staffData = [
  {
    slNo: 1,
    information: "PRINCIPAL",
    details: "Mr. KASHINATHA N",
  },
  {
    slNo: 2,
    information: "TOTAL NO. OF TEACHERS",
    details: "15",

    course: [
      { courseName: "PGT", value: "02" },
      { courseName: "TGT", value: "07" },
      { courseName: "PRT", value: "06" },
    ],
  },
  {
    slNo: 3,
    information: "TEACHERS SECTION RATIO ",
    details: "30:1",
  },

  {
    slNo: 4,
    information: "DETAILS OF SPECIAL EDUCATOR ",
    details: "1",
  },
  {
    slNo: 5,
    information: "DETAILS OF COUNSELLOR AND WELLNESS TEACHER",
    details: "1",
  },
];

const dataNew = [
  {
    slNo: 1,
    year: "2024",
    register: "20",
    passed: "20",
    percenatage: "100%",
    remarks: "Nil",
  },
  {
    slNo: 2,
    year: "2024",
    register: "80",
    passed: "40",
    percenatage: "80%",
    remarks: "Nil",
  },
  {
    slNo: 3,
    year: "2024",
    register: "70",
    passed: "50",
    percenatage: "70%",
    remarks: "Nil",
  },
];

const datatable = [
  {
    slNo: 1,
    information: "NAME OF THE SCHOOL",
    details: "",
  },
  {
    slNo: 2,
    information: "AFFILIATION NO.(IF APPLICABLE)",
    details: "30",

    course: [
      { courseName: "PGT", value: "10" },
      { courseName: "TGT", value: "10" },
      { courseName: "PRT", value: "10" },
    ],
  },
  {
    slNo: 3,
    information: "SCHOOL CODE (IF APPLICABLE)",
    details: "50",
  },
];

const CbscMandatory = () => {
  const { selectedSetting } = useContext(SettingContext);

  return (
    <>
      <TopNav />
      <NavBar />
      <Container sx={{ marginTop: "10vh" }}>
        <Title>MANDATORY PUBLIC DISCLOSURE</Title>
        <TableTitle>A: GENERAL INFORMATION:</TableTitle>

        <TableContainer component={Paper} sx={{ marginBottom: "40px" }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: "#1f92da",
                color: "white",
                fontSize: "14px",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  S.NO.
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  INFORMATION
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  DETAILS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {generalData.map((row) => (
                <TableRow key={row.slNo}>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    {row.slNo}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    {row.information}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    {row.details}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TableTitle>B: DOCUMENTS AND INFORMATION:</TableTitle>
        <TableContainer component={Paper} sx={{ marginBottom: "40px" }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: "#1f92da",
                color: "white",
                fontSize: "14px",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  S.NO.
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  DOCUMENTS/INFORMATION
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  UPLOAD DOCUMENTS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentData.map((row) => (
                <TableRow key={row.slNo}>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    {row.slNo}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    {row.information}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #ddd",
                      color: "#1f92da",
                      cursor: "pointer",
                      ":hover": { color: "black" },
                    }}
                  >
                    <Button
                      size="small"
                      disabled={!row.path}
                      onClick={() => window.open(row.path, "_blank")}
                      component="label"
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TableTitle>C: RESULT AND ACADEMICS:</TableTitle>
        <TableContainer component={Paper} sx={{ marginBottom: "40px" }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: "#1f92da",
                color: "white",
                fontSize: "14px",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  S.NO.
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  DOCUMENTS/INFORMATION
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  UPLOAD DOCUMENTS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resultData.map((row) => (
                <TableRow key={row.slNo}>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    {row.slNo}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    {row.information}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #ddd",
                      color: "#1f92da",
                      cursor: "pointer",
                      ":hover": { color: "black" },
                    }}
                  >
                    <Button
                      size="small"
                      disabled={!row.path}
                      onClick={() => window.open(row.path, "_blank")}
                      component="label"
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TableTitleResult>RESULT CLASS: X</TableTitleResult>
        <TableContainer component={Paper} sx={{ marginBottom: "40px" }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: "#1f92da",
                color: "white",
                fontSize: "14px",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  S.NO.
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  YEAR
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  NO. OF REGISTERED STUDENTS
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  NO. OF STUDENTS PASSED
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  PASS PERCENTAGE
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  REMARKS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ border: "1px solid #ddd" }}>1</TableCell>
                <TableCell sx={{ border: "1px solid #ddd" }}></TableCell>
                <TableCell sx={{ border: "1px solid #ddd" }}></TableCell>
                <TableCell sx={{ border: "1px solid #ddd" }}></TableCell>
                <TableCell sx={{ border: "1px solid #ddd" }}></TableCell>
                <TableCell sx={{ border: "1px solid #ddd" }}></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableTitleResult>RESULT CLASS: XII</TableTitleResult>
        <TableContainer component={Paper} sx={{ marginBottom: "40px" }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: "#1f92da",
                color: "white",
                fontSize: "14px",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  S.NO.
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  YEAR
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  NO. OF REGISTERED STUDENTS
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  NO. OF STUDENTS PASSED
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  PASS PERCENTAGE
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  REMARKS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ border: "1px solid #ddd" }}>1</TableCell>
                <TableCell sx={{ border: "1px solid #ddd" }}></TableCell>
                <TableCell sx={{ border: "1px solid #ddd" }}></TableCell>
                <TableCell sx={{ border: "1px solid #ddd" }}></TableCell>
                <TableCell sx={{ border: "1px solid #ddd" }}></TableCell>
                <TableCell sx={{ border: "1px solid #ddd" }}></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableTitle>D: STAFF (TEACHING)</TableTitle>

        <TableContainer component={Paper} sx={{ marginBottom: "40px" }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: "#1f92da",
                color: "white",
                fontSize: "14px",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  S.NO.
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  INFORMATION
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  DETAILS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {staffData.map((row) => (
                <TableRow key={row.slNo}>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    {row.slNo}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    {row.information}

                    <>
                      {row?.course?.map((course, index) => (
                        <Box
                          key={index}
                          sx={{
                            border: "1px solid #ddd",

                            padding: "5px",
                          }}
                        >
                          {course.courseName}
                        </Box>
                      ))}
                    </>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    {row.details}
                    <>
                      {row?.course?.map((course, index) => (
                        <Box
                          key={index}
                          sx={{
                            border: "1px solid #ddd",

                            padding: "5px",
                          }}
                        >
                          {course.value}
                        </Box>
                      ))}
                    </>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TableTitle>E: SCHOOL INFRASTRUCTURE:</TableTitle>

        <TableContainer component={Paper} sx={{ marginBottom: "30px" }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: "#1f92da",
                color: "white",
                fontSize: "14px",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  S.NO.
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  INFORMATION
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  DETAILS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schoolnfa.map((row) => (
                <TableRow key={row.slNo}>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    {row.slNo}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    {row.information}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    {row.details}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Footer />
    </>
  );
};

export default CbscMandatory;
