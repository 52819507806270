import React from "react";
import { Link } from "react-router-dom";
import "../../styles/components/About.scss";
import { useState,useEffect  } from "react";
import {get} from "../../services/apis";
import {urls} from "../../services/urlConstant";

const About = () => {
  const [announceNotice, setAnounceNotice] = useState([]);
  const [anounceNews, setAnounceNews] = useState([]);
  useEffect(() => {
    const getNotice = async () => {
      try {
        const response = await get(urls.announceNotice.getNotice,{
          params:{
            search:{
              date:Date.now()
            }
          }
        });
        console.log(response);
        setAnounceNotice([...response.data.data]);
      } catch (error) {
        console.log(error);
      }
    };
    getNotice();
  }, []);

  useEffect(() => {
    const getSchoolNews = async () => {
      try {
        const response = await get(urls.announceNews.getNews);
        setAnounceNews([...response.data.data]);
      } catch (error) {
        console.log(error);
      }
    };
    getSchoolNews();
  }, []);

  return (
    <div>
      <div
        className="about-container"
        style={{
          backgroundImage: `url(ExcellentSchool.jpg)`,
          height: "300px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          width: "100%",
        }}
      >
        <div className="about-home">
          <div className="about-us">
            <h1>ABOUT US</h1>
            <div className="home">
              <Link to="/" style={{ color: "white" }}>
                HOME
              </Link>
              / ABOUT US
            </div>
          </div>
        </div>
      </div>
      <div className="about-content">
        <div className="about-info">
          <h3>{announceNotice.data.title}</h3>
          {announceNotice.data.noticeFor.map((e) => (
            <li>{e}</li>
          ))}
          <p>{anounceNews.data.notice}</p>
          <h3>{anounceNews.data.title}</h3>
          <div>
            {anounceNews.map((e) => (
              <li>{e}</li>
            ))}
          </div>
        </div>
        <div className="news-about-us">
          <div className="news-awards">
            <div>
              <div>
                <h1>
                  <span style={{ color: "#f07b47" }}> NEWS </span>AND
                  <span style={{ color: "#f07b47" }}> NOTICE </span>
                  <div className="all-line-3">
                    <div className="first-line"></div>
                    <div className="middle-line"></div>
                    <div className="first-line last-line"></div>
                  </div>
                </h1>
              </div>
            </div>

            <div className="news-container1">
              <div className="scroll-container">
                {announceNotice.map((news) => (
                  <div className="scroll-text">
                    <div className="news-content">
                      <div className="news-date-month">
                        <div className="date">{news.date}</div>
                        <div>{news.noticeFor}</div>
                      </div>
                      <div className="title-discription">
                        <div>
                          <h4>{news.title}</h4>
                        </div>
                        <div className="discription">{news.notice}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="our-mission-container">
          <div className="our-mission-card">
            <div className="card1-container">
              <h4>
                <b>{announceNotice.data.title}</b>
              </h4>
              <p>{announceNotice.data.notice}</p>
            </div>
          </div>
          <div className="our-mission-card">
            <div className="card1-container">
              <h4>
                <b>{anounceNews.data.title}</b>
              </h4>
              <p>{anounceNews.data.notice}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
