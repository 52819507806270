import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Container,
  Box,
  styled,
} from "@mui/material";
// import backgroundImage from "../../assets/bg-image.png";
import backgroundImage from "../assets/Images/HeaderImage.png";

import Footer from "./Footer/Footer";
import NavBar from "./navbar/Navbar";
import { Link } from "react-router-dom";

const OverViewTitleContainer = styled(Box)(({ theme }) => ({
  color: "white",
  padding: "80px",
  backgroundImage:
    "linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url(/HeaderImage.png)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
  },
}));

const OverViewTitle = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  fontSize: "40px",
  [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "25px",
  },
}));

const KnowledgeOfParents = () => {
  const list = [
    {
      title: "Academic Programs:",
      description: [
        "Overview of our curriculum, teaching methodologies, and educational approach.",
        "Information about specific academic programs offered, including advanced placement, international baccalaureate, or specialized programs.",
      ],
    },
    {
      title: "School Policies and Guidelines:",
      description: [
        "Code of Conduct: Expectations, discipline policies, and guidelines for students.",
        "Attendance Policy: Information on attendance requirements, procedures for reporting absences, and tardiness.",
        "Uniform Policy: Details regarding the school's dress code and uniform requirements.",
        "Technology Policy: Guidelines for the responsible and safe use of technology on campus.",
        "Homework Policy: Information on homework expectations, guidelines, and support resources.",
      ],
    },
    {
      title: "Parent-Teacher Collaboration:",
      description: [
        "Parent-Teacher Conferences: Dates, scheduling procedures, and tips for productive meetings with teachers.",
        "Volunteer Opportunities: Ways for parents to get involved in school activities, clubs, and events.",
      ],
    },
    {
      title: "Communication Channels:",
      description: [
        "Parent Portal: Access to an online platform where parents can view their child's academic progress, attendance records, and communicate with teachers.",
        "Social Media: Links to official school social media accounts for quick news and announcements.",
        "Contact Information: Details on how to reach the school administration, teachers, and support staff.",
      ],
    },
    {
      title: "Health and Safety:",
      description: [
        "School Health Services: Information on the availability of health services, medication administration, and emergency procedures.",
        "Safety Measures: Overview of the school's security protocols, emergency drills, and safety policies.",
      ],
    },
    {
      title: "Parent Resources:",
      description: [
        "Academic Support: Links to online resources, tutoring services, and study guides to assist parents in supporting their child's learning.",
        "Parent Education: Workshops, seminars, and articles on topics related to parenting, child development, and education.",
        "Community Resources: Information on local organizations, libraries, and extracurricular activities available to students and families.",
      ],
    },
    // {
    //   content1:"Kayaka, we believe that effective communication and collaboration between parents and the school are crucial for the holistic development and success of our students. In this section, you will find important information, resources, and updates specifically designed to keep parents informed and engaged in their child's education journey",
    //   },
    //   {content2:"Please note that this is a general outline, and specific information relevant to your school should be included. We encourage you to explore the other sections of our website for a more comprehensive understanding of our school community. If you have any questions or require further assistance, please don't hesitate to contact us. We value your partnership in your child's education!"
    // },
  ];

  return (
    <>
      <NavBar />

      {/* <Typography
        style={{
          fontWeight: 700,
          textAlign: "center",
          padding: "60px",
          backgroundImage: `url(${backgroundImage})`,
          color: "white",
          textTransform: "capitalize",
        }}
        variant="h4"
      >
        To the knowledge of parents:
      </Typography> */}

      <OverViewTitleContainer variant="h4" align="center">
        <OverViewTitle>To the knowledge of parents</OverViewTitle>
        <Typography
          sx={{
            color: "white",
            backgroudColor: "red",
            textTransform: "uppercase",
          }}
        >
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            HOME
          </Link>
          &nbsp; / To The Knowledge Of Parents
        </Typography>
      </OverViewTitleContainer>

      <Container>
        <Typography style={{ fontSize: 16 }} mt={5} varient="h4">
          At Raja Somashekara Nayaka English Medium Higher Primary School, we
          take pride in offering a range of distinctive features and programs
          that set us apart from other educational institutions. These unique
          aspects contribute to our students' well-rounded development and
          provide them with enriching experiences. Here are some of the
          remarkable features you'll find at our school:
        </Typography>
        <ol>
          {list.map((d, i, a) => (
            <Container>
              <Typography
                style={{ fontSize: 16, fontWeight: 600 }}
                variant="h5"
                color="initial"
              >
                <li>{d.title}</li>
              </Typography>
              {d.description.map((v, i) => (
                <Container>
                  <ul type="disc">
                    <Typography
                      variant="h6"
                      style={{ fontSize: 16 }}
                      color="initial"
                    >
                      <li>{v}</li>
                    </Typography>
                  </ul>
                </Container>
              ))}
            </Container>
          ))}
        </ol>
        <Typography style={{ fontSize: 18 }} varient="h4">
          These unique features represent the diverse opportunities available to
          our students at Raja Somashekara Nayaka English Medium Higher Primary
          School. We invite you to explore our website further to learn more
          about each aspect and discover how they contribute to an exceptional
          educational experience. If you have any questions or require
          additional information, please don't hesitate to contact us.
        </Typography>
      </Container>
      <Footer />
    </>
  );
};

export default KnowledgeOfParents;
