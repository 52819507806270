import { instance } from "./axios.Config";

// in get request in the second argument add  headers: { roleFunction: "ROLE_FUNCTION" }
// in post request in the third argument add headers: { roleFunction: "ROLE_FUNCTION" }
// in delete request as the fourth argument add headers = { roleFunction: "ROLE_FUNCTION" }
// in put request in the fourth argument add headers: { roleFunction: "ROLE_FUNCTION" }

// for get request we have to provide the roleFunction of that particular page
// for all other request we have to provide role funtion of that particular model

// instance.get("",{
//   headers:{},
//   params:{

//   }
// })

export const get = async (url, config = {}) => {
  const res = await instance.get(url, config);
  return res;
};

export const post = async (url, payload, config = {}) => {
  const res = await instance.post(url, payload, config);
  return res;
};

export const del = async (url, payload, data = {}, headers = {}) => {
  const res = await instance.delete(url + payload, {
    headers: headers,
    params: {
      data,
    },
  });
  return res;
};

export const put = async (url, urlPrams = "", data, config = {}) => {
  const res = await instance.put(url + urlPrams, data, config);
  return res;
};
