import { Box, Grid, Typography, styled } from "@mui/material";
import React from "react";
import MovingText from "react-moving-text";
import founderimg from "../assets/Images/founder.png";

import style from "../NavbarComponents/styles/aboutfounder.module.css";
import Footer from "../Components2/Footer";
import NavTopnav from "../components/NavTopnav/NavTopnav";

const AboutFounder = () => {
  const styles = {
    background: {
      // backgroundImage: "url(/course2.png)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: "100%",
      opacity: "1",
      height: "100%",
      position: "absolute",
      top: 0,
    },
  };

  return (
    <>
      <Box component="div" className={style.background}>
        <NavTopnav />
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Grid
            sx={{ marginTop: { md: "250px", sm: "200px", xs: "200px" } }}
            item
            xs={12}
            md={10}
          >
            <img
              src={founderimg}
              alt=""
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                borderRadius: "5px",
              }}
            />
            {/* <MovingText
              type="slideInFromLeft"
              duration="2000ms"
              delay="0.3s"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none"
            > */}
            <Typography variant="h4" sx={{ color: "orangered", mt: 1 }}>
              Sri. Shivaraj T. Patil
            </Typography>
            {/* </MovingText> */}
            <Box sx={{ margin: "5%" }}>
              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  marginTop: "20px",
                  lineHeight: "25px",
                  textAlign: "justify",
                }}
              >
                Sri. Shivaraj T. Patil, the founder of the Kayaka Foundation
                Education Trust, is a man of many manifestations, hails from
                Devdurg of Raichur district, completed his B.E. from PDA College
                of Engineering, Gulbarga. He joined Infosys, Bangalore, served
                for a period of 6 months, then went to US for Consultancy job
                and completed MBA from Michigan University of America.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  marginTop: "20px",
                  lineHeight: "25px",
                  textAlign: "justify",
                }}
              >
                The sum total synthesis of whatever Patil observed, experienced,
                fascinated, in the field of American educational system- may it
                be of innovation, practicality, creativity, researching is the
                Kayaka Foundation, the brain child of Patil.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  marginTop: "20px",
                  lineHeight: "25px",
                  textAlign: "justify",
                }}
              >
                Shivaraj Patil, strongly believes that a child with strong
                determination can change the destiny. After returning from USA,
                he thought always that Indian children should compete in all the
                fields- may it be an academic excellence or the process of
                research (especially scientists).
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  marginTop: "20px",
                  lineHeight: "25px",
                  textAlign: "justify",
                }}
              >
                Patil has a vision in his philosophy of education that the
                students of Kayaka Foundation should hold their heads high,
                i.e., self-reliant what the American philosopher says "SELF
                RELIANCE IS GOD RELIANCE" - they should continuously participate
                in international cultural exchange programme of America and to
                develop the scientific knowledge in education they (the
                students) should aim for research oriented knowledge purely
                meant for scientists. He has the greater dream to collaborate -
                THINK AND INK SCIENCE FOUNDATION of Germany, which always aims
                at achieving to get the Nobel prizes.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Footer />
      </Box>
    </>
  );
};

export default AboutFounder;
