import React from "react";
import { Box, Typography, styled, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../components/Lottie/PageNotFound.json";

const Container = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  flex: 1,
  justifyContent: "center",
  flexDirection: "column",
  height: "100vh",
}));

function PageNotFound() {
  const navigate = useNavigate();
  const handleNavigateToHome = () => {
    navigate("/", { replace: true });
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            alignItems: "center",
            display: "flex",
            flex: 1,
            justifyContent: "center",
            flexDirection: "column",
            mt: 3,
          }}>
          <Box
            sx={{
              width: "300px",
              height: "300px",
              backgroundColor: "#fff",
            }}>
            <Lottie options={defaultOptions} />
          </Box>
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <Typography variant="h6" textAlign="center">
            Please contact administrator. If you belive something went wrong!
          </Typography>
        </Grid> */}
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
          }}>
          <Button onClick={() => navigate(-1)} size="medium" variant="outlined">
            Back to Home
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default PageNotFound;
