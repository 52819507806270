let dataList = [
  {
    id: 1,
    title: "TO THE KNOWLEDGE OF PARENTS",
    path: "/to-the-knowledge-of-parents",
    // image:"url(../../../../assets/icon01.png)"
  },
  {
    id: 2,
    title: "UNIQUE FEATURES",
    path: "/unique-features",

    // image:new URL("../../assets/icon02.png")
  },
  {
    id: 3,
    title: "VIEW RESULTS",
    // path:"/results"

    // image:new URL("../../assets/icon03.png")
  },
  {
    id: 4,
    title: "REGISTRATION",
    // path:"/registration"

    // image:new URL("../../assets/icon04.png")
  },
];

export default dataList;
