import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Typography,
  createTheme,
  styled,
} from "@mui/material";
import {
  Link,
  unstable_HistoryRouter,
  useNavigate,
  useParams,
} from "react-router-dom";
import css from "./Events.module.css";
import { ThemeProvider } from "@emotion/react";
// import styled from "@emotion/styled";
import { eventdetails } from "./EventsDetails";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import { AppSlider } from "./AppSlider";
import { blue, blueGrey, grey } from "@mui/material/colors";

import { get } from "../../services/apis";
import { urls as url } from "../../services/urlConstant";
import appendStringToUrls from "../../utils/appendString";
import SettingContext from "../../context/SettingsContext";

import moment from "moment";
import {
  calculateSlidersData,
  calculateSlidersSetting,
} from "../../Data/carousal";

export const AppSlider = styled(Slider)`
  width: 100%;
  .slick-track {
    display: flex;
    padding: 30px 0 30px 0;
    //overflow: hidden;
  }
  .slick-slide {
    display: flex;
    justify-content: center;
    margin-bottom: 1;
    // overflow: hidden;
    outline: none;
  }
  .slick-list {
    overflow: hidden;
  }
`;

const theme = createTheme();

// const settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 3,
//   slidesToScroll: 1,

//   // responsive: [
//   //   {
//   //     breakpoint: 1536,
//   //     settings: {
//   //       infinite: true,
//   //       slidesToShow: 3,
//   //       slidesToScroll: 1,
//   //       arrows: true,
//   //     },
//   //   },
//   //   {
//   //     breakpoint: 1280,
//   //     settings: {
//   //       infinite: true,
//   //       slidesToShow: 3,
//   //       slidesToScroll: 1,
//   //       arrows: true,
//   //     },
//   //   },
//   //   {
//   //     breakpoint: 900,
//   //     settings: {
//   //       infinite: true,
//   //       slidesToShow: 2,
//   //       slidesToScroll: 1,
//   //       arrows: true,
//   //     },
//   //   },

//   //   {
//   //     breakpoint: 600,
//   //     settings: {
//   //       infinite: true,
//   //       slidesToShow: 1,
//   //       slidesToScroll: 1,
//   //       arrows: true,
//   //     },
//   //   },
//   //   {
//   //     breakpoint: 300,
//   //     settings: {
//   //       infinite: true,
//   //       slidesToShow: 1,
//   //       slidesToScroll: 1,
//   //       arrows: true,
//   //     },
//   //   },
//   // ],
// };

const TypographyMain = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  marginTop: "25px",
  fontSize: "40px",
  color: "#f86f03",
  fontWeight: "bold",
  textShadow: "10px 8px 8px #969c96",

  // [theme.breakpoints.down("sm")]: {
  //   textAlign: "center",
  //   margin: 0,
  //   padding: "0",
  // },
}));

// const GridBox = styled(Box)(({ theme }) => ({
//   display: "grid",
//   gridTemplateColumns: "300px 300px 300px 300px",
//   justifyContent: "space-evenly",
//   gap: "15px",
//   [theme.breakpoints.down(1000)]: {
//     gridTemplateColumns: "300px 300px",
//   },
//   [theme.breakpoints.down(660)]: {
//     gridTemplateColumns: "90%",
//   },
// }));

// const Btn = styled(Button)(({ theme }) => ({
//   backgroundColor: "#ff6d34",
//   border: "1px solid #ff6d34",
//   color: " black",
//   fontSize: "12px",
//   fontFamily: "serif",
//   marginRight: "13px",
//   [theme.breakpoints.down("md")]: {
//     marginRight: "0px",
//   },
// }));

const Title = styled(Box)(() => ({
  color: "#1976D2",
  fontSize: "25px",
  fontWeight: "bold",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  "&:hover": {
    cursor: "pointer",
    color: "#ef6c00",
  },
}));

const Founder = styled(Box)(() => ({
  color: grey[500],
  fontSize: "14px",
  "&:hover": { color: "#ff6d34" },
}));

const Random = styled(Box)(() => ({
  display: "flex",
  gap: 5,
}));

const Content = styled(Typography)(() => ({
  fontSize: "18px",
  // marginTop: "3%",
  color: "#2c2c2c",
  fontFamily: "Muli",
  height: "50px",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  // "&:hover": { color: "#ef6c00" },
}));

// const CardContent = styled(CardContent)(({ theme }) => ({
//   [theme.breakpoints.down("md")]: {
//     width: "100%",
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "100%",
//   },
// }));

const CardImageWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
}));

const CardImage = styled(CardMedia)(({ theme }) => ({
  width: "100%",
  height: "100%",
  // [theme.breakpoints.down("md")]: {
  //   width: "100%",
  // },
  // [theme.breakpoints.down("sm")]: {
  //   width: "100%",
  // },
}));

const ViewMore = styled(Box)(() => ({
  // fontSize: 'large',
  fontFamily: "Muli",
  color: "#ef6c00",
  marginTop: "5%",
  "&:hover": {
    color: "#1976D2",
  },
  "&:after": {
    width: "20%",
    marginTop: "-1%",
    content: '""',
    borderBottom: "2px solid #dfdfdf",
    display: "block",
    transition: "border-color 0.35s ease",
  },
}));

const AnchorBox = styled(Box)({
  "&::after": {
    content: '""',
    height: "3px",
    // backgroundColor: "#1976D2",
    backgroundColor: "#ef6c00",
    display: "block",
    fontSize: "1rem",
    transform: "scale(0,1)",
    transition: "transform 0.35s ease",
  },

  "&:hover::after": {
    width: "100%",
    backgroundColor: "#1976D2",
    transform: "scale(1,1)",
  },
});

const Our = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  textTransform: "uppercase",
  fontSize: "2.5rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));
const Random1 = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: "20px",
  marginBottom: "15px",
  marginRight: "15px",
  ".MuiSvgIcon-root": {
    cursor: "pointer",
    border: "1px solid #f86f03",
    transition: "background-color 0.3s",
  },

  ".MuiSvgIcon-root:hover": {
    background: "#f86f03",
    color: "#fff",
  },
}));

const Month = styled(Typography)(({ theme }) => ({
  // marginTop: "8px",
  fontSize: "16px",
  // marginLeft: "10px",
  // paddingLeft: "15%",
  color: "white",

  // marginTop:'5%',

  // [theme.breakpoints.down("md")]: {
  //   paddingLeft: "30%",
  // },

  // [theme.breakpoints.down("sm")]: {
  //   paddingLeft: "25%",
  // },

  // [theme.breakpoints.down("lg")]: {
  //   paddingLeft: "30%",
  // },
}));

const Arc = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: 0,
  // borderRadius:'0 5px 0 90%',
  clipPath: "circle(85.8% at 100% 0);",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "flex-end",
  backgroundColor: "#00BDA6",
  zIndex: "2",
  padding: "20px 14px 35px 65px",
  // [theme.breakpoints.down("md")]: {
  //   paddingBottom: "13%",
  // },

  // [theme.breakpoints.down("sm")]: {
  //   paddingBottom: "13%",
  // },
}));

const GridBox = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "300px 300px 300px 300px",
  justifyContent: "space-evenly",
  // background: "red",
  gap: "15px",
  [theme.breakpoints.down(1000)]: {
    gridTemplateColumns: "300px 300px",
  },
  [theme.breakpoints.down(660)]: {
    gridTemplateColumns: "90%",
  },
}));

const EventCardComponents = ({ elem }) => {
  const [showFull, setShowFull] = useState(false);
  const navigate = useNavigate();

  const handleReadMoreClick = () => {
    navigate(`/home/events/${elem._id}`, { state: elem });
    if (showFull) {
      navigate("/");
    } else {
      setShowFull(!showFull);
    }
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    const isFullContentPage = currentPath === `/${elem.id}`;
    setShowFull(isFullContentPage);
  }, [elem.id]);

  let onlyDay = elem.fromDate;

  const date = moment(onlyDay);
  const specificDate = date.format("Do");

  const specificMonth = date.format("MMM ,YYYY");

  const time = moment(elem.fromDate);

  const specificTime = time.format("h:mm A");

  return (
    <>
      <Card
        sx={{
          maxWidth: "100%",
          alignSelf: "center",
          margin: { xs: "5px", sm: "10px", md: "20px" },
        }}
      >
        <CardImageWrapper>
          <Arc>
            {/* <Arc minWidth={makeStyle.minWidth}> */}
            {/* <Month fontSize="25px" className={css.date}>
              {specificDate}
              {specificMonth}
            </Month> */}
            <Typography fontSize="25px" fontWeight={600} className={css.date}>
              {specificDate}
            </Typography>
            <Typography fontSize="12px" mt={1} color="#fff">
              {specificMonth}
            </Typography>
            {/* <Month fontSize="0.7rem" className={css.month}>
              {specificMonth}
            </Month> */}
          </Arc>
          <CardMedia
            className={css.image}
            component="img"
            image={elem?.image?.link}
            alt="Live from space album cover"
            height="297"
          />
        </CardImageWrapper>
        <CardContent>
          <Title onClick={handleReadMoreClick}>{elem.eventTitle}</Title>
          <Content>{showFull ? elem.shortEvent : elem.shortEvent}</Content>
        </CardContent>

        <CardActions>
          <AnchorBox
            className={css.after}
            sx={{
              p: 1,
              cursor: "pointer",
              "&:hover": {
                color: "#1976D2",
              },
            }}
            onClick={handleReadMoreClick}
          >
            {showFull ? "View Less" : "View More"}
          </AnchorBox>
        </CardActions>
      </Card>
    </>
  );
};

const Events = () => {
  const { selectedSetting } = useContext(SettingContext);

  const urls = appendStringToUrls(url, `/${selectedSetting._id}`);

  const [totalEvents, setTotalEvents] = useState(0);

  const [events, setEvents] = useState([]);

  let sliderRef = useRef(null);

  const handleNextClick = () => {
    sliderRef.slickNext();
  };
  const handlePrevClick = () => {
    sliderRef.slickPrev();
  };

  const getAllEvents = async () => {
    try {
      const { data } = await get(urls.events.getEventListHome);
      console.log(data.result.data, "compo 2 events");
      setEvents(data.result.data);
      setTotalEvents(data.result.count);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <>
      <Container sx={{ marginTop: "8rem" }} maxWidth="xl">
        <TypographyMain variant="h5" my={6}>
          OUR EVENTS
        </TypographyMain>
        <Box
          sx={{
            padding: { xs: 0, sm: 0, md: "40px" },
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {events.length > 3 && (
            <Random1 style={{ borderColor: "#f86f03" }}>
              <ChevronLeftIcon onClick={handlePrevClick} color="warning" />
              <ChevronRightIcon onClick={handleNextClick} color="warning" />
            </Random1>
          )}

          <AppSlider
            {...calculateSlidersSetting(events.length)}
            ref={(slider) => {
              sliderRef = slider;
            }}
          >
            {events.map((elem, i) => (
              <EventCardComponents key={i} elem={elem} />
            ))}
          </AppSlider>
        </Box>
      </Container>
    </>
  );
};
export default Events;
