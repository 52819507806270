import { Box, Card, Typography } from "@material-ui/core";
import React from "react";
import SettingSelector from "./SettingSelector";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

export default function SectionSubHeader({
  title = "Title",
  goBackArrow = false,
  handleNavigate,
  style = {},
}) {
  return (
    <Card style={{ margin: "0px 0 20px 0", padding: 10, ...style }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", sm: "center" },
          justifyContent: "space-between",
          alignItems: "center",
          // border:"solid red",
        }}
        spacing={2}
      >
        <Box>
          {goBackArrow && (
            <KeyboardBackspaceIcon
              sx={{ fontSize: "25px", mr: 2 }}
              onClick={handleNavigate}
            />
          )}

          <Typography
            style={{
              color: "red",

              display: "inline-block",
            }}
          >
            {title}:
          </Typography>
        </Box>
        <SettingSelector />
      </Box>
    </Card>
  );
}
